 function Items() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1920.000000 1080.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,1082.000000) scale(0.100000,-0.100000)"
            stroke="none">
<path d="M5037 9343 c-4 -3 -7 -136 -7 -295 l0 -288 185 0 c166 0 191 -2 227
-20 23 -11 56 -20 74 -20 18 0 53 9 79 20 41 18 69 20 241 20 l194 0 -2 293
-3 292 -491 3 c-270 1 -494 -1 -497 -5z"/>
            </g>
        </svg>
    )
}

export default Items;