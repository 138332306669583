 function Items() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1920.000000 1080.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,1082.000000) scale(0.100000,-0.100000)"
            stroke="none">
<path d="M2495 8756 c-181 -36 -342 -118 -479 -247 -162 -151 -269 -349 -295
-547 -6 -42 -18 -234 -26 -427 -85 -1887 -244 -3944 -446 -5762 -44 -400 -45
-466 -8 -611 38 -150 138 -322 246 -423 103 -97 219 -161 361 -201 101 -29
278 -31 367 -4 238 71 415 268 471 524 10 45 19 205 29 482 87 2508 236 4418
495 6368 34 257 36 295 14 400 -56 274 -265 445 -559 457 -61 3 -127 -1 -170
-9z"/>
            </g>
        </svg>
    )
}

export default Items;