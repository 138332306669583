 function WhiteBottom() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1920.000000 1080.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
            fill="#fff5f0" stroke="none">
<path d="M3687 10170 c-48 -4 -92 -11 -96 -16 -4 -5 25 -468 65 -1029 40 -561
73 -1035 74 -1052 l0 -33 -579 0 c-514 0 -581 -2 -595 -16 -19 -18 -23 -163
-6 -195 11 -19 46 -19 2210 -19 2164 0 2199 0 2210 19 17 32 13 177 -6 195
-14 14 -164 16 -1500 16 -816 0 -1484 3 -1484 8 0 53 -151 2115 -155 2122 -3
6 -16 9 -28 9 -12 -1 -62 -5 -110 -9z"/>
<path d="M4750 8625 l0 -55 895 0 895 0 0 55 0 55 -895 0 -895 0 0 -55z"/>
<path d="M2557 5532 c-20 -22 -24 -160 -7 -193 11 -19 46 -19 2210 -19 2164 0
2199 0 2210 19 5 11 10 55 10 99 0 64 -4 82 -18 95 -17 16 -184 17 -2204 17
-2104 0 -2186 -1 -2201 -18z"/>
<path d="M3281 4754 c-25 -20 -31 -34 -31 -64 0 -30 6 -44 31 -64 33 -28 59
-32 92 -14 33 17 46 39 46 78 0 39 -13 61 -46 78 -33 18 -59 14 -92 -14z"/>
<path d="M4651 4754 c-70 -58 -13 -172 74 -150 55 14 85 94 52 137 -34 44 -83
49 -126 13z"/>
<path d="M6021 4754 c-70 -58 -13 -172 74 -150 55 14 85 94 52 137 -34 44 -83
49 -126 13z"/>
            </g>
        </svg>
    )
}

export default WhiteBottom;