 function ProjectsWindowBackground() {
    return (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="450.000000pt" height="450.000000pt" viewBox="0 0 5000.000000 5000.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(500.000000,5000.000000) scale(0.100000,-0.100000)"
fill="#0f2d30" stroke="none">
<path d="M0 24475 l0 -15495 25000 0 25000 0 0 15495 0 15495 -25000 0 -25000
0 0 -15495z m25669 8155 c7615 -478 13904 -875 13974 -880 472 -39 885 -243
1244 -614 418 -431 682 -1018 758 -1681 14 -127 15 -675 12 -5715 l-3 -5575
-21 -125 c-81 -480 -222 -851 -458 -1205 -265 -398 -608 -685 -1008 -844 -93
-37 -235 -76 -352 -96 -122 -21 -27897 -1525 -28162 -1525 -192 0 -295 8 -463
36 -1010 168 -1859 877 -2224 1857 -69 184 -115 365 -163 637 -16 88 -17 589
-20 6970 -3 5047 -1 6906 7 6990 136 1386 1165 2461 2513 2624 275 34 -357 71
14366 -854z"/>
</g>
</svg>
    )
}

export default ProjectsWindowBackground;