 function WhiteTop() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1920.000000 1080.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,1082.000000) scale(0.100000,-0.100000)"
            fill="#fff5f0" stroke="none">
<path d="M5150 9360 c0 -6 28 -10 65 -10 37 0 65 4 65 10 0 6 -28 10 -65 10
-37 0 -65 -4 -65 -10z"/>
<path d="M5754 9276 c-3 -8 -4 -25 -2 -38 3 -22 7 -23 88 -23 l85 0 0 35 0 35
-83 3 c-65 2 -83 0 -88 -12z m146 -26 c0 -18 -7 -20 -60 -20 -53 0 -60 2 -60
20 0 18 7 20 60 20 53 0 60 -2 60 -20z"/>
<path d="M5434 9229 c-179 -68 -229 -292 -96 -428 49 -51 135 -91 193 -91 47
0 150 52 190 97 124 137 68 357 -108 423 -67 25 -113 25 -179 -1z m165 -15
c173 -51 227 -290 94 -416 -148 -140 -389 -48 -410 157 -12 112 61 227 164
259 63 19 88 19 152 0z"/>
<path d="M5468 9181 c-135 -44 -195 -194 -125 -313 81 -139 283 -139 364 0 58
99 26 230 -71 287 -48 28 -125 40 -168 26z"/>
<path d="M5100 8875 c-26 -32 13 -81 48 -59 34 22 22 74 -18 74 -10 0 -23 -7
-30 -15z m50 -25 c0 -13 -7 -20 -20 -20 -13 0 -20 7 -20 20 0 13 7 20 20 20
13 0 20 -7 20 -20z"/>
            </g>
        </svg>
    )
}

export default WhiteTop;