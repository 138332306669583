 function Splash1() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1920.000000 1080.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
            fill="#0f2d30" stroke="none">
	<path d="M19195,6060c-3,0-5-6-5-13c0-15-130-143-195-194c-22-16-60-41-85-53c-41-21-54-22-145-17c-121,8-198,28-284,73
		c-62,33-67,34-181,34h-118l-73-91c-41-50-88-109-105-130c-6-7.4-12.5-16.6-19.2-27.5c-7.1-61.8-20.9-122.4-49-179
		c-20.4-40.9-47.7-77.7-77.8-112.2c-20.2-57.6-39.9-118.6-57.7-179.9c-11.6-55.2-22.5-110.5-37.9-164.7
		c-22.9-80.3-54.7-157.5-73.2-239.1c-2.7-11.7-8.2-20.3-15.3-26.2c-21-65.3-41.8-127.7-58.8-175.5c-7.5-20.9-15.5-35.3-29.2-46.7
		c-25.3-71.8-70.3-139.3-110.8-202c-27.6-42.8-57-84.4-87.3-125.3c-0.3-0.6-0.6-1.2-0.9-1.8c-15.4-98.9-48.8-196.7-93.8-288.5
		c-2.5-14.4-5.1-28.9-7.9-43.2c-24-202.3-71.5-360.1-169.3-590.9c-17.8-146.2-78.8-280.5-147.3-411.1c-12-44.6-23.2-90.1-33.4-136.6
		c-16.4-72-39.7-144.1-85.4-256.6c-7.1-29.6-16.6-57.8-28-85c-8.6-47.5-20.7-94.1-44.6-138c-20.1-37-45.4-70.8-70.2-104.9
		c-2.9-6.4-5.8-12.8-8.8-19.4c-102-226-210-377-376-530c-101-93-143-124-227-171c-25.2-13.9-67.1-43.2-108.5-75.1
		c6.5-21.2-6.9-44.3-23.7-56.9c-103.4-77.1-197.8-167.3-308.9-232.7c-39-35.7-73.4-67.9-104-97.4c-47-46-101-91-120-101
		c-19-11-89-31-155-46c-65-14-126-30-135-35c-4.6-2.3-30-12-65.5-25.1c-26.4-18.9-49-34.3-90.2-34.4c-1.2,0-2.3,0.1-3.4,0.2
		c-187.6-66-369.9-96.7-641.9-106.6l-174-7l-46-46c-25-25-54-59-65-74c-16-23-35-32-99-47c-44-10-120-33-169-50
		c-31-11-61.4-19.8-81.6-23.6c-6.3-1.7-12.7-3.1-19.3-4.1c-4.2-1.9-8.7-3.4-13.9-4c-4.8-5.5-17-13.3-32.2-20.3l-59-8
		c0-20-44-53-116-87c-8.9-4.3-15.6-7.2-21.2-9.1c-9.2-8.6-21.7-13.9-35.2-15.4c5.6,0.6-4.7-1.7-5.8-2.1c-3.9-1.3,0.6,0.9-5.5-2.6
		c-6.3-3.7-12.5-7.3-18.7-11.3c-16.1-10.4-31.8-18.9-49.6-26.3c-0.7-0.3-1.4-0.4-2-0.7c-19.7-11.8-55.1-21.8-114-34.7
		c-51-11-105-28-120-36c-25-15-41-15-162-3l-135,13l-3,38c-4,44,46,160,92,215c34,39,39,40,31,3c-7-32,18-52,33-25c7,13,11,12,27-6
		c22-24,35-26,35-4c0,14,3,14,16,3c13-10,17-10,24,0c12,20,30,4,30-27c0-24,1-24,13-8c12,17,15,17,30,2c15-16,18-15,38,5
		c12,12,29,19,40,16c13-4,19,0,19,12c0,12.2,15.9,18.1,33.4,18.5c5.9,2.5,12.2,3.8,18.6,3.8h1.9c-2.9,12-7.3,23.6-9.8,35.7
		c-2,9.5-1.5,19.1,1.1,27.9c-8.2,8.1-12.6,15.2-6.2,15.2c12,0,12,3,3,12c-7,7-12,18-12,25c0,15,36,18,45,3c2.4-4,8-7.9,13.2-9.3
		c1.8,1,3.8,1.9,5.8,2.7c-0.6,5.4-7.2,16.9-18,30.6c-22,30-23,35-9,40c17,7,42-14,62-53c10-17,18-21,37-17c25,7,27,4,26-59l-1-30
		l7,29c7,28,7,28,25,9c10-12,15-27,11-37c-3-9-1-16,5-16c16,0,13,32-6,59c-9,13-18,40-20,60c-4,34-3,35,11,16c9-11,16-28,16-39
		c0-14,3-16,13-7c11,8,17,5,27-14c2.5-5.1,5.5-9.7,8.4-13.6c0.4,24.6,16.5,48.5,48.2,47.6c2.1-0.1,13.9,0,22.8,0.3
		c-3,11.5-6.7,22.4-10.7,33.1c-9.2,6.8-16.8,12.7-23.2,18c-7.2,1.9-14.3,5.5-21,11.4c-27.7,24.2-57.9,48-76.2,80.7
		c-13.5,24.1-17.6,49.3-13.9,74.1c-8.5,4.5-10.1,11.1-3.4,19.4c14,17-3,73-31,97c-21,17-28,53-9,41c8-5,8-1,0,12c-19,33-38,54-49,54
		c-6,0-12,8-14,17c-4,21-107,47-125,31c-7-6-10-14-7-19c8-13-20-11-33,2c-6,6-17,9-25,7c-8-3,0-11,19-21c24-13,31-22,26-35
		c-6-14-4-15,9-5c16,14,28,8,28-14c0-26-48-14-70,18c-13,17-28,28-36,25c-16-6-38,29-28,46c4,6-4,3-18-8c-14-10-24-24-21-30
		c8-21-16-25-41-9c-22,15-26,14-60-5l-37-20l-9,22c-8,19-9,17-7-16c2-45,31-72,47-46c8,12,10,11,10-5c0-12-5-26-11-32
		c-7-7-7-13,0-17c5-4,15,3,21,14c15,28,32,16,20-14c-5-15-5-27,3-35c6-6,7-14,3-18c-4-4-11-2-15,5c-4,7-15,10-23,7
		c-21-8-30-45-16-62c8-10,6-18-7-33c-10-11-22-17-26-14c-12,7-11,61,1,69c6,3,10,16,10,29c0,12,5,27,12,34c9,9,8,12-5,12
		c-9,0-21-9-27-20c-9-16-14-18-34-8c-33,14-22,38,18,39c27,0,28,1,10,9c-22,9-28,24-39,103l-7,48l27-7c24-6,26-4,21,13
		c-9,27,38,60,59,43c8-6,23-9,35-5c17,5,19,10,9,21c-9,11-8,14,5,14c9,0,16,5,16,11c0,7,10,9,25,5c21-5,25-3,25,15s-4,20-32,14
		c-28-7-30-6-17,7c8,8,21,17,29,21c10,3,7,6-7,6c-13,1-23,7-23,15c0,24,17,28,49,11c43-22,50-19,18,10c-32,30-35,40-7,25
		c16-9,20-8,20,5c0,21-29,31-51,17c-22-14-49-16-49-4c0,19,30,46,55,49c18,3,35-3,50-17c20-19,23-19,29-5c3,9,1,25-4,35
		c-8,15-6,21,11,30c11,7,23,9,26,6c4-3,12-1,19,5c16,13,34,4,34-17c0-8,7-14,15-14c13,0,14,4,5,20c-10,18-8,20,17,20
		c22,0,25,2,13,10c-8,5-27,10-42,10c-18,0-28,5-28,14c0,22,19,30,35,16c11-9,15-9,15-1c0,6-5,11-11,11c-5,0-7,5-3,11c4,7-2,9-17,7
		c-26-3-25-6-12,75c5,30,8,35,19,26c8-6,14-18,14-25c0-24,18-15,24,12l7,27l25-23c32-31,30-46-6-65c-16-9-30-19-30-23
		c0-15,75-89,86-85c6,2,17-3,24-12c7-8,16-15,21-15s0,9-11,20c-17,17-18,23-8,39c9,15,9,26-1,48c-8,16-16,23-18,16
		c-10-29-29-12-25,22c4,29-1,40-28,65c-18,16-36,27-41,24c-14-8-50,6-43,18c4,6,10,5,15-3c7-11,9-11,9-1c0,7,8,12,17,10
		c12-2,19,6,24,27c4,17,7,24,8,17c0-7,6-10,12-6c6,3,9,17,6,29c-3,17,3,27,20,36c25,14,25,23-5,91c-10,21-25,40-35,44
		c-13,4-18,13-15,29c7,32-12,52-81,86l-62,29l-32-22c-18-12-35-26-38-31c-4-5-15-7-25-5c-19,4-54-30-54-51c0-6-9-11-20-11
		c-22,0-83-38-118-72l-22-23l30-14c28-14,42-41,20-41c-5,0-10,7-10,16s-3,14-7,12c-5-2-20-10-35-17c-24-12-30-12-46,2
		c-26,24-30,22-28-18c0-19-3-34-9-34c-5,1-9,5-7,11c1,5-2,7-8,3c-6-3-8-10-5-15c10-16-24-72-38-63c-7,4-22,6-34,5c-11-2-24,2-28,7
		c-3,6-11,11-16,11c-6,0-7-4-4-10c8-12-5-13-25,0c-10,6-24,3-46-11c-38-23-48-24-41-4c4,8,2,17-3,20c-6,4-10,45-11,93s-4,112-9,142
		c-6,46-7,36-9-62c-1-106-3-118-18-115c-27,4-44,17-37,28c3,5,10,7,15,4c10-7,12,8,2,18c-9,10-33-13-33-32c0-9,13-22,30-28
		c18-7,30-18,30-29c0-14-3-15-12-6c-17,17-29,15-22-3c4-8,1-15-4-15c-18,0-64,48-59,62c8,23-13,49-34,43c-14-5-19-2-19,14
		c0,17,4,19,32,10c18-5,34-9,35-9c2,0,3,9,3,20c0,14,5,18,16,13c16-6,16,4-4,56c-4,11,0,11,18-2c29-20,47-22,32-4c-6,7-9,23-5,35
		c5,20,2,21-32,17c-32-4-36-3-30,14c4,13,0,20-15,24c-11,3-20,13-20,22c0,14,6,16,30,11c21-4,30-2,30,7c0,7-13,26-30,42
		c-23,22-30,38-31,69c-1,23-3,33-6,23c-2-9-12-17-22-17c-20,0-61,32-61,48c0,5-9,8-20,5c-12-3-20,0-20,6c0,7-5,9-11,5c-8-4-9,1-4,17
		l8,24l-40-48l-7,26c-7,31-26,36-26,7c0-11-7-20-15-20c-8,0-15,3-15,8c0,4-10,13-22,21c-18,10-20,11-10,0c14-15,16-33,3-24
		c-5,3-12,1-16-5c-3-5-27-11-53-11c-26-1-39-3-30-6c14-3,17-13,15-44c-3-38,1-44,33-39c12,1,13-1,3-11c-7-7-13-19-13-27
		c0-12,4-12,24,1c21,14,26,14,36,2c6-8,10-18,7-22c-3-4,9-8,26-8c25,0,32-4,32-20c0-14-8-21-25-23c-28-4-53-22-30-22c9,0,6-8-10-25
		c-16-17-23-38-25-71c-1-31-7-49-18-56c-10-5-17-21-17-36c1-24,2-25,11-8c16,27,28,2,22-43c-4-32-3-34,8-16c7,11,12,41,12,67
		c0,27,3,48,8,48c4,0,10,15,12,33c4,29,5,30,12,10c7-21,21-28,53-24c6,1,12-3,12-9c0-5,9-10,20-10c11,0,20,7,20,15c0,8,5,15,10,15
		c6,0,10-8,10-17c0-13,3-14,12-5c8,8,8,15,0,24c-7,10-6,18,5,33c15,19,15,19,9-7c-8-33,9-38,18-6c6,21,7,21,17,5c8-13,19-16,51-12
		c34,4,39,3,29-9c-6-8-25-19-42-26c-17-8-29-20-28-29c3-32-1-51-11-51c-5,0-10,7-10,15c0,21-17,19-37-5c-10-11-22-20-29-20
		c-6,0-23-11-39-24c-20-17-29-36-32-67c-5-43-5-44,14-26c21,18,43,23,43,8c0-5-12-24-27-43c-16-20-19-26-7-16c11,10,24,18,27,18
		c13,0,7-28-8-40c-9-7-14-19-11-27c4-8,1-11-5-7c-8,4-9-1-4-15c4-15,2-21-9-21c-8,0-18,4-21,10c-3,5-11,10-16,10c-6,0-7-4-4-10
		c4-6-10-9-34-8c-25,1-42,7-46,16c-4,12-8,13-17,4c-7-7-22-12-35-12c-23,0-32-15-13-22c6-2-18-14-52-28c-35-13-73-33-85-45
		c-22-20-31-45-15-45c4,0,14,6,21,13c11,10,12,6,6-23c-9-43-4-38-40-42c-26-3-30,0-29,20c0,12-1,22-4,22c-13,0-58-48-54-58
		c2-7-1-12-7-12c-20,0-26-2-38-12c-7-5-17-7-23-3c-5,3-14,4-20,0c-13-8-1-35,16-35c8,0,14-7,14-15c0-8-3-15-8-15c-4,0-12-20-18-45
		s-9-45-7-45c14,0,43,23,43,34c0,8,7,16,15,20c17,6,20-15,5-30c-9-9-12-56-8-119c1-11-1-33-5-48c-5-20-4-27,3-22c7,4,9-2,4-19
		c-5-23-4-25,12-19c11,4,25,13,33,21c10,9,16,10,21,2c11-18,41,1,47,31c3,16,11,29,18,29c7,0,15,7,19,15c3,8,10,15,16,15
		c13,0,45,32,53,53c7,19,27,23,27,6c0-6-5-19-11-30c-8-16-7-25,7-40c16-18,18-18,31-1c13,16,14,15,9-6c-5-24-4-24,17-5
		c12,10,28,25,35,33c11,13,12,10,7-20c-6-33-5-34,10-16c8,11,13,28,10,37c-3,10-1,20,5,24c6,4,10-6,10-24c0-31,24-45,32-19
		c2,6,12,9,21,5c20-7,23-27,4-27c-22,0-37-35-27-66c4-16,9-21,9-11c1,27,15,20,30-15c12-29,14-31,20-13c3,11,13,20,21,20
		c8-1,20,3,26,7c7,5,14,1,17-13c4-13,10-18,17-14c16,10,30-13,30-47c0-31,11-36,30-13c11,13,15,10,33-22c11-21,24-51,29-68
		c11-36,38-335,38-422v-63h-276c-329,0-637-16-754-39c-67-12-120-15-245-11l-160,6l-101,47c-56,26-109,47-118,47c-9,0-47-15-84-34
		l-67-33l-80,12c-307,48-298,47-366,36c-37-6-132-15-210-21c-79-5-171-12-205-16c-70-7-97,4-222,88c-123,83-138,88-297,88
		c-126,0-147,3-210,26c-104,39-138,41-198,11c-51-25-58-25-357-32c-181-4-329-12-365-19c-50-11-67-11-100,1c-94,33-396,115-600,163
		c-230.7,54.4-355.4,88.3-445.1,130.1c-184.4,21.5-365.5,63.2-545,113.6c-29.9-0.2-60.1,0.5-89.9,2.3c-77,4-212,8-300,9
		c-93,1-171,6-187,13c-46,19-180,44-288,52c-118,9-191,27-295,73l-70,30l-255,8c-162,5-285,13-336,23c-94,19-266,80-444,160
		c-69,30-188,75-265,99c-130,41-140,43-143,26c-2-11-5-19-8-19c-2,0-43,18-92,39c-53,24-92,48-99,62c-9,17-32,27-101,44
		c-115,30-165,56-228,120c-60,62-120,193-179,392c-44,150-57,182-100,244c-44,64-53,113-66,349c-9,177-9,202,6,230
		c18,34,23,25-75,140c-9,10-36,21-65,24c-27,4-111,19-185,33c-145,29-137,25-203,113l-33,45l-129,6c-73,4-152,14-181,23
		c-32,10-101,53-186,114c-74,54-172,117-219,141c-26.6,13.9-61.8,33.4-94.7,52.6c-172.8,35.6-338.5,100.5-457.4,219.4
		c-146.3,74.6-331.6,178.8-528.1,297.2c-161.4,17.7-299.7,124.8-408.9,256.4l-40,25.5V0h19200v3030
		C19200,4697,19198,6060,19195,6060z M13386,672c9,13,21-1,29-34c5-18,3-28-4-28C13397,610,13378,658,13386,672z M13311,712
		c-6-2-11,4-11,13s5,15,11,13c6-2,11-8,11-13S13317,714,13311,712z M13360,806c0-16-27-29-42-20c-6,4-1,12,13,20
		C13361,823,13360,823,13360,806z M13308,894c24-17,30-39,8-31c-8,3-16,1-18-5c-7-20-28,2-28,28C13270,914,13277,916,13308,894z
		 M13270,830c4,0,11-7,14-16c9-22,8-26-6-22C13265,797,13258,830,13270,830z M13240,880c6,0,10-4,10-10c0-5-4-10-10-10
		c-5,0-10,5-10,10C13230,876,13235,880,13240,880z M13202,728c6-2,14-10,16-16c6-18-15-14-22,4C13192,726,13195,730,13202,728z
		 M13216,475c18-15,18-15-5-13c-14,2-26,9-29,16C13177,495,13192,493,13216,475z M13046,790c-3,0-8,5-11,10c-3,6-1,10,4,10
		c6,0,11-4,11-10C13050,795,13048,790,13046,790z M13100,410c-5,0-10,7-10,15s5,15,10,15c6,0,10-7,10-15S13106,410,13100,410z
		 M13140,305c-5,3-10,10-10,16c0,5,5,9,10,9c6,0,10-7,10-16C13150,306,13146,302,13140,305z M13142,378c16,16,18,15,18-8
		c0-11-7-20-15-20C13127,350,13126,362,13142,378z M13130,545c6-3,10-11,10-16c0-6-4-7-10-4c-5,3-10,11-10,16
		C13120,547,13125,548,13130,545z M13121,682c-7-2-18,1-23,6c-8,8-4,9,13,5C13124,689,13129,685,13121,682z M13016,881
		c7,11,9,11,12-2c1-9,19-20,39-25c50-12,55-34,9-34c-26,0-39,5-43,18c-4,9-11,20-16,23C13011,865,13011,874,13016,881z M12988,880
		c-8-13-15-16-19-8c-10,15-2,28,17,28C12998,900,12998,896,12988,880z M12990,480c6,0,10-2,10-4c0-3-4-8-10-11c-5-3-10-1-10,4
		C12980,475,12985,480,12990,480z M12979,448c3,9,21-1,21-12c0-8,6-17,13-19c6-3,0-6-15-6C12974,410,12970,417,12979,448z
		 M12960,640c6,0,10-4,10-10c0-5-4-10-10-10c-5,0-10,5-10,10C12950,636,12955,640,12960,640z M13230,1845c6-3,10-11,10-16
		c0-6-4-7-10-4c-5,3-10,11-10,16C13220,1847,13225,1848,13230,1845z M13084,1489c6,9,11,9,21,1c9-8,15-8,20,0c9,14,52,13,58-2
		c2-7,2-28-1-46c-3-24-8-32-19-27c-12,4-14-2-11-28c3-30,1-33-18-29l-22,4l19,33l20,33l-26,20c-14,12-31,22-37,24
		C13082,1474,13080,1482,13084,1489z M13014,1344c10-4,16,5,21,25c7,32,12,35,39,25c9-3,16-18,16-31c0-14,8-32,18-39
		c15-12,15-13,1-14c-9,0-22,14-29,30c-16,39-28,38-26-2c1-31-1-33-36-36c-21-2-38,0-38,3C12980,1323,13002,1349,13014,1344z
		 M12994,1125c2-7-6-23-19-35c-23-22-25-22-32-5c-4,11-2,20,5,23c7,2,12,12,12,23C12960,1151,12985,1148,12994,1125z M12950,1223
		c11-3,20-11,20-17c0-22-13-26-26-8C12925,1222,12927,1229,12950,1223z M12876,1010c-3,0-8,5-11,10c-3,6-1,10,4,10c6,0,11-4,11-10
		C12880,1015,12878,1010,12876,1010z M12900,1670c6,0,10-4,10-10c0-5-4-10-10-10c-5,0-10,5-10,10C12890,1666,12895,1670,12900,1670z
		 M12920,1191c0-13-29-41-41-41c-6,0-2,11,8,25C12906,1200,12920,1207,12920,1191z M12856,1411c6,11,24,2,24-11c0-5-7-7-15-4
		C12857,1400,12853,1406,12856,1411z M12905,510c14,0,25,5,25,10c0,6,2,10,5,10c3,0,5-4,5-9c0-9-61-51-74-51c-4,0-2,9,4,20
		C12876,502,12891,510,12905,510z M12890,640c0-15-31-60-40-60c-7,0-5,23,6,58C12860,653,12890,654,12890,640z M12826,677
		c4,18,12,29,23,29c17,0,17-3,2-48C12828,586,12810,599,12826,677z M12840,490c0-11-4-20-10-20c-5,0-10,9-10,20s5,20,10,20
		C12836,510,12840,501,12840,490z M12825,391c15,47,21,57,32,48c10-9,13-8,13,1c0,8,3,10,6,7c4-4-8-37-26-74
		C12812,296,12799,305,12825,391z M12085,2042c-4,4-14,3-22-4c-15-12-28-5-18,11c9,15,32,14,41-1C12090,2041,12090,2038,12085,2042z
		 M12125,2040c3,6,8,10,11,10c2,0,4-4,4-10c0-5-5-10-11-10C12124,2030,12122,2035,12125,2040z M12175,1850c-3-5-11-10-16-10
		c-6,0-7,5-4,10c3,6,11,10,16,10C12177,1860,12178,1856,12175,1850z M12160,1885c-28,7-40,14-32,19c20,12,14,44-10,48
		c-21,3-21,4-4,21c20,20,32,22,42,6c4-8,9-7,15,2c7,11,9,10,9-3c0-9-6-19-12-21c-7-3-4-6,7-7c19-1,19-1-2-19l-22-18l26-12
		c19-9,29-9,39,0c8,7,14,7,14,1C12230,1880,12204,1873,12160,1885z M12216,1947c3-3,4-12,1-19c-3-8-6-5-6,6
		C12210,1945,12213,1951,12216,1947z M12238,1793c-7,2-13,8-13,13s-4,15-8,22c-12,19,4,14,19-5C12253,1800,12254,1787,12238,1793z
		 M12236,1993c-14,6-17,4-12-8c3-9,0-15-9-15c-18,0-18,5-5,31c13,23,14,23,31,2C12253,1988,12253,1987,12236,1993z M12254,1861
		c-3,5,6,9,20,9s26-2,26-4C12300,1857,12260,1853,12254,1861z M11635,1129c3,6,11,8,17,5c6-4,6-10-1-17
		C11638,1104,11625,1114,11635,1129z M11630,1054c0,19,22,56,30,51c7-4,7-12,0-25C11650,1062,11630,1044,11630,1054z M11663,1054
		c-4,3,2,13,13,21c24,18,24,18,24,0C11700,1058,11673,1043,11663,1054z M11764,995c-4-8-10-15-15-15c-4,0-5,7-2,17c4,10,2,14-5,10
		c-6-5-9-28-6-62c3-32-1-65-7-77l-12-22l-9,23c-5,15-4,28,3,37c7,8,9,28,5,50c-12,63,13,126,29,74c3-11,10-20,15-20
		S11767,1003,11764,995z M11851,1150c-6,0-11,5-11,10c0,6,2,10,4,10c3,0,8-4,11-10C11858,1155,11856,1150,11851,1150z M11901,1200
		c-11,0-22-6-25-12c-2-7-3,18-2,56c3,75,15,90,64,79c16-4,22-1,22,11c0,9,5,16,10,16c6,0,10-3,10-8c0-4-6-16-14-27
		c-7-11-17-35-21-52c-3-18-11-33-16-33s-9-7-9-15S11911,1200,11901,1200z M11977,1214c-9-9-28,6-21,18c4,6,10,6,17-1
		C11979,1225,11981,1218,11977,1214z M11984,978c-10-32-37-67-51-68c-19,0-16,10,11,31c16,13,22,26,19,39c-4,14,0,20,12,20
		C11987,1000,11990,995,11984,978z M12473,652c8,5,13,0,15-15c2-12,8-26,13-31c5-6,9-33,8-60l-1-51l-23,75
		C12467,626,12464,646,12473,652z M12443,1088c6-3,14-13,16-24c3-16,1-17-13-5C12427,1075,12425,1093,12443,1088z M12420,1150
		c6,0,10-4,10-10c0-5-4-10-10-10c-5,0-10,5-10,10C12410,1146,12415,1150,12420,1150z M12355,1529c3,6,11,8,17,5c6-4,6-10-1-17
		C12358,1504,12345,1514,12355,1529z M12370,1280c12-8,11-10-7-10c-13,0-23,5-23,10C12340,1293,12351,1293,12370,1280z M12354,1238
		c14-6,27-16,29-23c3-6-2-11-12-10c-24,2-41,14-41,30C12330,1247,12335,1247,12354,1238z M12254,1470c3,0,8-4,11-10c3-5,1-10-4-10
		c-6,0-11,5-11,10C12250,1466,12252,1470,12254,1470z M12251,1381c7-52,8-50-28-33c-34,16-43,37-20,45
		C12218,1398,12250,1390,12251,1381z M12160,1555c0,20,17,19,29-2c7-14,12-15,24-5c10,9,17,10,22,2c3-5-1-10-9-10c-9,0-16-8-16-17
		c-1-17-1-17-11,0c-5,9-17,17-24,17C12167,1540,12160,1547,12160,1555z M12190,1500c0-5-7-10-16-10c-8,0-12,5-9,10c3,6,10,10,16,10
		C12186,1510,12190,1506,12190,1500z M12219,1331c13-12,12-13-9-6c-29,9-50,24-50,36C12160,1371,12196,1353,12219,1331z M11999,1250
		c5,0,11,21,14,46c5,47,19,66,44,58c7-2,20-6,29-9c15-5,15-8-2-27c-19-20-19-21,4-14c12,4,22,3,22-2c0-15-92-103-103-99
		C11994,1207,11987,1250,11999,1250z M12063,1181c-8,8-13,8-18-1c-4-7,1-14,14-17c12-3,20-11,18-17c-4-12-67-16-67-4c0,4,4,8,8,8
		c4,0,13,14,19,31c10,27,14,29,32,19c12-6,21-15,21-20C12090,1167,12077,1167,12063,1181z M12093,1219c0,11,4,18,8,15c4-2,10,0,14,6
		c8,13,35,13,35,0c0-5-13-16-29-25C12093,1201,12091,1201,12093,1219z M12128,1086c-10,6-1,24,13,24c5,0,9-4,9-8
		C12150,1089,12138,1080,12128,1086z M12200,990c-19,0-27,20-13,33c13,14,33,6,33-13C12220,997,12213,990,12200,990z M12285,1010
		c-24,0-75,57-75,84c0,14-5,26-11,26s-9-7-5-15c3-8,1-15-4-15c-6,0-10,9-10,21c0,12-4,17-11,14c-11-7-59,32-59,48c0,4,18,25,40,46
		c24,22,36,41,31,47c-5,5-12,19-16,32c-8,26,11,30,41,8c15-12,17-17,7-27c-21-21-15-32,12-25c14,4,25,2,25-4c0-5-7-10-16-10
		c-13,0-12-5,6-31c22-30,22-30,37-10c25,36,33,23,33-53C12310,1056,12302,1010,12285,1010z M12312,1596c-8-9-32,14-32,30
		c0,21,27,17,33-5C12316,1610,12315,1599,12312,1596z M12320,1143c0,72,8,83,30,40c8-16,21-36,28-45c8-10,12-26,10-36
		c-4-12,0-19,8-18c7,0,13-3,13-9c1-5-7-10-17-11c-9-1-25-5-36-9C12329,1043,12320,1065,12320,1143z M12360,575c-11,7-14,48-3,58
		c14,15,25,6,19-15c-3-13-6-29-6-36S12366,572,12360,575z M12395,135l-11,120c-14,140-15,279-4,315c4,14,8,21,9,17c1-4,7-2,15,5
		s12,16,9,21c-2,4-1,16,2,28c10,31,31,7,51-59c36-124,55-210,60-277c2-38,6-94,9-123l4-53L12395,135z M12513,1103c-7,7,6,27,18,27
		c5,0,9-4,9-9C12540,1109,12520,1096,12513,1103z M12610,890c6,0,10-9,10-20s-4-20-10-20c-5,0-10,9-10,20S12605,890,12610,890z
		 M12680,117l-47,7c-25,3-47,7-49,9c-3,3-34,436-34,476c0,25,4,32,15,27c13-5,13-3,0,17c-10,16-13,37-8,74l6,52l38-2c22-1,39-4,39-8
		c0-4,8-6,18-5c15,1,17-14,20-162c1-103-1-161-7-158c-6,4-6-5,0-26c5-18,9-93,9-167V117z M12670,1030c-5,0-10,5-10,10
		c0,6,5,10,10,10c6,0,10-4,10-10C12680,1035,12676,1030,12670,1030z M12731,990c4,0,10-17,13-37c4-21,9-51,13-68c5-26,4-28-10-16
		c-9,7-21,10-27,6c-7-4-10-1-9,6c2,8-1,25-5,38C12698,942,12715,990,12731,990z M12730,120c-11,0-14,59-4,84c10,27,14,18,14-34
		C12740,143,12736,120,12730,120z M12749,680c-8,0-10-13-6-36c6-34,5-35-8-18c-14,17-14,16-4-11c14-35,18-223,6-278
		c-4-21-12-35-18-31c-6,3-9,89-8,227c2,193,4,218,16,192c12-29,12-29,6,15c-3,25-10,53-15,63c-11,22-2,38,18,30c11-4,12-9,5-16
		c-15-15-14-27,4-27c12,0,15-13,15-55C12760,701,12756,680,12749,680z M12759,960c-10,37-10,60,0,60c5,0,12-13,16-30
		C12783,952,12769,925,12759,960z"/>
	<path d="M12171,1206c-12-13-18-27-13-30c9-5,30,14,46,42C12216,1239,12195,1231,12171,1206z"/>
	<path d="M12600,581c5-11,15-32,20-47c6-15,13-24,16-21c6,5-33,87-42,87C12592,600,12594,591,12600,581z"/>
	<path d="M12740.9,2269.8c-25.6-16.6-43.8-11.4-50.9,14.6c-5.5,17.2-6.4,17.5-5.6-1.5c0.8-11.7,1.8-26.5,2.7-34.1
		c0.3-6.3-7.7-12.4-18.1-12.5c-11.4,0.1-38.6-14.9-60.1-31.7c-71.6-57.5-102.7-71.5-132.2-62c-15.1,5.4-29.5,16.8-33.4,25.2
		c-5.2,10.9-13.5,11.1-40.1-2c-18.3-9.3-37.1-13.3-41.1-9c-10.3,11.3-49.6-9.7-42.7-22.1c2.7-4.9,9.3-3.7,13.2,2.5
		c13.5,18,33.4,4,31.7-23.7c0.1-17.7-6.9-31.3-20.5-38.9c-25.6-16.6-29.4-30.1-4.6-19.5c15.3,6,19.1,4.9,22.5-8.5
		c2.6-9.1,12.5-18.1,22.1-20.9c11.5-3.3,16.9-17.3,18.1-38.5c0.1-32.3,11.6-50.1,18-28c1.4,4.8,6.7,5.4,11.4-0.1
		c4.9-4.5,4.5-16.9-1.1-25.7c-15.7-25.7,1.3-24.3,24.6,2.3c12.1,13.2,19.5,17.3,20.2,8.8c1.1-10.7,4.9-11.8,18-6.2
		c19,8.1,41.2-16,34.9-38.1c-2.2-7.7,2.9-15.4,10.6-17.6c7.7-2.2,16.6,3.6,19.4,13.2c5.4,15.1,5.4,15.1,10.3-4
		c7.3-29.1-15.3-64.3-57.8-85.4c-19.5-10-37-23.7-39.2-31.4c-1.5-8.9,2.9-8.1,14.8,0.9c17.2,12.8,18.1,12.5,19.2-5.5
		c2.3-17.3,4.5-16.9,26.9-0.4c20.9,14.8,25.3,15.6,21.4,2.2c-2.8-9.6-0.2-18.7,4.7-20.1c5.8-1.7,7.4-10.4,4.7-20.1
		c-5.5-19.2,10.2-69.5,16.4-51.5c1.9,6.7,14.3,13.6,26.8,17.3c18.8,4,21.4,2.2,12.9-5.8c-8.2-7-5.9-9.7,7-8.2
		c10.7,1.1,25.5-5.2,33.8-12.8c12.9-13.1,19.8-10.9,39.2,9.6c21.2,23.1,34.6,26.5,55.6,16.3c4.5-2.3,7.1,3.2,6.2,10.7
		c-0.4,9.5,9.4,18.1,25.7,20.7c31.3,7.7,40.3,28,21.9,50.9c-10.4,14.4-15.5,14.8-28.7,5.1c-13.5-10.7-17.7-7.4-38.2,26.5
		c-13.3,22.5-20.4,48.5-16,63.9c3.7,16.6-0.1,25-8.6,24.3c-18-1.1-22.5,44.9-4.3,50.1c6.9,2.2,16.3-4.7,20.6-15.3
		c6.4-17.5,7.7-16.8,14,5.3c4.1,14.4,4.4,29.9-0.5,34.5c-13.3,15.3-3.7,27.1,12.7,15.1c12.8-9.9,14-9.2,4.4,8.1
		c-6.4,10.2-20.7,25.7-31.1,32.9c-17.6,11.3-21,10.2-19.8-3.7c1.9-22.4-11.3-17.6-24.4,9.1c-12.5,25.4-10.4,43.6,6.4,43.9
		c5.4,0.5,13.9,8.5,18.6,17.6c8.7,19.4,46.9,36.5,56.2,25.5c4-4.3,6.3,0.3,5.6,8.8s-12.1,23.2-23.9,32.9l-23.5,16.1l27.2-6.8
		c14.7-3.2,35.8-16.5,45.9-28.8c22-25,24.4-23.6,18.3,16.6c-2,18.3-7.4,25-14.4,18.7c-17.3-16.9-37.8,9.8-29.8,37.7
		c7.9,23.8,9.1,24.4,16.5,6.7c8.2-22.1,37.3-36.7,41.7-21.3c1.4,4.8-2.9,15.4-8.6,24.3c-5.8,8.9-9.5,21.4-7.5,28.2
		C12822.1,2273.6,12764.9,2284.7,12740.9,2269.8z M12751.8,2220.9c-11.1-6.2-25.3,13.5-15.2,23.1c5.5,4.7,11.3,3,16.1-5.6
		C12756.8,2230.9,12756.8,2223.6,12751.8,2220.9z M12725.8,2191.9c-1.4-4.8-9.2-6.7-16.9-4.5c-14.4,4.1-15.9,17-2.3,24.6
		C12716.5,2217.5,12729.4,2204.4,12725.8,2191.9z M12679.4,2175.1c-1.4-4.8,8-15.8,20.6-22.5l24-14.2l-28-10.7
		c-15.3-6-24.5-12.8-19.7-14.1c4.8-1.4,1-7.6-8-13.3c-18.1-12.5-35.2,0.7-22,17.7c4.8,5.9,0.7,20.6-8.3,32.6
		c-14,16.5-14.1,19.7,0.3,15.5c8.7-2.5,18.3,2,20.5,9.7s8.9,13,13.7,11.7C12678.3,2185.8,12681.1,2180.8,12679.4,2175.1z
		 M12596.9,2141.5c6.3-14.3,3.3-17.6-21.3-19.9c-36.3-3.1-37.2-2.8-32.8,12.5c3.9,13.5,28.5,30.3,40,27
		C12586.7,2160,12592.4,2151.1,12596.9,2141.5z M12487.3,2122c-0.8-2.9-6.1-6.6-12.6-7.8c-5.6-1.5-9.9,1.8-8.5,6.6
		c1.7,5.8,7.8,9.2,12.6,7.8C12484.6,2126.9,12487.9,2123.9,12487.3,2122z M12677.7,1842.7c-1.4-4.8-6.6-8.5-12.4-6.9
		c-4.8,1.4-8.2,7.6-6.9,12.4c1.7,5.8,7.6,8.2,12.4,6.9C12676.6,1853.4,12679.4,1848.4,12677.7,1842.7z"/>
	<path d="M11969,1764c-15-15-15-64,0-64c7,0,31,58,31,73C12000,1785,11985,1781,11969,1764z"/>
	<path d="M11960,1601c0-11,4-22,9-25c4-3,6,6,3,19C11967,1625,11960,1628,11960,1601z"/>
	<path d="M11940,1554c0-8,5-12,10-9c6,3,10,10,10,16c0,5-4,9-10,9C11945,1570,11940,1563,11940,1554z"/>
	<path d="M11990,1551c0-5,5-13,10-16c6-3,10-2,10,4c0,5-4,13-10,16C11995,1558,11990,1557,11990,1551z"/>
	<path d="M12061,1506c-6-16-10-34-8-40c6-17,29,33,24,52C12074,1531,12070,1528,12061,1506z"/>
	<path d="M13260,1465c0-8,9-19,21-25c20-11,20-11,4,14C13265,1484,13260,1486,13260,1465z"/>
	<path d="M13152,1272c-21-7-13-20,18-31c17-5,30-8,30-5C13200,1247,13162,1276,13152,1272z"/>
	<path d="M11541,1018c-1-17-38-44-55-40c-13,4-56-39-56-56c0-12,4-12,20,3c15,14,20,15,20,4c1-8,7-3,15,11s14,19,15,13
		c0-21,21-15,27,7c3,11,12,20,19,20c16,0,16,6,4,35C11543,1032,11541,1032,11541,1018z"/>
	<path d="M13358,918c12-12,25-19,28-15c4,4-1,14-12,22C13345,947,13333,943,13358,918z"/>
	<path d="M11502,890c0-14,2-19,5-12c2,6,2,18,0,25C11504,909,11502,904,11502,890z"/>
	<path d="M11837,873c-4-3-7-11-7-17s5-5,12,2c6,6,9,14,7,17C11846,878,11840,877,11837,873z"/>
	<path d="M11466,848c-10-38-6-45,9-18c17,29,18,40,6,40C11476,870,11470,860,11466,848z"/>
	<path d="M11968,833c-10-2-18-13-18-23s-3-27-7-37c-3-10-2-15,2-10c5,4,14-5,21-20l12-28l7,32c4,18,12,31,19,30c6-1,11,4,10,11
		c-1,6-2,21-2,32C12010,840,12002,842,11968,833z"/>
	<path d="M11610,765c0-8,7-15,15-15c9,0,12,6,9,15c-4,8-10,15-15,15S11610,773,11610,765z"/>
	<path d="M12190,713c0-12-5-25-10-28c-12-7-4-25,12-25c5,0,8,6,5,14c-3,7,0,19,6,25c8,8,8,14-1,23S12190,729,12190,713z"/>
	<path d="M12250,665c0-11,5-27,10-35c8-12,10-12,10,5c0,11-5,27-10,35C12252,682,12250,682,12250,665z"/>
            </g>
            </svg>
    )
}

export default Splash1;