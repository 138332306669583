 function Splash5() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1920.000000 1080.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,1030.000000) scale(0.100000,-0.100000)"
            fill="#40868d" stroke="none">
	<path d="M19097,388c-45,18-119,43-164,56c-45,14-90,29-100,34s-63,22-118,36c-55,15-122,35-150,45c-27,10-97,28-155,40
		c-58,12-125,28-150,36c-25,7-130,28-234,45s-192,33-196,35s-90,18-191,35c-322,53-823,184-1074,280c-27,11-88,33-135,50
		s-94,35-105,40c-11,5-65,26-120,46c-220,79-364,126-489,159c-203,53-345,94-395,115c-22,8.8-53.3,19.8-88.9,31.6
		c-147.3,12.1-288.9,51.7-424.9,108.3c-15.5,4.9-29.8,10.2-42.2,16.1c-36.9,16.6-138.5,53.1-225.7,81.9
		c-13.5-19.7-39.5-32.9-67.2-21.1c-117.1,49.7-232.9,102.2-348,156.3c-57.7,20.3-110.4,39.7-149.2,55c-287,115-472,194-510,218
		c-11,7-45,22-75,34c-56.4,21.9-266.3,126.5-405.3,200.9c-100,27.4-189.9,74.1-268.1,135.5c-9-4.1-19.6-5.7-31.7-3
		c-308.7,68.5-550,260.5-792.1,452.3c-27,17-56.7,35.7-76.8,48.8c-142.9,42.9-272.8,126.1-388.4,228.7
		c-37.9,22.6-77.1,46.2-102.5,61.8c-65,41-178,108-235,141c-46,26-154,75-220,101c-35,13-82,33-105,43
		c-16.2,7.3-48.5,20.1-80.8,31.9c-78,6.4-155.3,19.3-230,43.8c-59.8,19.5-117.3,45-173.5,73.1c-5.4,0.5-10.7,0.9-15.8,1.3
		c-98,7-272,41-435,85c-30,8-57,15-60,15s-28-16-55-36c-58-42-206-114-309-150c-39-14-125-52-191-84c-94-47-136-62-193-69
		c-51-7-88-19-125-40c-113-67-267-140-338-162c-41-12-79-26-85-30c-6-5-64-22-130-38c-65-16-157-40-204-55c-47-14-129-32-182-41
		c-53-8-132-24-176-35c-98-24-384-71-792-129c-36-5-132-12-215-15c-168-8-215-15-365-58c-58-17-175-47-260-68
		c-45.2-10.6-90.5-21.8-126-30.9c-98.5-48.1-202.3-83.2-312.1-99c-144.7-20.8-291.2-15.6-437-15.4c-8.5,0-15.9,1.8-22.1,4.8
		c-105.2-3.4-236.9-6.5-367.7-8.6l-460-8l-70-25c-187-70-336-120-394-134c-36-8-76-23-90-33c-14-10-96-48-181-84
		c-40.3-17.1-89.4-39.1-136.9-61.2c-255.3-170.7-517.4-335.8-822.4-377.2c-13-5.5-27.7-11.6-42.7-17.6c-43-17-86-35-95-40
		c-10-5-62-30-115-55c-40-18.7-75.3-35.2-106.9-50.1c-128.4-78.7-260.9-148-400.3-199.4c-4.5-2.7-8.2-4.9-10.8-6.5
		c-24-17-119-62-442-210c-87-39-197-83-245-97c-49-14-108-31-133-38c-56-16-229-35-314-35c-31.7,0-166.9-18.1-311.8-41.5
		c-8.7-2.3-17.4-4.6-26.1-6.9c-70.3-18.3-140-31.3-210.6-34.4c-95.1-18.3-142.6-31.3-187.5-49.1L5,984L2,492L0,0h19200v149
		C19200,347,19199,349,19097,388z"/>
            </g>
        </svg>
    )
}

export default Splash5;