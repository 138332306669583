function DarkBlue() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1920.000000 540.000000"
            preserveAspectRatio="xMidYMid meet"
            className='dark-blue'
            >

            <g transform="translate(0.000000,540.000000) scale(0.100000,-0.100000)"
            fill="#0f2d30" stroke="none">
<path d="M0 2700 l0 -2700 9600 0 9600 0 0 2700 0 2700 -9600 0 -9600 0 0
-2700z m8335 2433 c107 -38 114 -39 246 -28 114 10 148 2 206 -45 l48 -40 106
0 c65 0 125 6 155 15 27 8 55 15 62 15 9 0 12 -38 12 -155 l0 -155 120 0 120
0 0 -2050 0 -2050 -120 0 -120 0 0 -149 0 -150 -47 -5 c-54 -6 -321 -72 -428
-106 -67 -20 -82 -21 -325 -16 -140 3 -275 11 -300 17 -25 6 -169 14 -320 18
-252 7 -279 10 -322 30 -57 26 -221 51 -345 51 -64 0 -123 -7 -195 -25 -92
-22 -124 -25 -300 -25 -118 0 -208 4 -225 11 -36 14 -150 45 -278 75 -109 26
-171 30 -225 14 -19 -6 -124 -14 -234 -19 l-198 -9 -57 -37 c-81 -52 -150 -83
-212 -96 -69 -13 -217 -3 -334 23 -67 15 -150 22 -325 28 -129 5 -253 13 -275
18 -22 6 -80 13 -130 17 -49 4 -107 14 -128 23 -30 11 -59 13 -125 8 -105 -8
-229 -21 -337 -36 -89 -12 -209 -5 -269 16 -21 8 -76 14 -122 14 -81 0 -85 1
-121 33 -21 18 -54 46 -74 60 l-36 27 -74 -15 c-43 -9 -134 -17 -214 -18 -77
-1 -142 -5 -145 -8 -3 -4 -156 -12 -340 -19 -184 -6 -381 -14 -436 -16 -92 -5
-109 -3 -175 21 -41 15 -110 30 -154 35 -177 17 -262 47 -332 117 -23 23 -64
51 -90 63 -26 11 -62 32 -80 46 -19 14 -41 23 -49 20 -9 -4 -18 3 -23 17 -5
12 -22 32 -38 44 -37 26 -51 77 -57 194 -3 84 -4 87 -56 169 -43 66 -54 93
-58 135 -5 63 16 142 64 236 36 73 37 70 -7 169 -16 36 -55 139 -88 230 -53
149 -59 176 -66 275 -4 65 -14 122 -23 140 -11 23 -17 85 -23 255 -8 245 -27
396 -61 505 -36 114 -31 208 21 395 24 85 58 223 77 305 70 304 95 398 128
483 32 82 34 92 29 178 -8 136 -2 157 57 193 53 32 74 59 86 105 5 23 19 34
69 57 35 16 74 42 87 60 l25 31 54 -27 c30 -16 68 -38 84 -51 l30 -22 105 32
c58 18 164 56 235 84 72 29 158 57 190 63 33 6 104 21 158 35 142 36 264 41
403 19 103 -17 115 -17 131 -3 14 12 54 17 168 22 119 5 157 10 190 26 23 11
45 20 49 20 3 0 42 -20 86 -45 l79 -45 493 0 c271 1 522 5 558 10 188 26 276
43 359 70 l92 31 427 -3 427 -3 72 -32 c62 -29 83 -33 160 -34 120 -2 242 41
258 92 6 18 10 16 52 -20 36 -31 51 -37 67 -31 12 4 44 17 71 28 43 17 93 21
340 31 160 7 346 20 415 29 83 11 213 17 385 19 279 2 583 10 865 22 229 10
275 6 395 -36z m3410 13 c26 -8 65 -29 86 -45 l38 -31 278 0 c220 0 314 -4
444 -20 90 -11 172 -23 181 -26 16 -5 51 4 201 53 70 22 91 25 185 21 91 -4
120 -10 190 -37 46 -18 96 -44 111 -57 26 -21 39 -24 117 -24 48 0 138 -5 198
-11 101 -10 120 -9 211 11 145 32 187 34 310 16 60 -9 204 -21 320 -27 196
-10 220 -9 355 11 177 27 487 38 610 21 47 -6 185 -18 307 -26 240 -16 346
-33 427 -69 46 -21 74 -24 256 -30 557 -19 587 -19 733 3 140 21 336 27 427
12 32 -5 63 -2 105 11 94 28 127 24 164 -17 24 -28 42 -37 94 -48 70 -14 186
-75 264 -139 26 -22 102 -92 168 -157 111 -109 126 -128 184 -237 95 -177 103
-217 108 -536 6 -306 -8 -552 -33 -579 -14 -15 -14 -34 -4 -151 21 -250 26
-269 95 -370 81 -117 63 -384 -36 -549 -34 -57 -39 -73 -39 -125 0 -63 -76
-397 -107 -467 -9 -21 -29 -49 -45 -62 -35 -30 -35 -48 2 -117 l30 -55 -40
-74 c-49 -89 -61 -102 -125 -144 -27 -18 -77 -57 -111 -87 -53 -47 -75 -59
-164 -88 -82 -27 -112 -43 -152 -78 -28 -26 -47 -51 -44 -58 3 -8 -8 -24 -24
-36 -16 -12 -32 -30 -36 -40 -3 -10 -17 -32 -31 -48 -21 -25 -32 -30 -70 -30
-29 0 -65 -9 -98 -25 -46 -23 -68 -26 -206 -31 -85 -4 -180 -13 -212 -20 -32
-8 -110 -21 -173 -28 -102 -12 -123 -18 -195 -55 -70 -37 -110 -48 -297 -87
-151 -32 -261 -48 -360 -55 -116 -8 -158 -15 -220 -38 -42 -16 -111 -36 -152
-44 -42 -9 -102 -25 -133 -37 -56 -21 -60 -21 -135 -6 -51 10 -119 36 -200 76
l-123 60 -107 0 c-120 0 -342 -23 -385 -39 -18 -7 -121 -11 -274 -11 l-245 0
-81 36 c-92 40 -110 40 -236 -3 -68 -23 -85 -25 -195 -20 -96 4 -151 12 -266
42 l-145 38 -215 -12 c-118 -6 -360 -11 -537 -11 l-323 0 -177 -45 c-175 -45
-178 -45 -347 -45 -94 0 -188 5 -208 10 -20 6 -61 27 -90 47 -99 67 -99 67
-373 71 -166 3 -272 0 -315 -8 -102 -19 -251 1 -350 45 l-78 35 -191 0 -191 0
0 95 0 95 -125 0 -125 0 0 2050 0 2050 125 0 125 0 0 156 0 156 35 -21 c19
-11 59 -27 88 -35 28 -8 87 -27 130 -42 70 -25 82 -27 110 -15 129 54 129 54
348 46 191 -6 208 -5 250 13 132 59 186 74 294 83 133 11 186 23 245 54 53 28
123 32 195 11z"/>
            </g>
            </svg>
    )
}

export default DarkBlue;