 function Splash0Right() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1920.000000 1080.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
        fill="#0c2426" stroke="none">
        	<path d="M10550,10788c14-5,33-14,42-19c25-13,28-12,28,11c0,17-6,20-47,19C10530,10799,10528,10798,10550,10788z"/>
	<path d="M10760,10774c-15-38-18-270-4-284c17-17,50,63,53,124c3,80,15,88,45,29c33-66,107-139,119-117c15,26-3,72-63,163
		c-63,96-70,103-112,108C10775,10799,10767,10795,10760,10774z"/>
	<path d="M10962,10706c53-52,106-100,118-106c21-11,22-11,15,29c-5,33-4,41,8,41c33,0-9,78-61,112c-20,13-47,18-101,18h-75
		L10962,10706z"/>
	<path d="M11231,10783c-5-10-8-29-6-43c1-14,3-42,4-62c1-47,16-49,36-5c8,17,26,47,40,66c37,50,33,61-20,61
		C11253,10800,11238,10795,11231,10783z"/>
	<path d="M11370,10770c13-47,22-57,31-34c16,41,10,64-15,64C11364,10800,11362,10797,11370,10770z"/>
	<path d="M11460,10794c0-3,16-26,36-52c19-25,33-53,31-62c-13-43-12-45,15-45c14,0,29-3,33-7s17,5,29,19c29,34,45,27,84-34
		c51-80,62-93,76-93c15,0,29,52,20,75c-8,20,10,19,30-1c22-22,20-39-9-70c-37-39-29-60,20-56c33,2,47,10,82,49c38,42,45,46,58,32
		c13-12,13-24,5-67c-8-44-6-64,11-120c12-37,23-73,25-79c2-7,13-13,24-13c16,0,20,6,20,33c0,45,8,50,40,27c40-29,48-25,45,21
		c-2,33,6,73,21,107c0,1,20-5,44-13c32-11,45-12,51-4c10,17-1,47-27,72c-29,27-30,37-3,37c18,0,20,4,14,29c-8,35,13,44,60,25
		c17-7,38-15,49-18c15-5,17-12,11-39c-14-57-4-114,42-245c25-73,39-226,23-266c-7-19-4-58,10-143c11-64,19-118,17-119
		c-2-2-18-14-37-26c-25-17-36-35-44-68c-10-40-16-47-58-67c-98-46-225-50-268-7l-25,25l25,21c21,17,22,22,10,31c-22,17-18,67,8,104
		c12,17,22,38,22,46c0,24-56,32-85,13c-13-9-27-16-29-16c-3,0-3,34,0,75c3,54,0,85-11,111l-15,37l-30-28c-17-16-30-35-30-42
		c0-8-9-31-20-53c-24-47-26-132-5-174c19-36,19-46-5-76c-21-28-37-22-21,8c8,16,7,25-8,43l-18,23l-8-25c-10-31-22-39-67-39
		c-50,0-58-18-22-47c20-16,42-23,72-23h42v-40c0-34-4-42-30-55c-37-19-59-19-66,0c-4,9-13,13-23,10c-11-3-27,5-42,21
		c-27,29-79,59-79,46c0-4,16-23,35-42s35-39,35-44c0-6-16-20-37-31c-34-19-103-118-90-131c3-3,22,2,42,10c21,9,49,16,62,16
		c21,0,24-4,19-22c-20-73-29-159-19-169c9-9,15-6,23,14c34,87,31,83,41,64c6-10,8-25,5-35c-3-9,1-39,8-67l13-50l15,30
		c9,18,13,45,11,66c-7,54,12,57,57,10c46-48,53-50,44-15c-4,16-2,29,5,34c7,4,14,33,16,65c4,65-17,120-54,143c-24,14-28,57-7,65
		c8,3,24-5,37-19c27-29,53-30,97-4l33,20l-28,24c-41,36-21,42,38,12l52-26l32,20c23,14,50,20,90,20c59,0,89,7,179,41
		c55,21,96,25,96,8c0-6-8-23-19-37c-22-32-48-169-33-184c11-11,120,85,133,119c12,31,36,39,58,19c10-9,22-16,28-16s13-13,16-29
		c10-50,79-154,141-214c54-52,141-106,152-94c8,7-18,120-32,143c-18,27-5,39,34,29c15-4,42-8,60-10c29-2,32,1,27,19
		c-7,24-21,58-43,103c-12,27-14,58-9,172c4,96,2,142-5,149c-22,22,3,25,67,7c36-9,68-15,72-12c5,6-11,155-23,210c-9,38,6,33,41-15
		c22-29,46-46,89-63l58-24l-5,34c-3,19-18,50-32,69c-16,21-25,44-23,58c2,19,12,24,53,32c28,5,52,10,54,12c2,1-6,15-17,31
		c-13,19-18,38-15,61c6,40,14,45,48,32c24-9,26-14,23-58c-3-45-2-47,22-45c35,3,132-30,144-50c7-11,3-25-17-54c-37-53-34-133,7-180
		c9-10,27-31,40-47c13-17,28-26,34-22c15,9,16,38,2,52c-5,5-14,32-20,59c-5,28-13,61-16,73c-11,40,37,25,61-19c44-78,59-94,93-97
		c29-3,32-1,32,25c-1,38-19,67-59,95c-19,13-36,29-39,36c-7,21,23,13,69-18c24-16,51-30,60-30s24-9,33-19c14-15,15-24,7-40
		c-20-36-14-54,14-47c20,5,25,3,25-13c0-17,9-20,63-23c80-5,109,5,105,34c-2,18-12,24-47,31l-44,9l27,21c31,25,27,57-7,57
		c-11,0-35,11-53,25s-54,34-79,45s-45,23-45,28c0,4,42,8,93,8c101,0,142,7,150,27c3,9-6,13-32,14c-20,0-95,4-166,8
		c-114,7-132,5-144-9c-21-25-29-19-29,19c0,35,12,54,22,36c3-5,38,1,78,14s94,26,121,30c76,12,55,35-31,35c-26,0-64-9-89-21
		c-54-26-71-22-46,11c10,14,26,42,36,63l17,38l-31-18c-17-10-48-34-70-54c-33-31-39-42-39-77c0-56-13-53-28,5c-11,40-11,48,2,55
		c8,5,21,22,30,39c14,29,14,30-7,38c-20,7-16,11,32,36l54,27l-39,22c-49,27-59,59-17,54c26-3,28-1,25,27c-4,42-22,86-45,112
		c-25,28-18,37,17,23c15-5,37-10,48-10c12,0,36-11,54-25c40-31,54-32,54-2c0,12,5,29,10,37c9,13,14,11,33-10c12-14,41-35,65-48
		c40-20,43-21,48-5c18,57,26,64,66,58c38-7,39-6,32,19c-4,19-2,26,9,26c24,0,65-49,96-115c45-95,67-101,30-8c-16,41-10,47,40,38
		c36-6,41-10,47-40c3-19,14-41,25-51c18-16,19-15,29,10c5,15,10,36,10,47c0,12,4,17,11,14c6-4,13,2,16,14s9,21,14,21s6,7,3,15
		c-11,27,7,38,30,18c32-27,66-44,76-38c4,3,11,18,15,34c7,32,31,58,43,47c4-4,8-27,9-49c1-23,5-59,8-79c6-33,11-38,34-38
		c14,1,37,7,51,15c40,23,50,19,50-23c0-37,37-105,99-182l20-25l16,39c16,39,28,95,27,131c-1,115-40,229-88,268c-13,10-24,27-24,38
		s-5,29-10,40c-10,19-31,19-928,19h-918v-35c0-19-3-38-7-41c-9-9-55,33-63,58c-4,15-16,18-70,18s-64-3-64-17c0-10,7-27,15-37
		c8-11,15-31,15-45c-1-26-1-26-35,11c-72,78-119,88-422,88c-161,0-243-4-245-11s-12-7-31,0C11918,10800,11460,10805,11460,10794z
		 M13077,10764c-9-9-28,6-21,18c4,6,10,6,17-1C13079,10775,13081,10768,13077,10764z M11742,10765c0-5-5-11-11-13s-11,4-11,13
		s5,15,11,13S11742,10770,11742,10765z M13600,10740c0-11-4-20-10-20c-5,0-10,9-10,20s5,20,10,20
		C13596,10760,13600,10751,13600,10740z M13070,10738c0-25-18-44-30-32c-8,8-7,16,5,28C13065,10753,13070,10754,13070,10738z
		 M12543,10668c43-33,57-49,57-68c0-14,9-43,20-65c24-48,25-55,3-55c-14-1-11-7,13-28c17-15,39-32,50-38c18-11,17-14-16-49
		c-26-27-41-35-59-32c-14,3-32,0-41-5c-11-7-29-6-60,5c-32,11-50,12-64,5c-24-13-30-3-52,89c-17,72-17,75,3,121c22,49,47,115,57,145
		C12462,10719,12479,10714,12543,10668z M12388,10637c3-23,0-28-15-25c-11,2-19,14-21,31c-3,23,0,28,15,25
		C12378,10666,12386,10654,12388,10637z M11852,10618c-7-7-12-8-12-2c0,14,12,26,19,19C11861,10632,11858,10624,11852,10618z
		 M12835,10603c0-16-4-34-8-40c-5-7-5-18-2-23c4-7-3-9-21-6c-15,3-33,1-40-5s-18-8-24-4c-16,10-12,35,5,35c9,0,18,7,21,15
		c4,8,14,15,24,15s22,9,25,20C12824,10639,12835,10635,12835,10603z M13200,10600c0-5-2-10-4-10c-3,0-8,5-11,10c-3,6-1,10,4,10
		C13195,10610,13200,10606,13200,10600z M13006,10529l16-31l32,31c17,17,36,31,41,31c14-1,65-77,65-96c0-8-5-24-10-35
		c-9-16-7-19,15-19c27,0,33-18,13-38c-10-10-10-17,0-32c10-17,10-21-2-26c-25-10-51,0-61,23c-6,15-16,21-26,18s-20-1-24,5
		c-3,5-2,10,4,10c5,0,17,7,26,17c13,13,19,13,25,4c5-9,10-10,14-2c4,6,3,11-2,11c-6,0-8,18-5,42c4,29,3,39-5,34c-7-4-12-16-12-26
		s-3-21-8-24c-19-12-168,106-157,124C12958,10571,12990,10559,13006,10529z M13326,10511c-9-14-33-14-41-1c-3,6-3,20,0,31l7,21
		l20-20C13323,10531,13329,10517,13326,10511z M14780,10540c0-5-4-10-10-10c-5,0-10,5-10,10c0,6,5,10,10,10
		C14776,10550,14780,10546,14780,10540z M12977,10469c3-14-24-56-31-48c-2,2-6,21-10,41c-7,36-6,37,16,29
		C12965,10487,12976,10477,12977,10469z M12760,10467c0-17-29-57-41-57c-14,0-11,23,7,48C12743,10482,12760,10487,12760,10467z
		 M12899,10466c10-11,8-16-9-21c-24-8-46,7-36,24C12864,10484,12885,10483,12899,10466z M12930,10393c-1-5-9-18-20-31
		c-16-19-23-21-40-12c-23,12-25,21-8,38C12875,10401,12930,10405,12930,10393z M13040,10371c0-5-9-7-20-4s-20,7-20,9s9,4,20,4
		S13040,10376,13040,10371z M12854,10307c11-12,32-30,46-40c21-15,23-19,10-27c-8-5-29-10-47-10c-25,0-38-8-59-35
		c-15-19-42-42-60-51c-35-17-45-34-19-34c15,0,19-10,15-45c-3-29-15-38-44-31c-27,7-35,33-16,52c12,12,3,44-11,44c-5,0-9,8-9,18
		c0,9-11,34-25,54c-29,42-31,58-10,58c8,0,15,5,15,10c0,6,7,10,15,10c11,0,15-12,15-44c0-44,21-81,43-73c5,1,17,23,27,46
		c13,35,22,45,44,47c23,2,26,0,21-17c-4-10-3-19,1-19c15,0,44,34,44,52c0,21-11,23-28,6c-13-13-15-5-6,30
		C12824,10336,12828,10336,12854,10307z M13395,10318c29-13,33-28,7-28c-19,0-42,18-42,32C13360,10333,13364,10332,13395,10318z
		 M13020,10250c0-15-28-30-44-24c-27,10-18,31,14,31C13007,10257,13020,10253,13020,10250z M12478,10195c-12-27-28-24-28,6
		c0,12,7,19,20,19C12487,10220,12488,10217,12478,10195z M13230,10205c0-9-9-15-25-15c-24,0-32,10-18,23
		C13199,10226,13230,10220,13230,10205z M13390,10190c0-5-5-10-11-10c-5,0-7,5-4,10c3,6,8,10,11,10
		C13388,10200,13390,10196,13390,10190z M13576,10083c-10-10-19,5-10,18c6,11,8,11,12,0C13580,10094,13579,10086,13576,10083z
		 M12956,10065c-7-18-26-20-26-2s24,41,28,27C12960,10084,12959,10073,12956,10065z M12571,9938c-41-77-51-83-98-52
		c-25,16-31,62-11,82c7,7,26,12,43,12c16,0,41,7,55,14C12608,10021,12610,10010,12571,9938z M12845,9860c3-5-1-10-9-10
		c-9,0-16,5-16,10c0,6,4,10,9,10C12835,9870,12842,9866,12845,9860z M11950,9697c0-13-33-67-41-67c-22,0-22,34,0,52
		C11930,9698,11950,9706,11950,9697z M11750,9445c-11-12-20-27-20-34s-5-9-10-6c-11,7-3,53,13,73c7,9,13,10,24,1
		C11768,9469,11767,9464,11750,9445z"/>
	<path d="M14730,10791c0-5,7-12,16-15c10-4,13-13,9-27c-5-15,4-36,31-76c37-54,39-56,189-123c63-29,212-41,245-20
		c31,19-108,149-200,186c-8,4-27,11-43,18c-31,12-31,29,1,34c11,2,26,10,32,18c11,12-8,14-134,14
		C14796,10800,14730,10796,14730,10791z"/>
	<path d="M15430,10795c0-2,30-13,68-24c59-18,74-19,126-8c33,7,61,18,64,25c3,9-27,12-127,12C15489,10800,15430,10798,15430,10795z"
		/>
	<path d="M15743,10775c-26-14-63-25-81-25c-33,0-172-63-172-78c0-5-11-15-25-22s-35-30-47-49c-25-41-57-105-54-108c1-1,35,3,76,8
		c78,11,127,31,137,55c9,23,33,16,44-13c6-16,17-35,25-44c14-16,19-15,59,8c24,13,47,33,50,44c8,24,45,26,45,2c0-10-7-31-15-47
		c-9-16-14-31-12-33c3-2,27-6,56-10c54-6,65-18,29-32c-15-5-18-10-9-15c9-6,9-11-1-24c-11-12-11-16,0-20c10-3,7-11-11-31
		c-29-32-41-61-24-61c7,0,32,12,56,26c44,25,45,25,78,7c36-19,40-27,21-40c-17-10-107-49-196-83c-40-16-79-34-87-40
		c-12-10-15-10-15,0c0,8-10,9-30,5c-43-9-39,12,6,31c36,15,36,16,30,57c-4,23-14,53-23,67l-16,24l-38-42c-21-24-41-56-44-72
		c-4-17-19-39-34-50c-27-18-29-19-43-2c-9,9-19,27-22,39c-9,28-51,58-95,68c-38,8-51-3-51-47c0-29-9-35-25-19c-11,10-122,30-129,23
		c-8-7,31-102,50-123c22-24,11-24-40,0c-62,29-86,34-86,19c1-54,38-132,93-194c12-15,15-24,8-29c-6-3-11-19-11-36c0-43-13-36-43,24
		c-14,28-53,79-86,112c-70,68-86,66-78-12c5-46,8-61,23-130c8-41-5-42-34-3c-24,32-118,85-126,71c-3-4-8-26-12-48
		c-8-54,31-138,81-172c42-28,44-38,8-52c-16-6-40-17-55-25c-34-18-58-18-58,1c0,23-14,18-38-15c-13-16-30-30-40-31c-14,0-14-2,3-9
		c19-7,19-8,1-15c-11-5-21-3-26,5c-6,9-13,8-33-4c-23-16-27-16-63,5c-49,28-48,28-42,34c3,3,26,0,51-7c41-10,48-10,65,5
		c11,10,26,15,36,11c25-10,56,69,48,120c-6,33-15,45-62,77c-38,26-58,35-67,28c-18-16-25-62-14-92c14-35,1-35-54,2
		c-47,32-76,39-188,47l-79,6l13-23c14-26,83-95,119-119c34-22,50-40,50-54c0-7,15-38,34-69c47-78,41-96-33-95c-57,1-133-21-99-29
		c25-6,45-44,53-98c6-39,19-60,71-120c35-41,64-76,64-79s-19-15-42-27c-46-23-78-47-78-60c0-4,19-10,42-14l42-7l-89-42
		c-50-24-96-50-103-58c-11-14-7-17,33-22c25-4,45-12,45-17c0-6,10-9,22-7c37,5,32-26-9-70c-21-22-60-63-87-91c-27-29-46-54-43-57
		c8-9,88,16,147,46c52,26,150,117,150,140c0,5,5,10,10,10c15,0,12-27-5-53c-42-65-97-251-87-292c8-29,9-28,62,58c42,69,56,85,67,76
		c17-15,43,45,52,121c6,53,7,55,36,55c20,0,31,6,33,17c2,9,10,19,18,22c19,7,19-44-1-70c-8-10-15-28-15-40c0-11-5-25-11-31
		c-5-5-9-36-7-67l3-56l47,46c26,25,62,76,80,112c28,57,33,78,33,140c0,48,4,72,12,72c6,0,19,7,28,16c14,15,20,14,66-5l50-22l39,22
		c40,23,57,49,44,70c-3,6-24,19-46,29c-34,15-37,19-23,30c13,11,18,10,29-5c12-17,15-15,39,26c20,36,24,52,19,84c-6,33-13,43-40,54
		c-32,15-47,39-16,27c9-4,23-1,30,5c11,9,18,8,29-1c20-17,23-13,35,49c5,28,14,60,19,70c14,26,2,120-20,159c-23,40-24,52-4,52
		c8,0,15-6,15-13s26-39,58-72c45-46,70-63,107-73c26-7,49-11,51-10c1,2-2,26-8,53c-13,62-43,103-115,156l-55,41l35,36l36,35l36-29
		c73-57,68-56,111-38c50,21,48,17,29,53c-13,26-21,31-51,31c-20,0-33,4-29,10c3,5,11,7,19,4c17-6,63,11,108,43c20,13,41,22,46,19
		s27,6,47,20c35,24,41,25,86,14c39-10,49-16,49-33c0-12,30-49,76-94c63-61,71-73,52-73c-13,1-34,7-48,15s-38,14-55,15
		c-16,0-43,7-59,15c-36,19-59,5-50-31c5-20,3-23-12-18c-11,4-26,12-34,18c-19,15-90,39-96,33c-5-5,64-79,119-127c31-27,40-31,47-19
		c8,11,12,11,24-6c12-16,16-17,30-6c9,7,16,22,16,32c0,14,7,19,28,19c15,0,32-4,38-10c5-5,34-14,64-20c29-6,56-12,58-15
		c9-9-4-15-29-14c-13,0-40,1-61,1c-20,1-48-7-64-17l-28-18l24-19c27-22,27-21-23-42c-25-10-45-13-55-7c-16,8-56,6-109-5
		c-42-8-25-30,35-44c35-8,49-15,37-18c-11-2-24-8-29-13c-6-5-17-9-25-9c-9,0-36-18-61-40l-45-39l43-1c63,0,192,72,192,107
		c0,7-4,13-10,13c-20,0-9,21,18,32c15,7,38,20,51,30s34,18,46,18c13,0,37,6,55,14c35,14,70,11,70-8c-1-12-76-63-122-83
		c-16-7-28-19-28-28c0-14,8-15,58-9c31,3,69,13,83,20c38,19,78,70,72,92c-2,10,4,28,14,40l18,23l40-21c21-11,45-20,52-20s17-9,23-20
		c14-25,37-26,45-1c9,30,31,43,59,35c51-12,19-70-45-83l-32-6l33-39c41-46,34-65-31-81c-50-13-89-32-89-43c0-18,109-45,154-39
		c54,8,58-1,14-28c-38-21-138-106-138-115c0-4,23-17,50-30c28-13,56-30,62-37c19-23-69-17-99,7c-19,15-29,17-39,9c-7-6-22-8-34-4
		c-28,9-26-9,10-62c33-49,38-68,14-59c-12,4-14,1-9-14c5-16,2-20-17-20c-22,0-64-19-43-20c6,0,3-5-5-10c-8-6-31-8-50-5
		c-52,8-68-7-35-33l26-20l-31-18c-34-21-37-29-12-38c29-10,75-41,80-54c2-6-9-14-25-18c-15-4-40-16-54-28c-15-12-35-26-45-33
		c-17-10-16-12,18-27c21-9,65-16,102-16c97,0,119-16,76-55c-28-26-25-38,9-30c23,5,35,1,53-17c70-69,83-128,30-128
		c-44,0-74-11-68-26c6-16-10-29-19-15c-6,11-122,8-132-3c-4-3-4-7-2-9c57-39,88-57,98-57c8,0,11-6,7-16c-3-9-6-17-6-19s-29-13-64-25
		c-35-11-72-30-82-42l-19-21l69,7c65,6,89,16,181,71c24,15,48,22,62,19c38-10,61,3,55,31c-3,16,0,25,9,25c7,0,0,13-15,29
		c-27,28-28,33-22,85c4,31,12,56,18,56c5,0,8,6,5,13c-6,15,40,85,65,101c26,16,36-1,40-67c2-31,7-58,13-60c14-3,83,92,97,136
		c7,20,18,38,23,40c6,1,24-23,40-54c54-102,65-118,99-151c19-18,41-50,49-70c11-27,24-39,44-44c56-12,64-16,60-27c-2-7-16-10-32-9
		c-26,4-27,2-21-25c11-40,33-61,70-65c23-2,31-8,31-23c0-17-8-21-50-25c-27-3-59-11-70-17c-32-18-87-82-83-95c3-7-5-21-16-31
		c-21-18-20-18,31-12c64,7,78-8,39-42c-15-13-31-37-36-53l-8-29l26,16c15,9,42,30,59,47c35,32,56,29,35-5c-7-11-17-31-22-45
		c-24-63,55,8,94,85l23,44l27-39c14-21,26-45,26-52c0-8,6-12,13-10c20,7,40,105,28,140c-6,17-8,34-5,39s28,9,57,9c67,0,50,21-23,28
		c-31,3-54,10-57,19c-4,10-9,11-19,2c-19-15-41,3-49,41c-5,21-1,34,14,49c27,27,37,26,73-4c30-25,72-33,83-16c3,5-6,22-21,38
		c-20,22-24,34-19,60c4,24,2,33-7,33c-18,0-33-12-41-32c-3-10-11-18-18-18c-6,0-8,7-5,16s6,22,6,30s19,23,43,33c46,21,92,73,109,124
		c6,17,15,36,19,41c5,6,9,27,9,47c0,37-1,38-27,28c-33-11-81-53-106-92c-22-34-29-34-47,5c-31,66-70,60-70-10c0-25-5-35-20-39
		c-11-3-20-12-20-20c0-10-6-12-19-8c-16,5-20,0-23-27c-3-31-5-33-38-31c-28,2-45,14-83,55c-27,28-60,75-74,104l-25,53l23,40
		c19,35,21,46,12,76l-11,35l80,60c43,33,83,60,87,60c5,0,16,15,24,33c17,33,69,85,128,125c56,38,167,102,177,102c11,0,60,54,78,86
		c10,18,12,18,37,1c14-9,33-17,41-17c9,0,20-9,26-20c8-16,6-21-11-31c-15-8-18-14-10-22c19-19,12-27-20-27c-36,0-69-16-69-33
		c0-7,18-20,40-30c37-16,39-19,28-40c-7-12-29-39-48-59c-34-38-34-38-10-39c14,0,33-4,43-8c17-6,17-8-4-35c-15-19-18-31-11-38
		c8-8,30-1,78,23c50,26,73,44,93,76c19,30,33,42,50,43c22,0,23-3,18-37c-5-42,1-68,23-100c28-38,44-33,50,16c5,48,26,69,56,57
		c17-7,17-9,3-19c-9-7-21-22-28-35c-17-32,5-38,73-18c30,9,69,16,86,16c31,0,32-1,28-35c-3-22,0-35,8-35c6,0,17-7,24-15
		c17-21,28-19,32,7c3,19,14,24,93,40c49,9,91,15,94,12c3-2-10-19-27-37c-30-31-41-57-24-57c4,0,25,11,47,25c53,32,61,31,35-5
		c-11-16-25-53-30-82c-4-29-11-61-15-70c-4-10-4-18,0-18c13,0,95,73,95,84c0,7,20,38,45,70c63,82,71,120,22,109c-20-5-21-4-6,12
		c26,26,2,95-39,115c-39,19-66,56-68,92c-2,32,22,45,45,25c9-8,22-12,28-10c7,3,29-1,48-7c30-10,35-16,35-42c0-42,27-83,64-96
		c70-26,108-8,145,66c12,23,25,42,30,42c4,0,13-25,20-56c10-45,22-65,62-105c27-27,49-57,48-67c0-10-3-12-6-5c-2,7-20,15-40,19
		c-19,4-58,19-87,33c-42,22-63,26-116,25c-36-1-67-3-69-3c-7-2,44-37,62-44c22-8,14-23-26-47l-30-19l51-32c55-34,68-55,27-45
		c-19,5-23,3-18-9c3-9-4-26-17-41c-25-26-24-27,31-24c32,1,43-5,84-49c26-28,52-51,56-51c15,0,10,47-6,60c-31,26-13,70,28,70
		c13,0,37-45,37-70c0-9,6-22,13-29c10-10,6-17-16-37c-32-28-28-32,9-8c24,16,26,16,54-16c32-36,36-45,20-55c-5-3-10-10-10-14
		c0-9,61-31,88-31c37,0,24-17-17-24c-34-5-151-63-175-86c-12-12,58-40,98-40c17,0,54,6,81,13c75,20,182,3,115-18c-22-7-81-75-75-86
		c3-4,28-10,55-14c48-7,52-9,75-55c14-27,22-55,20-62c-6-16,36-57,85-84c19-10,39-25,43-31c4-7,23-13,42-13c43,0,135-21,135-31
		c0-4-18-10-40-14s-43-11-46-17c-6-9-51-5-98,9c-16,4-18,1-13-19c5-17-1-35-18-58c-41-55-35-63,38-54c94,12,108,11,121-9
		c9-14,8-20-4-27c-8-5-20-8-27-5c-6,2-30-3-52-12c-34-13-39-18-29-31c7-8,32-23,55-33c24-10,43-21,43-25c0-3-8-19-17-35
		c-23-39-55-36-63,6c-11,51-28,72-92,114c-47,31-64,38-66,27c-4-16,40-97,62-115c7-6,11-22,9-36c-5-24-6-24-89-18c-58,4-84,3-84-5
		c0-6,7-15,16-20c14-8,9-13-25-32c-22-13-41-27-41-31c0-12,69-29,113-29c23,0,36-4,32-10c-3-5-13-10-21-10c-9,0-36-12-61-27
		c-44-26-45-27-22-36c53-20,219,13,219,44c0,14,51,28,72,20c13-4,28-6,35-4c6,2,14-1,18-6c3-6,17-11,30-11c21,0,25-6,31-49
		c9-69,19-82,44-52c17,20,20,38,20,137c0,97-2,113-15,108c-19-8-19,7,0,32c8,10,15,25,15,33c0,18-57,7-82-16c-10-9-21-13-25-9
		c-9,9,25,42,67,65l35,18l3,258c2,229,1,256-13,245c-8-7-15-27-15-44s-7-40-15-50c-18-24-19-58-3-74c6-6,9-15,5-18c-3-3-22,9-41,27
		l-35,34l6,82c4,63,9,80,19,76c34-13,83,71,64,107c-6,11-17,20-25,20c-24,0-17,23,15,50c35,28,37,36,10,43c-11,3-20,13-20,22
		c0,12,5,15,17,11c9-4,20-1,25,6c4,7,8,559,8,1226v1212h-1150c-1012,0-1149-2-1144-15c8-19-6-19-32,0c-15,10-46,14-97,13
		c-68,0-78-3-100-27c-34-36-59-107-52-142c5-22,3-29-8-29c-9,0-18,13-22,32c-13,57-69,128-122,156c-22,12-81,17-71,6c3-3,2-20-3-39
		c-7-24-5-45,6-77c8-23,15-46,15-49c0-4-20-9-45-12c-25-2-55-13-65-22c-28-25-25-8,4,30c14,18,36,64,50,104l25,71h-299
		C15794,10799,15791,10799,15743,10775z M17960,10765c0-17,9-29,30-40c36-19,40-51,8-60c-48-15-118-107-118-155c0-37-26-20-50,33
		c-23,49-24,57-14,117c7,36,12,68,13,73c3,19,22,4,35-28c11-26,21-35,38-35c29,0,51,50,38,85c-9,23-5,35,12,35
		C17956,10790,17960,10779,17960,10765z M18185,10755c-3-43-8-50-24-31c-8,9-8,21-1,40C18174,10801,18187,10796,18185,10755z
		 M18870,10782c0-19-50-54-70-49c-14,4-20,0-20-12c0-10-14-30-31-45c-24-21-33-25-44-16c-16,13-10,33,18,70
		C18748,10763,18870,10806,18870,10782z M16850,10756c0-11-19-15-25-6c-3,5,1,10,9,10C16843,10760,16850,10758,16850,10756z
		 M17360,10748c-1-22-50-72-61-61c-14,14,19,73,42,73C17351,10760,17360,10754,17360,10748z M15958,10741c8-5,12-17,10-27
		c-3-16-12-19-57-17c-61,3-64,4-55,26C15865,10747,15931,10759,15958,10741z M15555,10670c3-5,1-10-4-10c-6,0-11,5-11,10
		c0,6,2,10,4,10C15547,10680,15552,10676,15555,10670z M18133,10566c33-24,34-37,5-70c-22-24-25-25-70-14c-27,6-48,15-48,19
		c0,19,64,98,74,92C18100,10589,18117,10577,18133,10566z M16935,10550c3-5-8-15-25-23c-27-14-30-14-35,4c-4,10-4,23-1,28
		C16880,10569,16928,10562,16935,10550z M17520,10550c0-5-9-15-20-22s-20-18-20-24s-7-17-15-24s-15-23-15-36c0-20-5-24-29-24
		c-47,0-45,15,9,66c27,26,50,53,50,60c0,8,9,14,20,14S17520,10556,17520,10550z M18050,10436c0-16-18-31-27-22c-8,8,5,36,17,36
		C18045,10450,18050,10444,18050,10436z M17141,10365c0-13-41-19-41-7c0,11,20,22,33,18C17138,10375,17141,10370,17141,10365z
		 M16108,10264c16-5,15-7-3-20c-20-15-20-15,4-33s25-38,7-82c-5-12,4-18,41-26c26-6,60-9,76-6c30,6,34-4,12-33c-25-32-18-44,24-44
		c22,0,43-5,46-10c3-6,23-19,43-29c25-13,32-21,22-24c-8-3-33-10-55-16s-51-21-65-32c-23-19-23-22-7-34c20-14,23-35,4-35
		c-6,0-27,4-45,9c-38,11-41,1-17-55c14-35,14-36-5-31c-24,6-30-2-34-50c-3-32-9-40-45-57c-22-12-41-27-41-34s-5-10-11-7
		c-6,4,3,21,24,42c19,19,37,45,39,58c5,26-12,125-22,125c-4,0-13-19-20-42c-7-24-15-50-17-58c-5-14-17,6-44,77c-9,24-85,80-95,70
		c-3-3,5-21,19-39s41-61,62-95c20-35,40-63,45-63c16,0,11-22-7-36c-25-19-33-17-33,7c0,22-56,77-134,133c-62,44-96,78-96,95
		c0,8,9,25,21,37c20,22,21,23,3,53c-30,51-68,65-148,53c-38-6-76-18-85-27c-18-16-41-20-41-7c1,8,92,83,149,121
		c40,28,272,127,341,146c25,7,51,11,58,9C16084,10272,16098,10267,16108,10264z M17513,10254c5-5-34-89-48-102c-5-5-44,39-45,49
		c0,4,14,9,31,13c19,4,33,13,36,26C17492,10260,17502,10265,17513,10254z M17564,10211c-4-16-1-32,5-35c15-10-5-46-24-46
		c-23,0-29,44-11,79C17555,10248,17571,10249,17564,10211z M18478,10189c25-9,30-29,7-29c-20,0-85,32-85,42
		C18400,10210,18435,10204,18478,10189z M17314,10123c8-21-1-29-41-38c-40-8-46,14-10,38C17292,10144,17306,10144,17314,10123z
		 M17517,10123c2-6-4-13-13-17c-21-8-26-3-18,18C17493,10142,17511,10141,17517,10123z M16350,10120c0-5-5-10-11-10c-5,0-7,5-4,10
		c3,6,8,10,11,10C16348,10130,16350,10126,16350,10120z M18460,10115c0-8-7-15-15-15s-15,7-15,15s7,15,15,15
		S18460,10123,18460,10115z M17633,10086c15-14,34-29,43-34c10-5,12-12,7-15c-11-7-93,51-93,65C17590,10117,17607,10110,17633,10086
		z M18620,10072c0-21-67-112-82-112c-6,0-8,6-5,14s11,34,17,58c16,56,17,58,46,58C18613,10090,18620,10084,18620,10072z
		 M16480,10066c0-16-18-31-27-22c-8,8,5,36,17,36C16475,10080,16480,10074,16480,10066z M15470,9989c0-14-44-39-53-30
		c-15,15,10,50,33,44C15461,10000,15470,9994,15470,9989z M17580,9970c0-5-9-10-21-10c-11,0-17,5-14,10c3,6,13,10,21,10
		S17580,9976,17580,9970z M15534,9948c9-35,7-68-4-68c-5,0-10,11-10,25c0,27-18,33-38,13c-23-23-25,3-2,27
		C15509,9976,15526,9977,15534,9948z M17028,9866c-3-21,32-46,66-46c16,0,16-2-5-17c-33-25-67-43-78-43c-5,0-9,24-9,53
		c0,30-4,63-11,75c-19,36-12,47,14,22C17020,9896,17029,9877,17028,9866z M15432,9868c-7-7-12-8-12-2c0,14,12,26,19,19
		C15441,9882,15438,9874,15432,9868z M17185,9839c-8-12-35-12-35,0c0,11,22,20,33,14C17187,9851,17188,9844,17185,9839z M15350,9836
		c0-3-7-12-16-20c-23-23-40-19-19,4C15331,9838,15350,9846,15350,9836z M18505,9800c3-5,4-10,1-10c-2,0-12-3-20-6c-12-5-15-2-10,10
		C18483,9812,18496,9815,18505,9800z M16850,9762c0-13-12-22-22-16s-1,24,13,24C16846,9770,16850,9766,16850,9762z M16540,9740
		c-12-22-18-24-39-11c-19,12-8,26,24,29C16548,9760,16549,9758,16540,9740z M16826,9704c-5-13-10-14-18-6c-6,6-8,18-4,28
		c5,13,10,14,18,6C16828,9726,16830,9714,16826,9704z M16650,9720c0-5-2-10-4-10c-3,0-8,5-11,10c-3,6-1,10,4,10
		C16645,9730,16650,9726,16650,9720z M18680,9695c7-8,9-22,6-31c-6-15-8-15-26,1c-11,10-20,24-20,32
		C18640,9715,18665,9714,18680,9695z M15190,9675c0-19-21-65-31-65c-4,0-16-16-28-35c-11-19-27-35-36-35c-8,0-15-6-15-14
		c0-15-40-49-48-41c-3,2,4,17,14,32s19,34,20,41c5,31,15,42,37,42c33,0,68,39,60,68c-4,16-1,22,11,22
		C15183,9690,15190,9683,15190,9675z M17910,9681c0-5,13-14,30-21c16-7,35-19,42-28c12-13,7-14-37-8c-59,9-69,17-60,45
		C17891,9690,17910,9699,17910,9681z M17064,9631c-11-29-24-43-40-46c-13-3-35-7-49-10c-28-6-29-5-14,27c9,19,108,82,117,73
		C17080,9674,17073,9654,17064,9631z M16334,9657c18-13,18-14,1-21c-10-3-24-1-31,5C16272,9667,16301,9681,16334,9657z M16689,9623
		c-1-10-1-30,0-45c1-25,4-27,38-25c26,1,38-2,40-13c3-12-7-15-44-14c-70,1-133,15-133,28c0,7,5,17,12,24c6,6,8,15,4,19s-12,0-17-10
		c-6-9-20-27-32-38c-17-15-18-19-4-14c13,5,17,2,17-14c0-11,11-28,25-37c38-25,23-28-20-4c-20,11-45,18-55,15s-28,3-41,15l-24,20
		l23,6c13,4,22,14,22,25c0,27,28,35,44,14c12-16,13-15,19,14c5,26,11,31,34,32c16,0,37,4,48,9C16677,9644,16691,9641,16689,9623z
		 M18890,9630c0-5-4-10-10-10c-5,0-10,5-10,10c0,6,5,10,10,10C18886,9640,18890,9636,18890,9630z M17750,9599c-12-22-37-35-48-25
		c-7,7,36,45,51,46C17757,9620,17756,9611,17750,9599z M17887,9600c3-11,14-20,24-20c27,0,24-18-6-32l-24-11l24-18
		c13-11,29-19,36-19c10,0,10-2,0-12c-9-9-15-9-17-3c-3,6-20,9-39,7c-46-5-61,8-63,57c-2,31,2,43,20,55
		C17872,9625,17881,9624,17887,9600z M18030,9610c0-5-4-10-9-10c-6,0-13,5-16,10c-3,6,1,10,9,10C18023,9620,18030,9616,18030,9610z
		 M17224,9583c9-22-7-36-33-28c-17,5-19,10-11,26C17193,9604,17216,9606,17224,9583z M18940,9575c-13-16-40-21-40-7
		c0,10,42,32,49,26C18951,9592,18947,9584,18940,9575z M16090,9580c0-5-4-10-10-10c-5,0-10,5-10,10c0,6,5,10,10,10
		C16086,9590,16090,9586,16090,9580z M18658,9573c2-8-5-13-17-13s-21,6-21,16C18620,9594,18651,9591,18658,9573z M16255,9570
		c3-5,1-10-4-10c-6,0-11,5-11,10c0,6,2,10,4,10C16247,9580,16252,9576,16255,9570z M18850,9570c0-5-5-10-11-10c-5,0-7,5-4,10
		c3,6,8,10,11,10C18848,9580,18850,9576,18850,9570z M16901,9491c-8-14-8-22,0-27c6-3,9-14,6-24c-3-11,2-21,10-24c19-8,7-26-18-26
		c-12,0-19,7-19,19c0,11-9,22-20,26c-17,5-18,9-7,28c13,23,50,57,55,52C16910,9513,16907,9503,16901,9491z M15110,9487
		c0-13-14-45-31-72c-58-92-66-139-31-192c13-21,12-23-26-38c-22-9-42-25-45-36c-4-13-14-19-33-19h-28l28,29c20,21,34,53,48,108
		c11,43,23,92,27,108c15,58,62,135,83,135C15106,9510,15110,9500,15110,9487z M16735,9496c18-13,18-14-2-20c-12-4-24-14-27-22
		c-7-19-27-9-21,11c3,8,7,22,10,30C16701,9514,16711,9514,16735,9496z M17182,9491c18-11,52-25,76-32c48-14,51-21,19-42
		c-22-14-28-14-78,11c-40,20-68,27-106,26c-29-1-53,2-53,5c0,8,78,48,97,50C17143,9509,17164,9501,17182,9491z M16390,9470
		c8-16,8-20-3-20c-18,0-39,24-30,33C16368,9495,16379,9491,16390,9470z M14937,9464c2-5-19-20-47-33c-27-12-50-26-50-29
		c0-4-12-20-26-35c-35-38-55-21-25,21c11,17,21,35,21,42c0,6,5,20,11,31c8,17,19,19,61,17C14910,9476,14935,9470,14937,9464z
		 M16784,9441c3-5-1-15-10-22c-13-10-17-10-25,2c-5,8-9,17-9,22C16740,9453,16778,9452,16784,9441z M18630,9443c0-3-7-15-17-25
		c-9-11-11-18-5-18c7,0,12-10,12-21c0-20,2-20,23-7c45,29,56,29,72-2c18-35,19-51,3-49c-37,4-41-1-23-28c9-16,30-40,46-54l30-26
		l-41-6c-23-3-44-8-47-10c-2-3,5-23,17-46l20-41h-21c-28,0-170-70-163-81c10-17,121-41,167-36c40,3,47,2,45-12c-2-9-15-22-30-29
		s-37-26-48-42c-12-17-26-30-32-30s-28,18-50,40s-45,40-51,40s-33,21-59,47c-46,45-49,47-54,25c-5-15-14-22-32-22c-16,0-38-14-62-40
		c-20-22-42-40-48-40c-26,0-21,22,12,50c19,17,51,53,72,80c36,47,40,50,72,44l35-7l-5,59c-3,33-8,67-12,76c-6,12,1,21,24,33
		c27,14,40,15,77,6c47-12,113-6,113,11s-84,96-113,107c-15,6-27,14-27,18C18531,9426,18630,9462,18630,9443z M18754,9431
		c10-16-5-41-25-41c-21,0-25,20-7,38C18736,9442,18747,9443,18754,9431z M16714,9404c9-8,16-25,16-36c0-17-4-19-27-13
		c-34,8-32,5-26,38C16683,9424,16692,9427,16714,9404z M14980,9375c-7-9-15-13-18-10c-3,2,1,11,8,20s15,13,18,10
		C14991,9393,14987,9384,14980,9375z M14743,9370c3-13,0-20-9-20c-8,0-14,9-14,20s4,20,9,20S14740,9381,14743,9370z M16557,9365
		c8-9,13-19,10-22c-10-10-37,6-37,22C16530,9384,16537,9384,16557,9365z M16860,9335c0-23-16-42-27-32c-3,3-2,19,2,36
		C16843,9374,16860,9371,16860,9335z M17124,9360c23,0,50-6,60-13c16-12,15-17-13-81c-17-37-31-73-31-80c0-25-119-116-152-116
		c-10,0-18,7-18,15c0,9-20,24-45,35s-45,26-45,34c0,9-9,20-20,26c-16,9-20,21-20,61c0,43,3,50,18,46c9-3,34-8,55-12c35-6,38-4,33,13
		c-5,15-1,21,23,25c16,3,42,17,57,31c16,14,35,23,42,21C17076,9362,17101,9360,17124,9360z M17635,9350c-3-5-10-10-16-10
		c-5,0-9,5-9,10c0,6,7,10,16,10C17634,9360,17638,9356,17635,9350z M18930,9320c0-6,11-8,25-4c23,6,25,3,25-25c0-17-2-31-4-31
		c-6,0-66,60-66,66c0,2,5,4,10,4C18926,9330,18930,9325,18930,9320z M16710,9310c0-3-5-12-11-20c-7-11-12-12-15-3c-7,16-34,5-34-13
		s-9-18-32,3c-33,30-22,42,37,40C16685,9316,16710,9313,16710,9310z M18120,9305c0-1-13-18-28-38c-21-26-32-33-44-26
		c-14,8-12,14,14,43c17,20,36,32,44,29S18120,9307,18120,9305z M14900,9269c0-16-4-19-22-13c-29,8-31,10-23,23
		C14867,9299,14900,9292,14900,9269z M17245,9251c7-12-22-41-41-41c-17,0-17,5-4,31C17211,9261,17235,9266,17245,9251z M16666,9241
		c10,8,17,7,29-4c13-14,13-19-1-52c-28-68,3-87,56-34c32,32,43,31,54-7c5-18,1-23-21-28c-106-26-132-38-135-59c-4-32-52-31-56,1
		c-5,32,16,55,44,48c21-6,22-4,12,26c-5,18-10,38-9,43c1,50,5,78,9,67C16651,9232,16655,9232,16666,9241z M17745,9239
		c10-15-1-23-20-15c-9,3-13,10-10,16C17723,9253,17737,9253,17745,9239z M16480,9220c0-11-7-20-15-20c-18,0-19,12-3,28
		S16480,9243,16480,9220z M18315,9213c-4-16-8-30-10-31c-8-8-25,10-25,27c0,21,9,31,28,31C18318,9240,18320,9233,18315,9213z
		 M14742,9188c-15-15-26-4-18,18c5,13,9,15,18,6S14751,9197,14742,9188z M14734,9161c7-10-32-47-40-38c-7,7,14,47,26,47
		C14725,9170,14731,9166,14734,9161z M18312,9128c3-18,8-41,12-50c6-13,2-18-21-23c-15-3-41-10-58-16c-49-16-50-16-50,26
		c0,36,4,42,36,57c19,9,41,23,48,32C18297,9175,18307,9167,18312,9128z M18910,9166c0-11-19-15-25-6c-3,5,1,10,9,10
		C18903,9170,18910,9168,18910,9166z M14764,9121c-10-2-25-8-31-13c-19-15-16-2,4,22c14,15,20,17,31,7
		C14781,9127,14780,9125,14764,9121z M17620,9095c9-10,10-19,4-25c-13-13-49,13-40,28C17594,9114,17605,9113,17620,9095z
		 M18939,9061c16-10,25-157,11-166c-4-3-36-11-71-19c-35-7-73-17-84-21c-16-7-15-3,8,22c15,16,27,33,27,39c0,5,16,27,36,48
		c31,33,35,44,31,73c-5,26-2,33,11,33C18917,9070,18931,9066,18939,9061z M14870,9050c0-5-4-10-9-10s-11-9-13-20c-2-12-7-20-13-20
		c-9,1-9,19,0,48C14840,9062,14870,9064,14870,9050z M14640,9040c0-12-42-31-50-23c-6,5,30,33,42,33
		C14636,9050,14640,9045,14640,9040z M14990,9040c0-5-7-10-16-10c-8,0-12,5-9,10c3,6,10,10,16,10C14986,9050,14990,9046,14990,9040z
		 M16877,8993c-21-22-32-11-17,17c9,16,15,19,23,11S16890,9005,16877,8993z M16415,9010c3-5-1-10-9-10c-9,0-16,5-16,10
		c0,6,4,10,9,10C16405,9020,16412,9016,16415,9010z M17795,9010c3-5-1-10-9-10c-9,0-16,5-16,10c0,6,4,10,9,10
		C17785,9020,17792,9016,17795,9010z M17795,8970c3-5-1-10-9-10c-9,0-16,5-16,10c0,6,4,10,9,10C17785,8980,17792,8976,17795,8970z
		 M14890,8960c0-5-7-10-16-10c-8,0-12,5-9,10c3,6,10,10,16,10C14886,8970,14890,8966,14890,8960z M16670,8940c0-10,11-32,25-48
		c29-35,31-49,8-74c-19-21-51-23-76-4c-18,14-18,17-1,73c9,32,18,64,20,71C16651,8977,16670,8963,16670,8940z M17745,8920
		c-3-5-13-10-21-10s-14,5-14,10c0,6,9,10,21,10C17742,8930,17748,8926,17745,8920z M17562,8898c-7-7-12-8-12-2c0,14,12,26,19,19
		C17571,8912,17568,8904,17562,8898z M19126,8724c18-69,18-70-15-35c-16,17-38,31-49,31c-29,0-46,24-27,40c8,7,21,10,30,7
		c8-4,17-2,21,4C19098,8791,19113,8773,19126,8724z M18610,8736c0-11-19-15-25-6c-3,5,1,10,9,10C18603,8740,18610,8738,18610,8736z
		 M19006,8721c18-24,5-34-22-16c-21,14-24,14-24,0c0-17-45-21-55-5c-14,22,4,35,46,36C18981,8737,18998,8732,19006,8721z
		 M18828,8718c-3-7-15-15-28-16c-21-3-22-2-11,12C18805,8733,18833,8735,18828,8718z M16365,8624c9,3,19-3,25-14c8-15,6-21-9-30
		c-30-15-49-12-61,11c-16,29-5,56,15,39C16343,8623,16356,8620,16365,8624z M18555,8600c-3-5-10-10-16-10c-5,0-9,5-9,10
		c0,6,7,10,16,10C18554,8610,18558,8606,18555,8600z M16320,8555c0-15-29-73-40-80c-4-2-13,2-20,10s-23,15-36,15c-33,0-37,14-9,38
		c14,12,25,27,25,32c0,6,10,15,23,19C16286,8598,16320,8578,16320,8555z M18747,8563c9-16,25-37,34-48c27-31,10-49-37-41
		c-63,11-67,16-52,69C18706,8597,18722,8602,18747,8563z M19100,8395c0-8-9-19-21-25c-16-9-20-19-17-52c4-48-9-57-43-29
		c-24,19-24,19-4,41c19,21,19,23,2,35c-26,19,1,36,71,44C19094,8409,19100,8403,19100,8395z M16906,8326c7-29-1-46-22-46
		c-16,0-19,26-4,35c5,3,7,12,3,20c-3,8,0,15,6,15S16902,8339,16906,8326z M16250,8240c-20-13-50-13-50,0c0,6,15,10,33,10
		C16257,8250,16261,8247,16250,8240z M18961,8051c-6-11-11-33-11-50c0-30-2-31-41-31c-40,0-41,1-27,23c8,12,20,41,28,65
		c7,24,23,50,34,58c20,14,21,13,24-15C18970,8084,18966,8062,18961,8051z M16897,8073c2-6-8-24-22-39l-25-29l6,30c3,17,8,36,10,43
		C16871,8093,16892,8090,16897,8073z"/>
	<path d="M13647,9797c-2-7,1-21,9-32c21-28,18-55-7-55c-19,0-20-4-16-52c2-29,9-70,15-90c11-41,61-108,77-104c13,4,6,85-11,120
		c-8,16-14,37-14,48c0,10-4,18-10,18c-5,0-10,5-10,10c0,6,20-3,44-20c75-51,103-31,46,33c-36,41-37,51-8,64c24,10,27,7,98-103
		c14-22,36-37,70-48c62-20,60-21,60,10c0,34-53,110-90,129c-17,9-53,20-82,25c-43,8-54,7-69-6c-23-20-69-11-69,14
		C13679,9783,13654,9814,13647,9797z"/>
	<path d="M13582,9728c-35-68-53-158-33-158c15,0,54,87,45,101c-3,6,3,23,14,39c15,20,19,35,14,54C13615,9790,13613,9788,13582,9728z
		"/>
	<path d="M15495,9581c-44-7-65-17-65-31c0-15,85-50,121-50c45,0,130,23,132,35c0,6-17,20-39,32C15605,9590,15569,9593,15495,9581z"
		/>
	<path d="M15701,9514c0-11,3-14,6-6c3,7,2,16-1,19C15703,9531,15700,9525,15701,9514z"/>
	<path d="M15808,9431c-10-10-18-27-18-38s-7-27-17-34c-33-28-54-113-22-94c15,10,60,92,65,121c3,15,9,35,15,46
		C15843,9455,15831,9454,15808,9431z"/>
	<path d="M14945,8940c-6-9,14-117,35-193c15-51,33-42,43,19c15,89,10,115-25,151C14964,8952,14955,8956,14945,8940z"/>
	<path d="M18097,8486c-9-7-15-20-12-28c4-9-4-19-20-26c-14-6-25-16-25-22c0-11,26-12,180-10c145,2,244,34,158,52c-18,3-58,14-88,24
		C18216,8499,18120,8504,18097,8486z"/>
	<path d="M18435,8420c-3-5-1-10,4-10c6,0,11,5,11,10c0,6-2,10-4,10C18443,8430,18438,8426,18435,8420z"/>
	<path d="M16315,8396c-22-16-15-29,23-41c21-6,23-4,19,21C16353,8410,16341,8415,16315,8396z"/>
	<path d="M18315,8373c-47-6-157-34-192-48c-17-7-36-23-43-34c-10-20-8-21,43-21c29,0,74,7,100,16c69,23,77,14,29-38
		c-23-25-42-52-42-61s-4-19-10-22c-15-9-12-35,4-35c20,0,162,147,177,182c10,25,9,31-9,48c-12,11-22,19-24,19
		C18346,8378,18332,8375,18315,8373z"/>
	<path d="M16333,8253c9-14,47-24,47-12c0,5-4,9-9,9s-17,3-27,7C16333,8261,16329,8260,16333,8253z"/>
	<path d="M16430,8217c0-26,34-111,38-97c2,8,11,30,20,49c15,31,14,35-3,47C16461,8234,16430,8234,16430,8217z"/>
	<path d="M16330,8175c-5-14-10-34-10-43c-1-9-9-35-20-57s-19-48-20-58c0-17,1-17,25,0c25,18,51,76,41,91c-3,5,4,19,16,32
		c16,17,19,27,11,38C16357,8204,16341,8203,16330,8175z"/>
	<path d="M16213,8179c-6-6-9-17-7-24c6-14-17-61-55-113c-13-18-21-35-18-38s23,4,46,16c28,14,41,27,41,41c0,13,6,19,18,17
		c13-2,18,5,20,32c2,19,10,40,19,47c8,6,12,16,8,22C16277,8193,16227,8193,16213,8179z"/>
	<path d="M19007,7293c-4-3-7-13-7-22c1-13,3-13,11,2C19022,7292,19019,7306,19007,7293z"/>
        </g>
        </svg>
    )
}

export default Splash0Right;