 function Items() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1920.000000 1080.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,1082.000000) scale(0.100000,-0.100000)"
            stroke="none">
<path d="M3519 7203 c-17 -4 -37 -80 -59 -223 -6 -39 -10 -338 -10 -747 l0
-683 1355 0 1355 0 5 583 c3 320 6 632 6 692 1 96 -22 321 -37 359 -3 7 -26
16 -52 20 -50 7 -2536 6 -2563 -1z m2574 -167 c14 -27 21 -59 21 -105 l1 -66
-318 -3 c-213 -1 -316 1 -312 8 4 6 -8 10 -30 10 -22 0 -34 -4 -30 -10 4 -6
-36 -10 -112 -10 l-119 0 1 28 0 27 -375 1 c-222 1 -382 -2 -393 -8 -12 -6
-16 -16 -12 -29 6 -18 1 -19 -99 -19 l-106 0 0 30 0 30 -76 0 -76 0 4 -30 5
-30 -89 0 -88 0 -6 30 -6 30 -74 0 -74 0 0 -30 c0 -29 -1 -30 -52 -31 -54 -2
-75 -2 -139 0 l-35 1 13 78 c15 89 22 112 35 130 8 9 269 12 1266 10 l1255 -3
20 -39z"/>
            </g>
        </svg>
    )
}

export default Items;