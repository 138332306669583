 function ProjectsWindowButtons() {
    return (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="450.000000pt" height="450.000000pt" viewBox="0 0 5000.000000 5000.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(500.000000,5000.000000) scale(0.100000,-0.100000)"
fill="#446e71" stroke="none">
<path d="M9815 36059 c-251 -39 -457 -186 -566 -404 -61 -124 -82 -217 -81
-365 0 -148 20 -240 82 -369 123 -256 342 -433 615 -497 101 -23 269 -21 368
5 290 76 500 304 553 601 79 442 -217 897 -657 1009 -92 24 -234 33 -314 20z"/>
<path d="M12173 35880 c-394 -42 -676 -392 -650 -808 23 -365 270 -680 622
-792 96 -30 281 -38 385 -16 90 19 236 89 305 147 115 95 203 233 247 385 19
66 22 102 22 219 -1 122 -4 151 -27 228 -48 158 -126 287 -238 398 -178 175
-425 264 -666 239z"/>
<path d="M14390 35695 c-286 -58 -500 -275 -561 -570 -99 -480 237 -960 719
-1025 140 -19 296 10 427 78 93 48 228 181 280 275 134 242 134 541 -1 795
-175 332 -528 515 -864 447z"/>
</g>
</svg>
    )
}

export default ProjectsWindowButtons;