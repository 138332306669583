 function Splash4() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1920.000000 1080.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,1065.000000) scale(0.100000,-0.100000)"
            fill="#307379" stroke="none">
            	<path d="M19161,1496l-39,89l-61.4,30.8c-163.8,6.8-294.9,111.7-426.4,213.8c-65,32.7-127.9,64.6-185.1,93.8
		c-104.7,41.6-205.8,95.2-308,146.5c-105.8,53.2-215.5,95.4-317.2,156.6c-32.1,19.3-64.5,39.8-97.6,59.1
		c-14.8,6.4-28.6,12.2-41.3,17.4c-150,61-327,114-465,138c-141.5,25-259.2,46.5-351.3,64.1c-143.6,0.5-286.8,8.6-426.5,41.6
		c-111.7,26.4-213.7,80.5-321.9,117.8c-223.3,37.6-467.9,68-730.3,90.4c-301.8,25.4-341.6,29.4-553.4,63.4
		c-99.6-6-198.8-2-296.6,23.8c-63.3,16.7-119.2,43.6-172.9,75.4c-30.9,9.5-57.9,19-80.1,28.3c-266,112-323,129-486,144
		c-277,26-444,95-771,320c-136,93-185,146-255,280c-34,63-67,116-75,117c-8,1-28-5-45-13c-16-9-82-44-145-79c-112-62-118-64-225-76
		c-89-9-124-18-185-47c-41-19-88-45-105-58c-54-41-298-185-432-255c-122-64-173-83-158-59c3,6-1,10-10,10c-11,0-15,7-13,23
		c2,12-4,27-12,34c-11,9-12,12-2,13c8,0,10,5,6,12c-4,7-9,24-10,37c-2,14-8,27-14,29c-5,2-10,12-10,23c0,10-4,19-10,19
		c-10,0-11-4-14-39c0-11-4-22-8-26c-4-4-4-13-1-21c3-8,0-14-7-14c-6,0-9-4-6-9s-1-14-9-21c-8-7-13-16-10-20c3-5-2-16-11-24
		c-14-15-14-16,0-16c22,0,20-17-1-23c-17-4-17-5,0-6c21-1,23-21,2-21c-8,0-15-9-15-19s-7-22-16-25c-24-9-31-7-14,4c13,8,13,10-2,10
		c-14,0-17,7-14,30c2,17-1,30-6,30c-6,0-8,6-5,14c3,8,1,17-6,20c-9,3-8,8,3,15c12,8,12,11,1,11c-7,0-11,6-7,15c3,9,0,15-9,15
		c-11,0-15,12-15,47c0,54-6,54-14-2c-4-21-12-43-19-47c-9-6-8-8,3-8c12,0,13-2,1-9c-7-5-11-13-7-19c3-6,2-13-4-17c-6-4-8-10-5-15
		c3-5-3-13-12-19c-10-6-15-11-11-11c4,0,3-11-2-25c-8-21-6-25,8-25c16-1,16-2-1-14c-18-14-18-14,0-19c16-4,15-6-4-12
		c-13-4-20-11-17-16c3-5-3-9-14-9c-14,0-19,7-18,23c1,12-5,27-14,34c-12,10-12,12-1,13c10,0,11,3,2,13c-6,8-8,17-5,20
		c4,4-1,18-10,32c-9,14-16,31-15,38c1,6,0,23-1,37c-2,24-2,24-12-5c-5-16-10-40-12-52c-1-12-5-25-8-28c-4-4-4-13-1-21c3-8,0-14-7-14
		c-6,0-9-4-6-9s-1-14-9-21c-8-7-13-16-10-20c3-5-2-16-11-24c-14-15-14-16,0-16c19,0,21-16,4-23c-10-4-10-6,0-6c18-1,15-21-3-21
		c-9,0-15-6-14-12c4-31-7-38-103-68c-57-18-195-63-308-101c-113-37-236-74-275-80c-40-6.7-136.6-36.4-243.7-72.8
		c-193.5-122-397.1-221-624.9-235c-16.3-4.9-30.3-8.8-40.5-11.2c-41-9-65-25-142-95c-50-47-95-85-98-85s-6,9-6,20s5,20,10,20
		c6,0,10,11,10,24c0,19-4,23-20,19c-11-3-17-9-14-14s0-9-5-9c-6,0-14-1-18-2c-10-4-11,42-1,48c7,5,1,72-7,82c-10,11-48-45-47-69
		c1-29-10-35-59-31c-13,1-15-4-11-20c4-16,2-19-9-14c-8,4-5,0,6-9c14-12,16-19,8-22c-20-7-15-30,8-36c31-8,14-25-18-19
		c-25,5-27,4-14-12c7-9,19-16,25-15c31,4,44-3,48-26c5-23,3-24-26-19c-17,3-34,10-39,17c-7,9-10,9-14,0c-9-24-41-14-49,15
		c-4,15-7,30-6,35c1,4-5,7-13,7s-15-5-15-11c0-6-7-9-14-6c-10,4-13,1-9-10c3-8,0-19-8-24c-10-6-10-9-1-9c14,0,17-29,2-31
		c-5-1-14-2-19-3c-5,0-13-4-17-8c-4-4-12-4-18,1c-13,11-15-27-3-46c4-7,3-14-3-18c-15-9-12-35,4-35c8,0,18-5,22-11c5-8-3-10-25-7
		c-36,5-42-9-8-21c21-9,21-9,2-16c-11-5-35-19-53-33c-43-31-683-332-707-332c-3,0-29-11-58-25c-41.6-18.8-80.9-35.3-121.5-50.5
		c-5.6-2.3-11.3-4.7-16.9-7c-125.2-49.9-256-81.3-389.1-97.7c-92.8-17.8-203.2-37.1-339.4-59.9c-102-17-230-40-285-51
		c-59.7-12.7-102.9-18.1-306.6-22.2c-77-22.2-157.5-35.4-243.1-36.3c-24.6-0.2-39.8,14.2-45.6,32.1c-73.9-0.8-158.2-1.7-254.8-2.7
		c-53-0.6-105.1-1.3-155.9-2.1c-198.3-30.1-401.6-36.4-597.6-36.7c-19.6,0-33.2,9.2-40.8,22.1c-7.4-0.5-12.8-0.9-15.6-1.3
		c-33-6-134-20-225-31c-198.6-25.9-241.3-32.7-496-81.8c-27-17.7-54.4-34.6-83.7-48.4c-127.5-60.4-283.3-59.4-421.7-58.2
		c-36,0.3-72,1.2-107.9,2.5c-214.8-26.4-410.2-35-679.7-35c-141.1,0-271.1-6-405.3-19.6c-175.7-69.1-368.2-114.5-555.5-91.4
		c-55.2-12.1-139.7-31.3-190.3-44c-52-13-133-29-180-35c-111-14-144-26-172-58c-13-15-68-56-123-91c-55-36-170-111-255-167
		c-173-114-204-131-328-179C700,286,625,244,310,36L255,0h18945v1408L19161,1496z M8759,2036c-5,3-7,10-4,15c8,12,32,12,39,0
		C8801,2040,8772,2028,8759,2036z M8833,2120c-7,0-13,9-13,20c0,23,13,26,29,7C8861,2132,8854,2120,8833,2120z M8920,2114
		c-15-17-30-18-30-1c0,7,9,13,21,14C8928,2129,8929,2126,8920,2114z M8960,2256c-12,4-12,7-1,13c14,9,51,3,51-8
		C9010,2252,8981,2250,8960,2256z"/>
	<path d="M11331,3444c0-11,3-14,6-6c3,7,2,16-1,19C11333,3461,11330,3455,11331,3444z"/>
	<path d="M8790,2270c0-5,5-10,10-10c6,0,10,5,10,10c0,6-4,10-10,10C8795,2280,8790,2276,8790,2270z"/>
            </g>
        </svg>
    )
}

export default Splash4;