function Cart() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1920.000000 540.000000"
            preserveAspectRatio="xMidYMid meet"
            className='cart'
            >

            <g transform="translate(0.000000,540.000000) scale(0.100000,-0.100000)"
            fill="#1c1c1c" stroke="none">
<path d="M7235 2023 c-11 -2 -30 -6 -41 -8 -12 -3 -55 -37 -95 -77 -72 -71
-111 -96 -173 -114 -32 -10 -34 -14 -68 -221 l-12 -73 -113 0 -113 0 0 -165 0
-165 43 0 42 0 65 -238 66 -238 -25 -47 c-110 -214 114 -447 326 -338 73 37
116 98 137 194 l6 27 325 0 325 0 6 -27 c15 -68 29 -98 62 -135 99 -109 256
-115 357 -13 72 72 92 168 55 268 l-20 54 50 194 c86 326 75 299 115 299 l34
0 3 153 c2 83 1 158 -1 165 -2 9 -38 12 -132 12 -125 0 -129 1 -119 19 14 28
12 82 -5 131 -8 23 -15 57 -15 74 0 46 -17 99 -40 124 -11 12 -46 29 -78 38
-31 8 -70 22 -87 29 -44 20 -148 45 -184 45 -43 0 -94 -28 -186 -100 -67 -54
-79 -60 -93 -48 -42 33 -83 48 -134 48 -86 0 -123 15 -165 68 -58 74 -64 77
-118 65z"/>
            </g>
            </svg>
    )
}

export default Cart;