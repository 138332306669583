function PipesPipes() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1920.000000 200.000000"
            preserveAspectRatio="xMidYMid meet"
            className='pipes-pipes'
            >

            <g transform="translate(10.000000,200.000000) scale(0.100000,-0.100000)"
            fill="#0f2d30" stroke="none">
<path d="M106 1919 c-11 -55 -7 -89 9 -89 8 0 15 5 15 10 0 7 265 10 775 10
510 0 775 -3 775 -10 0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 70 10 190 10 218
0 198 11 202 -110 3 -59 5 -65 28 -71 14 -4 109 -7 213 -8 159 -1 187 -3 187
-16 0 -17 21 -20 36 -5 14 14 105 24 138 16 l26 -6 0 -115 c0 -94 -3 -116 -16
-123 -14 -8 -14 -10 -1 -24 11 -11 16 -45 18 -134 l4 -119 -325 -3 c-284 -2
-326 -4 -342 -19 -16 -14 -18 -33 -18 -149 0 -78 -4 -135 -10 -139 -5 -3 -10
-13 -10 -21 0 -10 14 -14 54 -14 58 0 79 13 52 33 -14 10 -16 30 -14 122 l3
110 329 3 c299 2 330 4 342 20 10 12 13 56 14 150 0 88 4 139 13 153 10 17 9
22 -5 30 -15 9 -18 25 -18 112 1 130 5 137 94 137 50 0 68 -4 72 -15 7 -17 44
-21 44 -5 0 7 262 10 765 10 688 0 765 -2 765 -16 0 -11 6 -14 23 -8 12 4 25
11 28 16 3 4 100 7 215 6 l209 -3 3 -105 c2 -58 0 -120 -3 -137 l-6 -33 -93 0
c-74 0 -95 3 -100 15 -3 8 -12 15 -21 15 -8 0 -15 -7 -15 -15 0 -12 -15 -15
-78 -15 -116 0 -112 8 -112 -242 0 -223 -1 -218 56 -218 l24 0 0 195 0 195 51
0 c37 0 56 -5 65 -17 13 -17 15 -17 28 0 11 14 31 17 134 17 108 0 122 2 135
19 10 16 12 58 10 192 -2 94 -8 180 -14 190 -9 18 -26 19 -249 19 -214 0 -240
2 -240 16 0 12 -5 14 -17 8 -10 -5 -364 -10 -787 -12 -613 -2 -771 0 -777 10
-7 10 -12 11 -29 1 -14 -9 -76 -13 -214 -13 -175 0 -196 2 -205 18 -10 16 -12
16 -25 0 -12 -16 -33 -18 -190 -18 l-176 0 0 80 c0 123 6 120 -248 120 -148 0
-211 3 -220 12 -9 9 -15 9 -24 0 -16 -16 -1558 -18 -1558 -2 0 23 -18 7 -24
-21z"/>
<path d="M16494 1835 c-5 -13 -101 -15 -774 -15 -502 0 -771 3 -775 10 -10 15
-35 12 -35 -5 0 -12 -15 -15 -78 -15 -119 0 -112 23 -112 -352 0 -174 3 -329
6 -346 8 -39 38 -52 124 -52 44 0 70 -4 70 -11 0 -6 5 -9 11 -7 6 2 12 29 15
61 3 45 1 57 -11 57 -7 0 -17 -4 -20 -10 -3 -5 -31 -10 -61 -10 l-54 0 0 300
0 300 51 0 c37 0 56 -5 65 -17 13 -17 15 -17 28 0 12 16 62 17 780 17 504 0
766 -3 766 -10 0 -16 27 -12 34 5 4 13 35 15 186 15 l180 0 0 -80 c0 -123 -6
-120 248 -120 148 0 211 -3 220 -12 9 -9 15 -9 24 0 18 18 1554 17 1561 0 8
-24 18 0 24 56 5 48 3 56 -11 56 -9 0 -16 -4 -16 -10 0 -7 -265 -10 -775 -10
-510 0 -775 3 -775 10 0 6 -9 10 -20 10 -11 0 -20 -4 -20 -10 0 -6 -70 -10
-190 -10 -218 0 -198 -11 -202 110 -3 59 -5 65 -28 71 -14 4 -112 7 -217 8
-165 1 -193 3 -193 16 0 8 -5 15 -10 15 -6 0 -13 -7 -16 -15z"/>
<path d="M13281 1816 c-12 -14 -91 -16 -777 -16 -503 0 -764 3 -764 10 0 6 -9
10 -19 10 -11 0 -23 -4 -26 -10 -4 -6 -83 -10 -206 -10 -244 0 -239 3 -239
-120 l0 -80 -180 0 c-160 0 -180 2 -189 18 -8 14 -13 15 -30 5 -29 -18 -402
-18 -419 -1 -10 10 -17 10 -32 0 -25 -16 -1560 -18 -1560 -2 0 6 -9 10 -19 10
-11 0 -23 -4 -26 -10 -4 -7 -275 -10 -781 -10 -509 0 -774 3 -774 10 0 6 -7
10 -15 10 -8 0 -15 -4 -15 -10 0 -6 -64 -10 -173 -10 -156 0 -176 -2 -190 -18
-14 -16 -17 -44 -17 -182 0 -135 -3 -166 -16 -181 -15 -16 -12 -18 41 -24 39
-5 60 -4 68 4 9 9 8 15 -5 22 -16 9 -18 27 -18 153 l0 143 34 6 c37 6 247 -8
284 -19 13 -4 22 -2 22 5 0 8 211 11 775 11 510 0 775 -3 775 -10 0 -5 11 -10
25 -10 14 0 25 5 25 10 0 7 265 10 775 10 510 0 775 -3 775 -10 0 -13 33 -13
46 0 14 14 105 24 138 16 l26 -6 0 -116 c0 -90 -3 -116 -14 -120 -33 -13 -3
-31 54 -33 47 -2 60 1 60 12 0 8 -7 17 -15 21 -12 4 -15 26 -15 108 1 131 6
138 91 138 54 0 68 -4 83 -22 17 -20 20 -20 31 -5 11 15 37 17 201 18 104 1
200 4 214 8 23 6 25 12 28 71 4 121 -14 110 192 110 113 0 180 -4 180 -10 0
-5 11 -10 25 -10 14 0 25 5 25 10 0 7 262 10 765 10 503 0 765 -3 765 -10 0
-16 37 -12 44 5 4 13 35 15 186 15 l180 0 0 -80 c0 -123 -7 -120 250 -120
l210 0 6 -26 7 -26 16 26 16 27 145 -3 145 -3 3 -144 c2 -128 1 -145 -15 -157
-30 -21 -4 -34 55 -27 50 6 63 15 42 28 -6 4 -10 73 -10 175 0 140 -3 171 -16
184 -13 13 -44 16 -184 16 -140 0 -169 2 -174 15 -6 17 -36 21 -36 5 0 -6 -70
-10 -190 -10 -218 0 -198 -11 -202 110 -3 59 -5 65 -28 71 -14 4 -110 7 -214
8 -160 1 -191 3 -200 17 -11 14 -13 14 -25 0z"/>
<path d="M1235 1791 c-4 -8 -196 -11 -621 -11 l-614 0 0 -40 0 -40 614 0 c482
0 615 -3 618 -12 4 -10 13 -8 39 5 30 16 57 18 239 15 l205 -3 3 -105 c2 -58
0 -120 -3 -137 l-6 -33 -95 0 c-75 0 -96 3 -100 15 -7 17 -34 21 -34 5 0 -7
-253 -10 -740 -10 l-740 0 0 -40 0 -40 739 0 c675 0 739 -1 745 -16 6 -16 8
-16 24 0 15 13 42 16 140 16 108 0 122 2 135 19 10 16 12 58 10 192 -2 94 -8
180 -14 190 -9 17 -29 19 -257 21 -136 2 -254 7 -263 11 -10 5 -20 4 -24 -2z"/>
<path d="M12175 1570 c-4 -6 -73 -10 -174 -10 -150 0 -170 -2 -184 -18 -14
-16 -17 -44 -17 -182 0 -135 -3 -166 -16 -181 -15 -16 -12 -18 41 -24 39 -5
60 -4 68 4 9 9 8 15 -5 22 -16 9 -18 27 -18 153 l0 143 34 6 c39 6 252 -7 277
-18 10 -3 20 -2 24 4 10 17 402 15 408 -1 3 -10 8 -10 22 2 14 12 58 15 234
15 l216 0 3 -105 c2 -58 0 -120 -3 -137 l-6 -33 -95 0 c-75 0 -96 3 -100 15
-4 8 -12 15 -20 15 -10 0 -14 -15 -14 -60 0 -47 3 -60 15 -60 8 0 15 5 15 10
0 6 50 10 129 10 117 0 131 2 144 19 10 16 12 58 10 192 -2 94 -8 180 -14 190
-9 18 -26 19 -250 19 -161 0 -247 4 -262 11 -16 9 -22 9 -25 0 -5 -14 -412
-16 -412 -1 0 6 -4 10 -9 10 -6 0 -13 -4 -16 -10z"/>
<path d="M15246 1555 c-4 -12 -23 -15 -84 -15 -119 0 -112 23 -112 -352 0
-174 3 -329 6 -346 8 -39 38 -52 124 -52 44 0 70 -4 70 -11 0 -6 5 -9 11 -7 6
2 12 29 15 61 3 45 1 57 -11 57 -7 0 -17 -4 -20 -10 -3 -5 -31 -10 -61 -10
l-54 0 0 300 0 300 54 0 c36 0 58 -5 66 -15 9 -11 16 -13 24 -5 16 16 733 14
738 -2 4 -10 13 -8 39 5 30 16 57 18 239 15 l205 -3 3 -105 c2 -58 0 -120 -3
-137 l-6 -33 -95 0 c-75 0 -96 3 -100 15 -4 8 -12 15 -20 15 -10 0 -14 -15
-14 -60 0 -47 3 -60 15 -60 8 0 15 5 15 10 0 6 50 10 129 10 117 0 131 2 144
19 10 16 12 58 10 192 -2 94 -8 180 -14 190 -9 18 -26 19 -250 19 -155 0 -248
4 -261 11 -14 8 -23 8 -31 0 -8 -8 -125 -11 -373 -11 -312 0 -363 2 -368 15
-3 8 -10 15 -15 15 -5 0 -11 -7 -15 -15z"/>
            </g>
            </svg>
    )
}

export default PipesPipes;