 function Splash2() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1920.000000 1080.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
            fill="#1a464a" stroke="none">
            	<path d="M11637,32c-3,18-17,83-31,143c-13,61-28,155-32,210c-8,115-24,146-99,189c-24,14-68,53-99,87l-12.7,14.3
		c-29,15.6-58.6,30.4-86.8,47.4L11028,721c-68.4-0.4-125.9-1.3-174.5-2.8c-72-24.4-147-40-222.2-50.4c-14.3-2-28.6-3.8-43-5.4
		c-13-4.8-36.7-6.5-84.8-7.1c-32.1-1.6-64.2-1.8-96.3-0.2h-92.3l-37.6,18.3c-66.5,5.3-130.9,32.5-192.9,54.4
		c-60.6,21.4-123.2,36.2-185,53.9c-58.2,16.7-117.9,37.9-168.9,69.6c-70.2,24.4-133.9,49.2-180.6,70.8c-96,45-308,128-327,128
		c-6,0-54-14-105-32c-86-30-105-33-245-36c-139-3-159-2-240,22c-48,14-115,28-148,32c-33,4-71,15-85,24c-18,13-54,20-120,25
		c-5.4,0.3-11.6,0.8-18.3,1.3c-177.2,0.4-354.6,3.5-531.4,16.3c-92,6.6-183.8,16-275.2,28.5c-84.9,11.5-168.9,31-253.8,41.3
		c-142.8,17.2-295.4,6.2-430.4,63.5c-41.8,17.8-79.1,44-115.6,71.7c-59.6,17.9-124.6,39-199.3,64.6c-14.3,5-31.1,11.5-49.6,19
		c-74,13.7-146.1,35.8-213.1,71.2c-72.7,38.4-138.7,90.1-210.5,130.7c-44.9,25.4-89.5,51.1-131.8,80.3l-70.6,36.4
		c-33.8,6.1-68.2,9.5-103.4,9.5c-11.1,0-20.2,3-27.5,7.9l-36.6-2.1l-123-7v111c0,92,3,111,15,111c8,0,15-5,15-11c0-6,8-8,18-5
		c14,5,18,0,20-21c3-37,11-41,32-15c21,26,33,28,49,8c10-12,16-12,41,0c18,9,27,20,24,29c-3,8,4,28,16,45c12,16,17,30,11,30
		c-6,0-11-3-11-7c0-5-11-9-25-9c-14-1-25,4-25,11s10,11,24,10c13-2,27,0,32,4c11,8,12,44,0,52c-4,4-3,15,3,25c10,18,10,18,11-1
		c0-17,2-18,10-5c9,13,12,13,21-2c12-22,35-23,43-3c3,9,18,15,36,15c21,0,30,5,30,17c0,2.6,0.1,4.6,0.2,6.3
		c-0.1,0.2-0.3,0.5-0.4,0.7c-8.9,1.4-16.4,5-22.2,10c1.4-4-2.7-1.5-13.6,7c-10,9-27,19-38,23c-11,4-16,12-12,21c3,10-1,15-12,15
		c-9,0-26,3-38,6c-14,4-21,1-21-10c0-9,6-16,14-16c7,0,13-4,13-8c0-18-13-20-35-6c-14,9-25,11-29,5c-4-5-2-12,4-16
		c17-11,11-20-15-21c-21-2-24,1-14,12c9,11,9,18,1,26c-16,16-15,28,2,28c7,0,19,7,26,15c10,12,9,16-4,21c-11,4-22,0-30-11
		c-8-11-20-16-30-12c-10,4-23-1-33-13c-22-25-33-25-33,0c0,13-7,20-20,20c-11,0-20,7-20,16c0,8,5,12,10,9c10-6,15,20,11,65
		c-1,18,1,21,7,11c6-8,12-9,16-3c3,6,15,8,27,5c16-5,19-11,14-34c-6-26-4-29,17-29c13,0,33,7,44,15c10,8,30,15,43,15
		c16,0,21,4,18,14c-3,8,1,17,9,20c20,8,18,26-3,26c-13,0-8,9,19,34c34,31,36,35,20,49c-9,8-23,12-31,9c-8-3-11,0-7,6c3,6-2,16-11,22
		c-15,9-14,10,7,4c14-3,29-10,35-15c15-14,37-11,51,8c18,24,17,31-6,43c-16,9-19,8-13-6c4-12,1-15-13-12c-14,3-18,11-17,33
		c1,16-3,35-9,42c-7,9-7,13,1,13c6,0,11,9,11,21c0,11,5,17,10,14c6-4,8-11,5-16c-12-18,21-8,50,16c25,20,26,23,8,19
		c-15-4-25,0-33,16c-6,12-17,19-24,17c-31-12-35,10-10,45c30,42,24,72-7,39c-22-23-39-27-39-10c0,8-5,7-15-1c-24-20-42,2-28,32
		c22,46,22,57,2,63c-15,5-17,10-9,26c8,14,8,19-1,17c-8-2-12,14-12,45c-1,26-4,47-9,47c-4,0-8-6-8-14c0-17-5-21-43-30
		c-26-6-28-5-22,15c4,12,3,19-2,16c-5-3-7,8-5,26c5,29,3,31-12,18c-14-12-16-11-16,8c0,11-5,21-11,21c-5,0-7,5-4,10c4,6-7,10-24,10
		c-21,0-31-5-31-15c0-8-5-15-11-15s-9-10-7-23c2-13-1-29-9-35c-15-15-17-32-4-32c5,0,4-11-2-25c-8-16-23-27-45-31c-41-8-46-3-30,31
		c10,23,10,28-5,37c-10,5-15,13-12,17c2,5-2,15-11,22c-12,10-15,10-12-2c2-10-8-15-33-17c-32-3-36-6-31-25c3-12,10-25,15-29
		c7-4,6-13-4-24c-24-29-20-70,8-83c20-9,22-14,13-31c-9-16-8-20,5-20c9,0,15-5,13-12c-8-22-24-19-36,7c-9,20-16,24-40,19
		c-38-8-47,8-28,47c14,29,14,30-10,39c-14,6-27,10-30,10c-17,0-41,40-42,69c0,20-7,38-16,43c-9,5-16,18-16,28c0,21-15,27-25,10
		c-4-6-19-1-38,13c-18,13-40,22-49,20c-10-3-18,0-18,6c0,17-28,13-48-6c-39-37-63-53-79-53c-14,0-12-4,7-25c13-14,18-25,12-25
		c-6,0-14-11-18-25c-3-14-13-25-20-25c-20,0-35-41-21-58c7-9,7-16-1-24c-8-8-13-7-18,5c-9,24-27,21-19-3c4-12-1-38-12-60
		c-15-33-23-40-43-38c-17,2-28-4-36-21c-7-13-18-27-24-31c-11-7-24-26-52-78c-11-21-9-26,11-42c14-10,21-22,17-29c-4-7-2-18,4-26
		c9-11,8-19-4-37l-16-23l-6,22c-3,11-16,23-29,25c-18,4-21,1-17-14c3-13-1-18-17-18c-12,0-21-6-21-15c0-8-5-15-11-15c-8,0-8-5,1-15
		c9-11,10-15,1-15c-7,0-11-10-9-23c2-12,9-21,16-20c6,2,12-4,12-12c0-8,5-14,12-12c6,1,14-6,16-15c2-11-2-18-12-18c-8,0-20-10-27-21
		c-13-24-3-37,43-57c25-10,29-8,51,19c16,21,28,28,41,23c20-8,18-51-3-46c-19,4-61-37-61-59c0-10,5-21,12-25c16-10,58-11,64-2
		c3,4,14,8,26,8c13,0,18,5,15,13c-10,25,8,40,32,28c11-7,21-16,21-21c0-16,27-11,34,5c3,9,16,19,28,24c24,10,23,11-29,27
		c-17,5-17,34,0,34c7,0,23-9,35-20c12-11,25-20,29-20c4,0,34-24,65-53l57-52l6-184l7-184l-124,6c-132,6-205,26-373,99
		c-44,19-109,43-145,52c-27.1,7.6-50,15.2-72.5,24.6c-102.8,34.1-193.9,92.8-279.2,161.2c-0.8,0.4-1.6,0.9-2.3,1.3
		c-93.6,52.2-179.2,124.5-267.9,227.8c-36.6,27.7-72.2,56.4-105.9,86.9c-49.7,44.9-69.2,103.7-85.9,167.3c-1.7,6.5-3.3,13.2-5,20
		c-25.5,45.3-50.3,96.3-73.8,151.5c-2.5,1.8-5,3.6-7.7,5.2c-10.1,6.5-16.5,14.8-20.1,23.8c-75.7,47.1-58.4,128-56.8,205.1
		c0,0.9,0.1,1.7,0.2,2.5c-16.5,55.7-31.4,113-44.2,171c-13.6,61.3-22.9,107.5-29.8,151.8c-9.2,17.6-17.9,35.4-23.3,54.7
		c-13.9,49.4-16.1,105.4-16,156.3c0.1,44.5,3.8,88.6,11,132c-6.8,75.3-15.8,143.2-26.9,198.1c-13,72-29,155-35,185
		c-5.6,28.7-12.6,55.1-22.1,80.9c-28.2,28.9-40.1,66.1-53.8,103c-29.7,44-70.1,92.5-126.3,152.7c-6.3,4.1-12.6,7.9-19,11.6
		c-39.4,23-57.2,51.2-73.5,84.8c-67.8,70.4-143.4,150.8-181.3,192.1l-81,90l-85-3c-113-5-359,32-462,68c-44,15-120,45-170,65
		c-88,37-93,38-266,46c-97,5-210,15-253,24c-238,49-507,163-680,289c-82,59-93,71-126,138c-20,40-41,82-47,93c-14,25-49,137-58,185
		c-11,60-14,65-47,85c-18,10-80,55-138,100c-110,86-193,143-272,189l-48,28V0h11643L11637,32z M5558,2460c-22,1-23,1-4,15
		c25,19,26,19,26,0C5580,2466,5571,2460,5558,2460z M5719,2540c-5,0-7,5-4,10c3,6,8,10,11,10c2,0,4-4,4-10
		C5730,2545,5725,2540,5719,2540z M5700,2625c14,17,60,17,61,0c0-10-62-27-70-19C5689,2608,5693,2617,5700,2625z M5666,2314
		c-23-5-96,11-96,22c0,13,27,33,38,29c8-3,18-5,23-5c4,0,6-7,3-15c-5-12,0-14,25-9C5696,2344,5702,2323,5666,2314z M5680,2465
		c-7-9-15-13-17-11c-7,7,7,26,19,26C5688,2480,5688,2474,5680,2465z M5655,2800c3,6,10,10,16,10c5,0,9-4,9-10c0-5-7-10-16-10
		C5656,2790,5652,2795,5655,2800z M5656,2615c-3,8-1,15,4,15c6,0,11-7,12-15c0-8,1-20,2-27c1-6-9-12-21-13c-30-2-39-13-25-27
		c10-10,17-10,32,0c11,7,21,8,25,2c3-5-4-14-16-21c-18-9-25-7-47,14c-21,22-23,28-12,41c7,9,21,16,32,16
		C5656,2600,5660,2605,5656,2615z M5568,2853c12,13,22,28,22,34c0,16,24,22,32,9c5-6-1-17-12-25c-11-7-20-20-20-27c0-8-10-14-22-14
		h-21L5568,2853z M5535,2740c3,6,8,10,11,10c2,0,4-4,4-10c0-5-5-10-11-10C5534,2730,5532,2735,5535,2740z M5528,2665
		c5,26,22,35,22,11c0-7-6-19-14-25C5525,2642,5524,2645,5528,2665z M4984,2107c-8-7-104,62-111,80c-3,7-10,13-17,13
		c-23,1-89,69-82,85c3,9,10,13,16,10c5-3,11,0,11,7c7,62,18,88,35,81c8-3,14,1,14,11c0,23,13,20,40-9s42-25,16,4c-10,11-15,24-12,30
		c10,16,63-17,74-47c16-41,23-92,13-92c-5,0-19,16-31,35s-24,35-26,35c-6,0,1-12,38-67c23-35,28-52,28-106
		C4990,2142,4987,2111,4984,2107z M5660,2015c0-8-9-15-20-15c-22,0-27,15-7,23C5654,2032,5660,2030,5660,2015z M5617,2188
		c9-2,18-9,21-15c6-19-25-16-32,3C5602,2187,5605,2190,5617,2188z M5613,2091c16-15,17-22,7-41c-11-21-12-21-25-3
		C5566,2084,5582,2123,5613,2091z M5529,2100c17,0,32-4,36-9c3-5,1-12-5-16c-16-10-11-35,8-36c14,0,14-2-3-8c-11-4-22-19-25-32
		c-3-13-14-25-23-27c-15-3-17,6-17,62v66H5529z M5451,2820c5,0,9-3,9-7c0-14-19-38-25-32C5427,2789,5440,2820,5451,2820z M5404,2390
		c3,0,8-4,11-10c3-5,1-10-4-10c-6,0-11,5-11,10C5400,2386,5402,2390,5404,2390z M5345,2710c3,6,11,10,16,10c6,0,7-4,4-10
		c-3-5-11-10-16-10C5343,2700,5342,2705,5345,2710z M5306,2654c8,20,34,21,34,2c0-8-7-17-16-20C5303,2628,5298,2633,5306,2654z
		 M5237,2723c29,8,33,7,31,0c0-5,2-16,7-26c12-29-1-30-29-3C5222,2716,5221,2719,5237,2723z M5017,2353c9,10,23,9,23-2
		c0-5-7-14-15-21c-12-10-15-10-15,2C5010,2340,5013,2350,5017,2353z M5459,1952c16-29,14-59-4-66c-12-4-14-10-5-25c5-11,10-49,10-85
		c0-74-9-81-74-59c-52,17-168,16-257-2c-40-9-78-14-85-11c-17,6-31,428-15,462c6,13,29,44,51,68c35,38,37,43,16,33
		c-14-7-26-16-26-21c0-12-41-56-52-56c-10,0-10,91,0,106c7,10,28,21,45,23c4,1,7-6,7-14c0-18,12-19,28-3c10,10,15,10,23-2
		c8-13,11-13,19,2c8,12,21,16,56,13c54-3,67-17,59-65l-6-35l25,30c17,20,25,25,26,15c0-9-5-21-12-28c-9-9-8-16,6-30
		c17-16,18-16,11,6c-7,24,4,29,23,10c8-8,15-8,26,1c11,10,19,9,35-1c23-14,43-2,21,13c-13,8-13,11,3,23c22,17,22,22-3,29
		c-11,3-20,10-20,16c0,16,15,14,30-4c7-8,19-15,26-15c21,0,17-19-12-57c-18-24-31-32-44-28c-10,3-21,1-25-5c-3-5,1-10,9-10
		c9,0,16-7,16-15c0-8,5-15,10-15c6,0,10,5,10,10c0,13,27,13,36-1c3-6,1-16-5-22c-17-17-13-27,9-27c14,0,20-7,21-22c6-91,5-99-9-107
		C5451,1975,5450,1968,5459,1952z M5473,2664c15,32,40,35,35,4c-2-15-11-24-25-26C5463,2639,5462,2641,5473,2664z"/>
	<path d="M5186,2134c-22-22-20-24,13-11c17,6,28,15,25,19C5216,2154,5203,2152,5186,2134z"/>
	<path d="M5260,2124c0-9,7-14,17-12c25,5,28,28,4,28C5269,2140,5260,2134,5260,2124z"/>
	<path d="M5317,2120c-9-11-17-26-17-33c-1-6-10-23-21-36c-15-18-23-21-31-13c-9,9-9,12,0,12c18,0,15,37-4,44c-8,3-12,10-9,16
		c13,20-7,9-30-17c-31-35-31-41-1-49c14-3,27-12,30-20s12-14,21-14c8,0,15-4,15-10c0-5,5-10,10-10c6,0,10,9,10,19c0,21,27,54,39,47
		c4-3,8,15,9,40c1,24,0,44-1,44S5327,2131,5317,2120z"/>
	<path d="M5395,2920c-4-12-5-24-2-27c3-2,8,5,12,17s5,24,2,27C5404,2939,5399,2932,5395,2920z"/>
	<path d="M5822,2195c-7-8-9-15-4-15s15,7,22,15C5856,2214,5839,2214,5822,2195z"/>
            </g>
        </svg>
    )
}

export default Splash2;