 function WhiteMid() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1920.000000 1080.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
            fill="#fff5f0" stroke="none">
<path d="M3574 6677 c-2 -7 -3 -58 -2 -113 l3 -99 1225 0 1225 0 0 110 0 110
-1223 3 c-1008 2 -1223 0 -1228 -11z"/>
<path d="M3919 6315 c-149 -48 -251 -197 -236 -343 7 -71 57 -171 104 -213
130 -113 316 -113 446 0 20 18 51 63 69 99 81 163 16 353 -147 433 -77 38
-166 47 -236 24z"/>
<path d="M5479 6315 c-149 -48 -251 -197 -236 -343 7 -71 57 -171 104 -213
130 -113 316 -113 446 0 20 18 51 63 69 99 81 163 16 353 -147 433 -77 38
-166 47 -236 24z"/>
<path d="M4452 6188 c-17 -17 -17 -339 0 -356 9 -9 98 -12 338 -12 240 0 329
3 338 12 17 17 17 339 0 356 -9 9 -98 12 -338 12 -240 0 -329 -3 -338 -12z"/>
            </g>
        </svg>
    )
}

export default WhiteMid;