 function Splash0Left() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1920.000000 1080.000000"
                preserveAspectRatio="xMidYMid meet">
                    
                <g transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
                fill="#0c2426" stroke="none">

                <path className='shift' d="M0 9596 c0 -1058 2 -1205 15 -1216 8 -7 15 -23 15 -36 0 -37 16 -69
                44 -88 31 -20 57 -11 97 36 22 27 28 29 47 19 29 -16 28 -4 -6 72 -16 35 -40
                70 -55 80 -29 19 -35 37 -14 37 7 0 26 -18 43 -41 65 -90 116 -119 212 -119
                43 0 57 -11 67 -52 11 -45 -19 -85 -71 -92 -50 -6 -76 -20 -121 -60 -30 -27
                -53 -28 -53 -2 0 18 -49 66 -67 66 -29 0 -58 -119 -33 -135 20 -12 10 -25 -19
                -25 -16 0 -31 -4 -33 -9 -1 -5 -15 -12 -29 -16 l-26 -6 5 74 c3 49 1 77 -7 81
                -8 5 -11 -36 -11 -149 l0 -155 28 6 c15 4 42 17 61 30 l34 23 33 -27 c18 -15
                45 -36 60 -46 l28 -20 -50 -23 c-27 -12 -54 -23 -61 -23 -18 0 -30 -30 -27
                -65 1 -16 3 -35 3 -41 2 -18 74 -60 152 -87 64 -23 80 -25 114 -16 22 6 43 14
                47 17 3 4 -21 28 -54 54 -45 36 -65 47 -83 42 -12 -3 -26 -1 -29 5 -4 6 -3 11
                2 11 19 0 87 62 86 78 -2 36 -18 77 -38 94 -12 9 -18 18 -13 19 4 1 10 2 15 3
                4 1 12 4 17 8 6 3 32 2 59 -3 39 -7 53 -6 66 6 23 21 40 19 40 -4 0 -15 -10
                -22 -42 -28 -75 -15 -49 -58 48 -83 28 -7 43 -17 47 -30 6 -23 39 -27 60 -6 7
                8 38 17 68 21 61 8 86 -7 76 -44 -5 -19 4 -25 113 -75 14 -7 27 -20 29 -31 3
                -17 1 -18 -13 -6 -23 19 -82 31 -95 18 -7 -7 -2 -19 14 -39 25 -29 32 -48 18
                -48 -4 0 -17 12 -29 28 l-22 27 -12 -30 c-12 -31 -6 -100 10 -120 5 -6 12 -26
                15 -45 9 -52 92 -146 112 -126 3 3 5 32 5 65 0 57 2 60 29 70 16 5 29 15 29
                22 0 6 7 9 15 5 8 -3 15 -2 15 3 0 5 14 11 31 15 23 4 42 -1 73 -19 35 -20 56
                -25 113 -25 65 0 133 20 133 39 0 10 -90 41 -117 41 -38 0 -26 17 17 24 51 8
                111 37 107 50 -2 6 -17 16 -33 21 -52 18 -57 25 -37 47 18 21 18 21 -37 14
                -30 -4 -68 -14 -84 -22 -42 -20 -54 -18 -61 14 -4 20 2 41 19 73 32 58 43 109
                23 109 -20 0 -108 -90 -124 -127 -7 -18 -13 -46 -13 -62 0 -44 -34 -60 -60
                -29 -29 35 -25 63 11 78 32 14 67 56 55 67 -4 4 -34 8 -66 8 -70 1 -86 12 -65
                42 12 17 28 21 100 25 46 2 88 8 92 11 4 4 -12 27 -35 50 -29 30 -41 49 -36
                60 8 21 -16 23 -55 3 -32 -17 -151 -10 -151 8 0 13 85 52 135 62 19 3 35 11
                35 18 0 6 16 23 35 39 51 41 90 96 77 109 -7 7 -6 22 4 50 8 22 14 48 14 58 0
                12 18 24 60 40 33 12 60 26 60 30 0 11 -69 63 -105 79 l-30 14 35 13 c19 7 77
                13 129 13 82 1 100 4 138 27 24 14 41 29 39 33 -11 17 -156 61 -200 61 -50 0
                -62 14 -21 24 14 4 39 15 55 25 29 17 30 18 11 35 -19 17 -19 18 7 62 23 38
                31 44 52 39 19 -4 21 -3 9 5 -12 7 -16 22 -14 52 4 77 14 98 45 98 22 0 29 -5
                33 -27 3 -16 1 -32 -4 -37 -12 -14 -11 -66 1 -66 6 0 29 29 52 65 36 57 46 65
                72 65 46 0 62 13 41 34 -9 9 -21 16 -27 16 -6 0 -7 11 -3 28 5 22 4 25 -6 15
                -12 -12 -49 -18 -49 -8 0 2 20 23 45 46 25 23 45 45 45 49 0 4 -18 14 -40 23
                -38 15 -54 37 -27 37 16 0 68 55 60 63 -3 3 -26 -1 -51 -9 -26 -7 -55 -11 -65
                -8 -16 5 -37 -9 -109 -70 -9 -8 -27 -17 -40 -21 -12 -4 -31 -15 -41 -23 -18
                -16 -18 -15 -11 13 4 16 22 49 41 73 18 24 33 56 34 70 1 15 2 34 3 42 2 8 3
                29 4 45 l2 30 53 -57 c47 -51 58 -58 91 -58 45 0 101 39 112 78 4 15 7 29 6
                32 0 3 -3 17 -7 32 -6 23 -2 30 27 48 18 11 42 20 53 20 11 0 26 9 32 19 13
                21 58 18 49 -4 -2 -6 0 -18 5 -26 5 -9 3 -22 -6 -36 -8 -12 -15 -28 -15 -36 0
                -7 -16 -21 -35 -30 -34 -16 -35 -19 -35 -70 0 -29 5 -58 12 -65 9 -9 7 -12 -9
                -12 -32 0 -35 -13 -10 -47 28 -38 85 -93 96 -93 5 0 14 -13 21 -29 7 -18 30
                -39 61 -56 28 -15 52 -25 54 -23 9 9 -49 146 -73 172 -30 32 -32 32 60 2 58
                -18 57 -4 -3 44 -22 18 -38 35 -34 38 3 3 47 5 98 3 81 -2 94 -5 109 -24 15
                -19 19 -20 28 -7 5 8 10 18 10 23 0 4 7 7 15 7 18 0 18 1 5 36 -17 44 -1 53
                93 48 106 -6 124 5 79 47 -38 34 -37 39 4 42 24 2 29 -3 38 -38 20 -73 32 -78
                65 -24 18 30 18 38 -5 131 -11 46 24 40 82 -13 35 -33 64 -50 90 -55 22 -4 51
                -13 66 -20 15 -8 31 -14 36 -14 18 0 9 36 -16 62 l-25 27 27 11 c15 5 32 10
                37 10 19 0 8 20 -25 45 -19 14 -42 38 -51 52 -16 23 -16 26 2 38 10 7 27 20
                38 29 l20 16 -20 16 c-12 9 -35 14 -59 12 -25 -1 -41 2 -44 10 -2 6 1 12 7 12
                21 0 11 19 -11 22 -27 4 -27 37 -1 53 9 6 28 20 44 33 l28 23 43 -43 c30 -30
                60 -48 98 -60 173 -52 273 -104 330 -170 14 -15 72 -52 130 -81 l105 -52 -3
                -44 c-3 -38 1 -48 29 -77 l32 -33 -13 -58 c-13 -60 -59 -151 -96 -191 -28 -32
                -87 -36 -92 -7 -7 42 -11 48 -26 36 -10 -9 -15 -8 -20 4 -3 8 -15 15 -26 15
                -13 0 -20 7 -20 20 0 24 -27 80 -39 80 -17 0 -51 -48 -51 -73 0 -37 -15 -41
                -39 -12 -27 36 -89 77 -130 86 l-33 7 6 -38 c3 -22 17 -56 31 -76 14 -20 25
                -40 25 -45 0 -18 95 -80 132 -86 32 -5 42 -12 52 -37 18 -42 9 -52 -19 -22
                -16 17 -29 23 -44 19 -20 -5 -20 -7 -6 -23 20 -22 19 -42 -5 -73 -23 -29 -26
                -57 -6 -57 32 0 72 23 88 50 20 35 34 37 66 11 37 -32 23 -119 -18 -106 -12 4
                -20 0 -24 -12 -4 -12 -24 -22 -58 -31 -80 -19 -86 -35 -9 -26 l63 7 -7 -43
                c-4 -23 -3 -52 3 -64 5 -11 17 -38 25 -58 9 -21 20 -38 24 -38 5 0 16 27 26
                60 9 33 21 60 26 60 4 0 17 -12 27 -26 35 -49 120 -121 130 -111 5 5 -14 44
                -38 79 -21 29 0 32 32 4 17 -14 39 -26 49 -26 9 0 27 -7 39 -16 l22 -15 -17
                35 c-9 20 -24 38 -33 41 -10 3 -25 14 -34 24 -23 25 -4 39 59 43 l50 3 -28 14
                c-15 8 -26 20 -23 26 7 19 -83 88 -123 95 -20 3 -49 2 -64 -3 -22 -7 -32 -5
                -48 9 -22 20 -13 36 23 41 33 5 58 40 58 80 0 34 0 34 -37 28 -36 -6 -37 -5
                -18 10 11 9 36 26 55 38 26 17 36 31 38 55 2 18 19 56 38 84 19 29 34 57 34
                63 0 5 6 22 14 37 8 14 19 50 25 79 19 86 42 102 62 42 12 -39 106 -143 128
                -143 10 0 12 21 9 77 -2 65 0 78 13 81 17 3 99 -73 99 -92 0 -7 5 -17 12 -24
                15 -15 38 -81 38 -113 0 -29 -53 -89 -80 -89 -20 0 -31 -10 -24 -21 2 -5 19 1
                37 11 43 26 57 25 57 -5 0 -21 5 -25 31 -25 18 0 28 -4 24 -10 -4 -6 -10 -8
                -15 -5 -4 3 -13 -2 -20 -9 -9 -12 -7 -17 9 -26 12 -6 21 -20 21 -31 0 -33 18
                -58 38 -52 13 3 21 -2 25 -16 3 -13 19 -23 47 -31 23 -6 44 -10 46 -8 2 3 -19
                29 -47 59 -28 30 -53 67 -56 82 -3 16 -12 31 -19 34 -8 3 -14 12 -14 20 0 10
                5 13 18 8 9 -4 29 -11 43 -15 14 -4 41 -16 60 -26 25 -13 55 -18 116 -18 45 0
                86 4 90 8 15 14 -29 34 -100 46 -77 14 -113 35 -84 51 21 12 87 82 87 92 0 14
                -124 -3 -136 -19 -16 -20 -24 -17 -24 7 0 20 -4 21 -54 16 -52 -5 -55 -4 -63
                20 -9 26 9 74 50 135 15 22 24 26 48 21 37 -7 37 6 -1 42 -16 15 -27 32 -24
                36 2 4 39 15 82 25 42 9 95 28 116 42 l39 24 -57 29 c-31 16 -67 29 -81 29
                -18 0 -25 5 -25 20 0 11 8 24 18 27 9 4 29 20 44 35 l26 28 -35 15 -35 15 22
                27 c19 24 19 28 4 36 -10 6 -25 5 -41 -2 -29 -13 -76 -15 -69 -2 8 13 -16 20
                -49 14 -25 -5 -27 -3 -23 15 4 14 2 19 -7 16 -21 -9 -27 14 -11 43 22 43 39
                93 33 99 -3 3 -15 0 -27 -6 -15 -8 -25 -8 -34 -1 -9 8 -19 4 -36 -14 -20 -21
                -74 -45 -102 -45 -21 0 -3 23 47 62 l56 43 -25 20 c-40 33 -90 61 -140 80 -25
                10 -46 21 -46 25 0 4 26 10 58 12 45 5 69 14 106 39 l48 34 -31 12 c-17 7 -51
                14 -76 15 -28 1 -51 8 -60 18 -15 16 -14 22 10 63 14 25 25 49 25 52 0 3 -16
                3 -36 -1 -32 -6 -39 -3 -65 26 l-30 33 28 17 c24 15 31 15 50 3 12 -8 24 -21
                27 -29 9 -23 36 -16 46 11 5 14 13 25 17 25 5 0 24 14 43 30 53 45 73 40 95
                -24 6 -16 27 -43 48 -60 35 -28 43 -30 118 -29 44 1 83 4 86 7 11 11 -9 25
                -58 41 -28 10 -63 24 -78 31 -14 8 -32 14 -39 14 -8 0 -12 8 -10 18 3 14 15
                17 78 18 46 1 86 -4 104 -12 16 -8 37 -14 46 -14 9 0 25 -7 36 -15 16 -12 17
                -16 4 -24 -12 -8 -11 -14 9 -35 13 -14 38 -28 55 -32 17 -4 45 -13 62 -22 32
                -15 124 -10 124 7 0 13 -96 61 -123 61 -13 0 -30 6 -36 14 -10 12 -4 18 33 35
                25 11 46 24 46 30 0 25 -96 24 -147 -1 -12 -6 -30 -7 -40 -3 -10 4 -29 8 -42
                9 -27 1 -42 26 -17 26 26 0 18 36 -9 48 -33 15 -71 15 -98 0 -29 -16 -55 -20
                -61 -9 -7 11 33 38 70 47 16 4 40 15 53 26 32 24 71 24 71 -1 0 -10 7 -24 16
                -32 13 -10 17 -10 25 2 5 8 9 18 9 22 0 5 8 6 18 3 14 -5 30 7 60 43 23 27 42
                55 42 61 0 7 11 25 25 42 24 28 32 48 19 48 -14 0 -72 -38 -107 -69 l-37 -35
                0 36 c0 42 -28 57 -54 27 -8 -10 -28 -21 -43 -25 -15 -4 -52 -20 -80 -36 -41
                -23 -53 -26 -53 -14 0 8 4 17 9 20 5 4 28 33 50 66 40 59 41 60 17 60 -43 0
                -172 -152 -178 -209 l-3 -33 -28 26 -27 26 20 20 c11 11 20 26 20 33 0 7 11
                41 24 75 l25 62 -24 0 c-32 0 -45 -20 -52 -79 -3 -28 -10 -54 -14 -57 -5 -3
                -20 19 -33 49 -38 86 -49 83 -44 -13 3 -79 5 -84 36 -109 40 -34 41 -46 2 -26
                -16 8 -36 15 -45 15 -8 0 -15 9 -15 20 0 12 -7 35 -16 51 -12 25 -18 28 -30
                18 -21 -18 -24 -9 -12 44 5 27 8 51 5 53 -3 3 -19 -2 -36 -10 -52 -27 -93 -17
                -56 14 8 7 15 16 15 21 0 5 -294 9 -675 9 -443 0 -675 -3 -675 -10 0 -5 -9
                -10 -20 -10 -11 0 -20 5 -20 10 0 7 -348 10 -1025 10 l-1025 0 0 -1204z m1980
                1167 c-1 -17 -79 -97 -87 -90 -4 5 0 13 10 18 9 6 17 15 17 21 0 7 10 22 22
                35 21 23 38 30 38 16z m-880 -63 c0 -5 -13 -16 -29 -25 -16 -8 -31 -20 -33
                -27 -5 -13 -38 -4 -38 11 0 6 19 19 43 30 50 23 57 24 57 11z m1555 -104 l0
                -95 -30 6 c-66 13 -109 53 -56 53 24 0 55 36 47 55 -6 17 19 75 32 75 4 0 7
                -42 7 -94z m-605 24 c0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 9 10 20 10
                11 0 20 -4 20 -10z m-962 -18 c2 -7 -6 -12 -17 -12 -21 0 -27 11 -14 24 9 9
                26 2 31 -12z m2121 -11 c2 0 -4 -10 -14 -21 -14 -15 -21 -17 -32 -9 -22 19
                -15 35 15 33 15 -1 29 -2 31 -3z m-719 -29 c0 -14 -11 -21 -26 -15 -21 8 -17
                23 6 23 11 0 20 -4 20 -8z m1200 -18 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9
                14 15 11 15 -7z m-2709 -60 c15 -32 26 -61 23 -66 -7 -11 -73 46 -97 83 -20
                30 -20 29 23 42 19 6 17 8 51 -59z m1866 40 c-9 -9 -28 6 -21 18 4 6 10 6 17
                -1 6 -6 8 -13 4 -17z m205 4 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1
                -11 -7 -17z m374 -13 c-3 -9 -18 -15 -33 -15 l-28 1 30 24 c29 24 43 19 31
                -10z m-546 -39 c-14 -14 -31 11 -22 32 4 12 7 12 19 -4 9 -12 10 -21 3 -28z
                m800 14 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z
                m-175 -40 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11
                -10z m-349 -40 c22 -40 16 -53 -10 -23 -13 15 -28 25 -32 21 -4 -5 0 -16 9
                -26 24 -26 22 -29 -26 -50 -46 -20 -147 -38 -147 -25 1 24 20 36 50 29 l33 -6
                -5 50 c-3 27 -3 51 -1 54 2 2 29 3 60 2 47 -1 57 -5 69 -26z m71 -20 c3 -11
                14 -20 24 -20 26 0 30 -14 12 -39 -11 -17 -22 -22 -41 -18 -18 3 -31 -1 -42
                -16 -16 -19 -17 -19 -16 16 1 21 4 37 9 37 4 0 7 11 7 23 0 13 3 27 7 30 13
                14 35 6 40 -13z m-562 -9 c35 -11 65 -27 74 -40 16 -25 14 -28 -19 -31 -55 -5
                -67 1 -98 45 -17 25 -28 45 -24 45 4 0 34 -9 67 -19z m-2510 -21 c3 -5 -1 -10
                -10 -10 -9 0 -13 5 -10 10 3 6 8 10 10 10 2 0 7 -4 10 -10z m3242 -54 c-4 -9
                -13 -16 -22 -16 -18 0 -19 5 -4 32 13 24 35 9 26 -16z m-888 -27 c11 -18 11
                -23 -1 -30 -18 -12 -48 -11 -48 2 0 15 19 49 28 49 4 0 13 -10 21 -21z m539
                -19 c13 -37 6 -41 -35 -20 -30 16 -31 18 -15 33 24 25 39 21 50 -13z m97 1
                c-3 -6 -11 -11 -17 -11 -6 0 -6 6 2 15 14 17 26 13 15 -4z m-2045 -11 c8 -5
                12 -12 9 -15 -8 -8 -39 5 -39 16 0 11 11 11 30 -1z m1730 0 c0 -5 7 -10 16
                -10 9 0 18 -9 21 -20 3 -11 13 -20 22 -21 12 0 11 -4 -6 -16 -13 -8 -23 -24
                -23 -34 0 -22 -16 -33 -37 -25 -11 5 -12 10 -5 19 6 7 9 22 6 34 -3 11 -1 24
                5 27 7 5 6 15 -5 32 -10 15 -12 24 -5 24 6 0 11 -4 11 -10z m-1078 -26 c15
                -14 -7 -41 -42 -52 -56 -17 -63 -16 -50 8 16 30 77 60 92 44z m1249 -26 c-10
                -28 -29 -35 -37 -13 -7 17 10 35 33 35 9 0 10 -7 4 -22z m-1041 -30 c0 -12
                -35 -9 -48 4 -7 7 -12 18 -12 26 0 10 7 9 30 -5 17 -9 30 -21 30 -25z m650 26
                c8 -1 27 -5 43 -9 40 -8 33 -22 -17 -29 -53 -8 -114 -37 -140 -65 -11 -12 -26
                -21 -35 -21 -17 1 -58 27 -50 33 2 2 29 18 60 35 30 17 64 41 74 52 11 13 25
                18 34 14 9 -3 23 -8 31 -10z m460 -12 c0 -10 5 -23 11 -29 8 -8 3 -16 -20 -27
                l-31 -17 0 34 c0 31 14 57 32 57 4 0 8 -8 8 -18z m-1236 -53 c13 -64 8 -77
                -30 -89 -42 -14 -66 -1 -45 24 16 19 6 36 -19 36 -30 0 -34 19 -5 26 17 4 25
                13 25 28 0 32 7 36 39 20 19 -9 31 -25 35 -45z m1406 37 c0 -27 -29 -44 -41
                -24 -5 8 -3 14 6 16 8 2 15 10 15 18 0 8 5 14 10 14 6 0 10 -11 10 -24z
                m-1605 -26 c-3 -5 -13 -10 -21 -10 -8 0 -12 5 -9 10 3 6 13 10 21 10 8 0 12
                -4 9 -10z m-695 -34 c0 -25 -12 -46 -26 -46 -14 0 -20 41 -7 53 12 12 33 7 33
                -7z m1982 -68 c-14 -14 -36 37 -27 61 5 12 10 7 22 -17 12 -24 13 -36 5 -44z
                m244 23 c-9 -16 -16 -41 -15 -53 0 -13 1 -52 3 -88 1 -36 4 -64 6 -63 37 14
                43 14 61 -3 12 -13 16 -27 13 -41 -8 -31 -45 -30 -73 3 -15 17 -28 23 -41 19
                -12 -4 -20 -2 -20 4 0 7 -22 11 -54 11 -54 0 -55 0 -54 30 1 42 12 45 51 16
                37 -28 57 -32 74 -15 13 13 3 36 -31 76 -17 20 -17 23 -3 29 9 3 15 10 12 14
                -3 5 -1 11 4 14 5 4 12 2 16 -4 10 -17 25 -11 25 9 0 11 -8 27 -17 36 -15 16
                -18 16 -28 1 -9 -12 -14 -13 -23 -4 -17 17 -15 28 7 28 10 0 34 6 52 14 43 18
                55 7 35 -33z m-399 -29 c36 -6 48 -12 47 -25 -2 -21 11 -21 66 -2 24 8 44 14
                46 12 1 -1 6 -26 10 -55 5 -43 4 -53 -10 -58 -9 -3 -16 -17 -16 -30 0 -16 -13
                -32 -45 -54 -28 -19 -45 -38 -45 -51 0 -25 -15 -21 -110 25 -47 22 -69 39 -79
                61 -7 17 -21 39 -31 50 -9 11 -28 40 -41 63 -23 43 -23 44 -4 58 10 8 28 14
                39 14 12 1 35 7 51 14 25 11 34 11 52 0 11 -7 43 -17 70 -22z m-1027 -2 c0 -5
                -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m1608 -14
                c3 -12 -1 -17 -10 -14 -7 3 -15 13 -16 22 -3 12 1 17 10 14 7 -3 15 -13 16
                -22z m-2628 -26 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9
                -4 9 -10z m268 -7 c-2 -10 -13 -19 -26 -21 -24 -4 -29 7 -10 26 18 18 40 15
                36 -5z m1112 -48 c0 -8 -4 -15 -9 -15 -13 0 -22 16 -14 24 11 11 23 6 23 -9z
                m-1401 -11 c3 -2 0 -9 -6 -15 -13 -13 -53 5 -53 22 0 10 48 4 59 -7z m1853
                -21 c25 -22 23 -33 -6 -33 -28 0 -40 14 -32 35 7 19 15 19 38 -2z m-1447 -73
                c24 0 18 -19 -13 -37 -31 -18 -93 -103 -96 -130 -1 -15 6 -18 43 -16 24 1 62
                11 84 22 28 14 44 17 53 10 6 -5 16 -9 20 -8 30 5 34 -3 33 -58 -1 -53 12
                -120 22 -116 2 0 16 6 30 13 24 11 29 8 67 -29 23 -23 44 -41 47 -41 14 0 94
                -66 91 -75 -6 -22 -38 -15 -68 14 -24 24 -41 31 -69 31 -29 0 -39 5 -44 20 -7
                23 -21 26 -28 6 -9 -22 -71 -105 -80 -105 -14 -1 -47 -43 -63 -78 -8 -18 -18
                -33 -22 -33 -4 0 -23 13 -42 30 -19 16 -42 29 -50 30 -19 0 -60 28 -60 41 0 5
                18 9 39 9 39 0 191 64 191 80 0 4 -21 13 -47 19 -27 7 -56 17 -65 22 -10 5
                -38 9 -62 9 l-44 0 14 38 c22 62 20 65 -36 60 -55 -6 -60 0 -29 28 21 19 49
                76 49 100 0 10 -7 13 -26 8 -25 -6 -26 -6 -19 27 4 19 10 38 12 42 6 9 46 5
                74 -7 23 -10 31 47 10 69 -24 24 4 38 40 19 16 -8 35 -14 44 -14z m2345 -26
                c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z
                m-2537 -8 c22 -17 22 -36 -2 -36 -22 0 -45 25 -36 40 8 13 16 12 38 -4z m719
                -14 c13 -16 29 -27 36 -24 18 6 15 -5 -8 -28 -13 -13 -20 -15 -20 -7 0 7 -13
                21 -30 30 -29 17 -38 36 -23 50 11 12 21 8 45 -21z m370 -4 c-15 -15 -26 -4
                -18 18 5 13 9 15 18 6 9 -9 9 -15 0 -24z m962 -64 c4 -9 5 -19 2 -21 -8 -8
                -47 18 -41 28 10 15 32 11 39 -7z m260 -74 c20 -39 34 -75 31 -80 -4 -6 -20
                -17 -36 -26 -28 -14 -32 -13 -59 6 -36 25 -35 25 -10 75 11 22 20 52 20 67 0
                44 14 33 54 -42z m-1806 7 c2 -15 -1 -27 -5 -27 -15 0 -33 23 -33 43 0 30 34
                16 38 -16z m761 19 c9 -10 6 -17 -13 -29 -28 -19 -40 -5 -24 26 11 21 22 22
                37 3z m-1794 -51 c0 -22 -5 -40 -12 -42 -9 -3 -13 9 -13 42 0 33 4 45 13 43 7
                -3 12 -21 12 -43z m330 7 c-14 -15 -25 -32 -25 -39 0 -23 -19 -14 -26 11 -7
                31 1 46 26 46 10 0 22 5 25 10 4 6 11 8 16 5 5 -4 -2 -18 -16 -33z m1575 -12
                c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z
                m-800 -40 c34 0 49 -17 57 -66 l6 -37 -39 8 c-21 5 -56 8 -76 7 l-37 -2 0 57
                c1 32 3 61 6 66 2 4 16 -2 30 -13 14 -11 38 -20 53 -20z m505 -40 c-3 -5 -10
                -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z m1 -45 c-3 -9
                -11 -13 -16 -10 -8 5 -7 11 1 21 14 18 24 11 15 -11z m-1206 1 c0 -3 -4 -8
                -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m1460 -22 c0 -8
                -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m-176 -13
                c3 -4 3 -11 0 -14 -8 -8 -34 3 -34 14 0 11 27 12 34 0z m1540 -46 c16 -42 -34
                -80 -72 -56 -21 13 -10 46 13 44 11 -1 24 5 28 13 10 18 23 18 31 -1z m-2814
                -57 c1 -33 19 -68 36 -68 5 0 32 -25 61 -56 l52 -56 -84 5 c-46 3 -88 8 -94
                12 -6 3 -11 19 -11 35 0 16 -5 51 -12 79 -10 47 -10 53 8 66 31 23 44 18 44
                -17z m2898 12 c7 -12 24 -25 38 -30 30 -12 27 -34 -4 -38 -12 -2 -29 -13 -37
                -25 l-15 -22 -35 39 c-41 46 -38 38 -23 71 14 31 57 34 76 5z m-2516 -292 c-7
                -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m-528 -47 c3 -4 16 -6
                29 -4 13 3 29 -2 35 -10 10 -12 6 -18 -23 -36 -20 -11 -44 -34 -53 -51 -9 -16
                -20 -30 -24 -30 -6 0 0 102 8 128 4 14 20 16 28 3z m319 -5 c11 -9 14 -17 8
                -21 -14 -8 -53 13 -46 26 8 12 16 11 38 -5z m177 -6 c0 -5 -4 -10 -10 -10 -5
                0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m2655 0 c4 -6 -8 -10 -29
                -10 -20 0 -36 5 -36 10 0 6 13 10 29 10 17 0 33 -4 36 -10z m-2947 -21 c-2
                -12 -14 -26 -26 -30 -20 -7 -25 -5 -29 11 -6 21 -6 21 -29 -1 -27 -25 -39 -21
                -29 10 6 19 14 22 98 29 13 2 18 -4 15 -19z m2202 -5 c0 -8 7 -22 15 -30 22
                -22 18 -36 -7 -32 -19 2 -24 10 -26 41 -2 21 1 37 7 37 6 0 11 -7 11 -16z
                m-1786 -49 c-5 -12 -10 -13 -20 -4 -19 15 -18 19 6 19 13 0 18 -5 14 -15z
                m-874 -35 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4
                -10z m690 -12 c0 -7 9 -24 20 -38 30 -38 25 -57 -21 -79 -48 -23 -86 -14 -71
                17 5 9 19 38 31 65 22 45 41 61 41 35z m-720 -87 c0 -11 -29 -35 -35 -29 -10
                9 7 38 21 38 8 0 14 -4 14 -9z m-52 -70 c37 -49 42 -81 13 -81 -11 0 -23 -4
                -26 -10 -9 -14 -45 -12 -45 3 1 6 7 23 15 37 21 36 19 60 -5 60 -19 0 -27 20
                -13 34 12 11 27 1 61 -43z m2822 -46 c0 -13 7 -28 16 -33 10 -5 12 -12 6 -15
                -11 -8 -62 40 -62 59 0 13 20 24 33 17 4 -2 7 -15 7 -28z m-2980 7 c0 -5 -6
                -16 -13 -27 -10 -13 -16 -15 -21 -6 -4 6 -2 18 4 26 13 16 30 20 30 7z m245
                -22 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z
                m151 -67 c10 -40 14 -45 28 -34 10 8 18 5 31 -9 13 -14 21 -17 26 -9 4 6 12 8
                18 5 18 -11 13 -37 -9 -43 -25 -7 -26 -20 -2 -28 9 -3 28 -15 41 -26 l24 -20
                -22 -22 c-27 -29 -27 -57 0 -91 28 -36 57 -50 73 -36 15 12 26 -13 26 -60 0
                -13 7 -44 17 -68 16 -43 16 -43 -10 -70 -15 -16 -27 -33 -27 -40 0 -7 -5 -12
                -10 -12 -7 0 -9 13 -5 33 5 27 2 40 -19 64 -14 17 -26 42 -26 56 0 26 -40 67
                -65 67 -7 0 -20 -31 -31 -70 -11 -42 -23 -70 -31 -70 -7 0 -13 4 -13 9 0 6
                -13 29 -29 53 -51 77 -66 152 -32 161 25 6 32 69 20 172 -9 80 -5 115 11 115
                6 0 13 -12 16 -27z m254 4 c0 -7 -5 -18 -11 -24 -8 -8 -5 -14 10 -22 27 -15
                27 -35 -1 -61 -20 -18 -24 -19 -34 -5 -6 8 -9 26 -7 39 4 18 -3 29 -26 45 -17
                12 -31 27 -31 32 0 5 23 9 50 9 34 0 50 -4 50 -13z m-363 -39 c-3 -8 -6 -5 -6
                6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-39 -40 c29 -60 25 -103 -7 -74 -10 9
                -41 20 -69 26 -29 5 -65 16 -80 24 l-27 15 35 1 c19 1 51 7 70 14 51 20 65 19
                78 -6z m72 -79 c0 -11 -4 -17 -10 -14 -5 3 -10 13 -10 21 0 8 5 14 10 14 6 0
                10 -9 10 -21z m461 -119 c5 0 18 -19 30 -42 12 -24 31 -53 42 -66 18 -20 19
                -24 7 -32 -8 -5 -27 -10 -42 -10 -22 0 -29 6 -34 28 -4 15 -15 45 -25 67 -23
                50 -24 69 -3 61 9 -3 20 -6 25 -6z m-411 -169 c15 -29 12 -38 -14 -45 -14 -3
                -36 -25 -53 -51 -32 -50 -38 -53 -53 -25 -8 15 -17 19 -31 15 -13 -4 -19 -2
                -19 9 0 9 6 16 14 16 8 0 28 11 44 25 17 14 36 25 44 25 8 0 21 11 28 25 16
                30 26 31 40 6z m50 -91 c0 -11 -4 -20 -10 -20 -5 0 -10 9 -10 20 0 11 5 20 10
                20 6 0 10 -9 10 -20z m-337 -55 c-18 -13 -33 -11 -33 4 0 5 11 9 25 9 23 0 24
                -2 8 -13z m404 -17 c33 -31 30 -64 -9 -88 -39 -24 -95 -23 -122 1 -18 17 -18
                18 4 49 22 31 22 31 37 11 10 -14 25 -21 49 -21 38 0 42 9 18 45 -20 31 -8 32
                23 3z m-537 -27 c15 -29 12 -41 -10 -41 -16 0 -20 7 -20 30 0 34 14 39 30 11z"/>
                <path className='shift' d="M4254 10785 c-11 -27 4 -42 46 -49 44 -7 70 6 91 46 9 16 3 18 -61
                18 -54 0 -72 -4 -76 -15z"/>
                <path className='shift' d="M4508 10793 c21 -8 13 -32 -19 -60 -17 -16 -45 -48 -62 -73 -26 -38
                -32 -57 -35 -118 l-4 -73 38 16 c42 17 104 72 104 90 0 7 14 31 30 53 17 23
                30 49 30 57 0 8 7 15 15 15 19 0 19 -8 -1 -55 -19 -47 -11 -145 17 -191 10
                -17 19 -37 19 -44 0 -7 10 -28 22 -48 17 -28 23 -32 32 -21 6 8 29 14 50 15
                l40 1 -32 -27 c-28 -23 -32 -33 -32 -76 0 -41 6 -56 32 -88 l31 -38 17 22 c14
                19 18 20 29 7 10 -13 7 -18 -18 -33 -16 -9 -34 -28 -41 -42 -14 -32 8 -58 63
                -73 37 -10 43 -8 86 21 36 26 48 30 63 21 10 -6 26 -11 36 -11 16 0 20 -10 24
                -63 4 -51 13 -75 41 -120 47 -73 162 -186 190 -187 4 0 5 20 2 44 -6 57 -22
                91 -71 161 -12 17 -25 40 -28 53 -6 18 -3 22 13 22 11 0 22 -9 26 -20 5 -15
                12 -18 35 -13 23 4 31 1 36 -13 14 -46 56 -141 69 -158 15 -19 15 -19 29 -1
                12 16 14 17 22 3 13 -23 127 -148 134 -148 12 0 -6 84 -26 122 -50 96 -104
                185 -123 202 -12 11 -21 28 -21 40 0 18 5 16 38 -15 47 -46 112 -82 182 -102
                63 -19 150 -29 150 -18 0 4 -23 25 -51 47 -27 21 -62 49 -76 62 -14 12 -31 22
                -37 22 -11 0 -56 59 -56 73 0 4 11 7 25 7 13 0 28 6 32 13 4 8 25 20 46 28
                l39 16 -24 15 c-22 14 -95 37 -170 53 -38 7 -35 18 7 34 19 7 35 16 35 20 0
                11 -84 51 -109 51 -38 0 -34 27 18 118 47 82 51 95 51 154 0 51 4 69 21 87 12
                12 18 25 15 28 -9 9 -97 8 -133 -1 -29 -7 -34 -5 -44 16 -9 20 -7 34 10 71 12
                25 21 48 21 51 0 4 -20 6 -44 6 -33 0 -52 -7 -75 -26 -25 -21 -37 -24 -62 -19
                -21 5 -34 3 -39 -5 -5 -8 -15 -10 -26 -5 -18 8 -18 8 -1 12 9 3 15 8 12 13 -3
                5 -12 7 -19 4 -7 -3 -19 2 -26 11 -14 16 -55 20 -65 6 -3 -6 -18 -6 -37 0 -18
                5 -105 9 -195 8 l-163 0 0 -29 c0 -32 31 -60 66 -60 32 0 54 -10 54 -25 0 -7
                14 -29 30 -50 17 -21 28 -40 25 -43 -6 -6 -55 26 -55 37 0 5 -11 12 -25 15
                -14 4 -32 17 -41 31 -9 14 -21 25 -28 25 -11 0 -56 63 -56 79 0 5 6 12 13 14
                6 3 -15 5 -48 5 -33 0 -54 -2 -47 -5z m514 -180 c10 -9 18 -21 18 -27 1 -6 14
                -26 30 -45 27 -31 31 -51 11 -51 -5 0 -24 14 -42 31 -37 33 -70 49 -109 53
                -48 6 -31 40 25 49 17 2 34 5 39 6 5 0 18 -6 28 -16z m-221 -79 c16 -23 29
                -49 29 -56 0 -8 4 -18 9 -23 6 -6 24 -44 41 -85 21 -50 44 -86 68 -107 l37
                -32 -27 -1 c-16 0 -37 9 -48 20 -11 11 -33 20 -51 20 l-30 0 6 54 c9 69 -5
                113 -51 157 -40 38 -78 104 -69 119 9 15 53 -18 86 -66z m339 56 c0 -5 -5 -10
                -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m2 -82 c-16 -16 -21
                -8 -15 27 6 29 6 29 16 7 7 -16 7 -26 -1 -34z m-256 -12 c10 -8 15 -18 11 -22
                -4 -4 -17 2 -29 14 -25 25 -13 30 18 8z m131 -88 c7 -9 4 -18 -8 -30 -18 -17
                -19 -17 -29 2 -18 34 14 57 37 28z m163 -63 c0 -8 -7 -15 -15 -15 -8 0 -15 7
                -15 15 0 8 7 15 15 15 8 0 15 -7 15 -15z m30 -44 c17 -32 13 -43 -10 -31 -11
                6 -20 20 -20 30 0 25 16 26 30 1z m-40 -36 c0 -8 -9 -15 -20 -15 -13 0 -18 5
                -14 15 4 8 12 15 20 15 8 0 14 -7 14 -15z m30 -15 c0 -5 -4 -10 -10 -10 -5 0
                -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m144 -59 c14 -5 16 -10 8 -15
                -15 -9 -52 2 -52 15 0 11 17 11 44 0z m-249 -51 c18 -34 18 -40 5 -40 -6 0
                -15 11 -20 25 -5 14 -14 25 -20 25 -5 0 -10 5 -10 10 0 22 30 8 45 -20z m-135
                -20 c0 -13 -11 -13 -30 0 -12 8 -11 10 8 10 12 0 22 -4 22 -10z m110 -66 c0
                -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z"/>
                <path className='shift' d="M5163 10793 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
                <path className='shift' d="M4203 10600 c-39 -9 -103 -55 -103 -76 0 -11 17 -14 85 -14 82 0 87
                1 135 36 46 33 61 52 43 55 -30 5 -134 4 -160 -1z"/>
                <path className='shift' d="M4075 10510 c-3 -5 -1 -18 4 -27 9 -16 10 -14 11 10 0 28 -5 34 -15
                17z"/>
                <path className='shift' d="M3956 10393 c6 -4 17 -26 25 -47 16 -44 84 -126 105 -126 8 0 14 3
                14 6 0 15 -43 75 -60 84 -9 5 -20 22 -23 38 -3 16 -14 32 -24 36 -10 3 -25 9
                -33 12 -9 3 -11 2 -4 -3z"/>
                <path className='shift' d="M4974 10022 c-27 -19 -54 -68 -54 -97 0 -18 10 -59 22 -92 41 -110
                49 -101 56 60 4 118 -1 145 -24 129z"/>
                <path className='shift' d="M3615 9155 c-3 -8 -7 -21 -9 -29 -7 -23 5 -26 35 -10 32 16 38 38 13
                48 -25 9 -34 7 -39 -9z"/>
                <path className='shift' d="M3513 8935 l-28 -23 21 -24 c12 -12 25 -34 29 -47 10 -34 22 -14 29
                50 8 68 -8 82 -51 44z"/>
                <path className='shift' d="M3637 8933 c-15 -14 -6 -46 18 -65 14 -11 24 -25 22 -32 -7 -20 23
                -65 54 -82 17 -8 33 -13 36 -11 2 3 -14 36 -36 73 -23 37 -41 73 -41 79 0 11
                -31 45 -42 45 -2 0 -8 -3 -11 -7z"/>
                <path className='shift' d="M1539 8848 l-124 -62 35 -9 c38 -10 96 -6 235 17 140 22 179 45 105
                61 -29 6 -42 25 -17 25 7 0 4 7 -7 15 -40 30 -95 19 -227 -47z"/>
                <path className='shift' d="M1380 8766 c0 -2 9 -6 20 -9 11 -3 18 -1 14 4 -5 9 -34 13 -34 5z"/>
                <path className='shift' d="M1500 8729 c0 -7 -8 -17 -17 -24 -16 -12 -16 -14 0 -38 9 -15 42 -45
                72 -68 30 -23 55 -45 55 -48 0 -10 99 -56 105 -49 13 13 -32 79 -76 113 -27
                20 -49 40 -49 44 0 4 45 7 99 6 109 0 139 11 109 41 -22 22 -86 33 -200 34
                -67 0 -98 -4 -98 -11z"/>
                <path className='shift' d="M27 7832 c-27 -3 -27 -4 -27 -73 0 -46 4 -68 10 -64 6 4 10 14 9 23
                -3 35 2 41 58 66 31 15 54 28 52 30 -10 9 -76 21 -102 18z"/>
                <path className='shift' d="M380 7756 c-8 -23 -7 -38 7 -74 21 -56 42 -79 116 -126 49 -31 57
                -41 57 -67 0 -29 2 -30 37 -27 32 3 41 9 66 49 16 26 31 65 34 88 3 23 10 41
                14 41 19 0 7 40 -17 59 -27 22 -69 28 -78 12 -3 -5 -27 -12 -52 -16 -26 -3
                -53 -9 -60 -12 -8 -3 -34 17 -63 48 l-50 54 -11 -29z m200 -186 c0 -5 -4 -10
                -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
                <path className='shift' d="M0 7581 c0 -108 1 -111 72 -111 41 0 41 13 -2 58 -36 37 -40 47 -40
                91 0 34 -4 51 -15 55 -13 4 -15 -10 -15 -93z"/>
                <path className='shift' d="M1010 7434 c0 -6 7 -19 15 -30 8 -10 14 -14 14 -9 0 6 -6 19 -14 29
                -8 11 -15 15 -15 10z"/>
                <path className='shift' d="M961 7378 c0 -14 -4 -35 -7 -45 -5 -17 -5 -17 6 0 6 10 9 30 7 45
                l-4 27 -2 -27z"/>
                </g>
        </svg>
    )
}

export default Splash0Left;