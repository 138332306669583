 function ProjectsWindowFrameBack() {
    return (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="450.000000pt" height="450.000000pt" viewBox="0 0 5000.000000 5000.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(500.000000,5000.000000) scale(0.100000,-0.100000)"
fill="#ffc6b2" stroke="none">
<path d="M9415 37648 c-49 -4 -127 -9 -172 -12 -98 -6 -345 -47 -498 -83 -115
-27 -246 -67 -405 -125 -650 -236 -1219 -710 -1565 -1301 -196 -336 -327 -741
-365 -1127 -8 -84 -10 -2656 -7 -9605 3 -8917 4 -9497 20 -9600 82 -518 293
-979 644 -1403 288 -347 756 -700 1143 -862 36 -15 108 -45 160 -68 247 -104
705 -221 935 -237 33 -3 96 -10 140 -16 120 -18 611 -15 915 4 410 26 424 31
105 38 -293 6 -397 17 -614 65 -454 101 -885 316 -1241 619 -109 93 -296 285
-384 395 -285 356 -496 810 -586 1260 -65 326 -60 -536 -60 9861 0 6380 3
9481 10 9550 25 247 83 505 166 737 56 156 180 411 271 554 505 797 1383 1288
2323 1299 l135 2 -85 8 c-47 5 -130 13 -185 18 -94 10 -403 26 -615 34 -52 2
-135 0 -185 -5z"/>
</g>
</svg>
    )
}

export default ProjectsWindowFrameBack;