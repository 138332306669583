 function Orange() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1920.000000 1080.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,1082.000000) scale(0.100000,-0.100000)"
            fill="#ffb48a" stroke="none">
<path d="M2930 6350 c-74 -10 -126 -25 -246 -70 -149 -57 -242 -71 -524 -79
-261 -8 -303 -15 -379 -67 l-35 -23 -50 28 c-28 15 -75 43 -104 61 -40 25 -62
33 -94 32 -73 -3 -111 -43 -134 -140 -44 -184 38 -485 149 -552 41 -25 78 -25
171 0 l73 21 71 -36 c107 -55 159 -61 385 -46 342 24 426 25 538 6 187 -31
340 -13 361 43 6 19 4 25 -12 29 -30 8 -24 25 24 60 52 38 91 111 108 201 13
71 -1 217 -28 281 -21 49 -70 100 -122 123 -49 22 -63 45 -39 63 10 7 17 17
17 24 0 10 -59 52 -70 50 -3 -1 -30 -5 -60 -9z m95 -31 c4 -11 5 -22 3 -24 -4
-5 -206 -45 -224 -45 -25 0 -15 30 14 41 33 13 160 46 184 48 9 1 20 -8 23
-20z m-958 -217 c10 -6 10 -17 1 -47 -17 -60 -15 -241 4 -301 20 -65 55 -141
68 -149 58 -36 -172 -32 -252 4 -75 34 -129 113 -143 212 -8 54 15 144 47 185
33 41 137 83 258 103 3 0 11 -3 17 -7z m1001 -579 c-4 -24 -96 -34 -139 -16
-48 20 -24 33 62 33 71 0 80 -2 77 -17z"/>
<path d="M1293 3861 c-40 -25 -76 -79 -104 -156 -17 -47 -23 -90 -27 -197 -4
-120 -2 -146 17 -208 37 -122 87 -154 220 -143 l67 6 59 -52 c99 -87 158 -107
495 -165 52 -9 133 -24 179 -32 47 -8 134 -35 195 -61 61 -25 131 -51 156 -58
73 -22 167 -29 200 -15 34 14 47 40 25 52 -25 15 -17 27 35 49 93 40 160 142
187 283 29 154 2 255 -86 326 -39 30 -46 60 -14 60 23 0 11 51 -17 72 -35 26
-171 30 -325 11 -159 -20 -299 -7 -524 48 -206 51 -277 57 -372 34 l-71 -17
-88 86 c-48 47 -94 86 -102 86 -9 0 -28 2 -44 5 -19 4 -40 -1 -61 -14z m545
-241 c72 0 77 -7 48 -72 -59 -134 -77 -308 -41 -411 9 -27 15 -51 12 -53 -2
-2 -43 8 -91 21 -180 53 -240 120 -241 270 0 71 3 86 31 135 18 32 45 63 65
75 36 21 136 46 157 39 8 -2 35 -4 60 -4z m1017 -6 c29 -5 36 -10 33 -28 -3
-20 -7 -21 -118 -17 -112 3 -140 12 -120 36 11 13 152 19 205 9z m-226 -764
c20 -5 53 -12 74 -15 29 -5 37 -10 37 -27 0 -19 -4 -20 -49 -15 -59 7 -111 32
-111 52 0 18 2 18 49 5z"/>
            </g>
        </svg>
    )
}

export default Orange;