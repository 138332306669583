 function Splash3() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1920.000000 1080.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
        fill="#1f5054" stroke="none">
        <path d="M19193,7100c-5,0-40-17-78-38c-88-46-108-61-258-185c-69-57-150-114-187-131l-65-31l-320-6c-295-5-324-7-376-27
		c-51-19-63-20-112-10c-48,10-61,9-96-6c-22-10-50-29-62-42c-21-22-30-24-119-24c-74,0-123,7-213,30c-65,17-125,30-135,30
		c-11,0-32-24-56-67c-21-38-60-93-86-123c-113-133-141-193-161-349c-3.3-26.1-10.4-67.7-18.7-111.8c1.9-26,2.6-52.2,1.7-78.5
		c-3.2-97-26.6-189.8-63.4-278.2c-6.8-70.1-12.3-155-18.6-271.5c-11-192-18-231-44-231c-8,0-19-7-26-15c-9-11-16-12-30-3
		c-24,15-50,3-50-24c0-12-10-32-21-45c-16-17-24-41-29-95c-4-40-5-79-2-86c2-7,20-19,40-27c34-15,34-16,29-62c-5-48,5-83,23-83
		c6,0,10,15,10,34c0,69,12,126,27,126c12,0,14-8,8-36c-3-20-11-132-16-248c-2.9-71.7-5.8-127-9.2-171.2c3.9-7.3,7.5-14.9,10.2-23
		c12.8-38.1,6.1-73-6.1-110.3c-13.5-41.3-28.5-82-43.7-122.6c-6.1-32.6-15.7-85.5-25.3-139.9c-1.8-10.6-3.6-20.8-5.4-30.7
		c0.3-7.3,0.4-14.5,0.1-21.8c-1.2-24.6-8.1-47.7-16.7-70.4c-17.4-93.1-31.6-155-52.9-216.5c-1.2-9.3-2.5-18.7-4.3-28.1
		c-6.8-36.9-26.8-64.3-45.5-93.1c-19.6-42.6-43.7-91.6-74.4-153.3c-67-135.5-107.2-220.8-133.4-293c-1.1-58.1,0.4-116.3-15.8-173.1
		c-9.6-33.6-25.2-64.5-40.9-95.3c-22.3-173.3-25.9-225.6-25.9-363.6c0-169-18-282-51-324c-10-13-39-37-64-53c-25-15-48-37-50-48
		c-3-11-7-94-10-185c-2-91-9-190-14-220s-15-121-22-201l-13-145l27-85c22-71,27-108,30-235c3-91,9-160,17-175c7-13,47-58,89-99
		c42-42,117-121,167-177c78-85,96-112,120-176c28-75,48-97,92-97c9,0,39-12,66-26c27-14,78-37,115-51c36-13,95-42,131-63
		c89-54,127-68,250-96l105-24h2015v3550C19200,5503,19197,7100,19193,7100z M16734,4986c-9-52-12-56-44-56c-22,0-33,6-41,24
		c-17,37,1,61,50,62C16738,5017,16739,5017,16734,4986z M16689,5050c-16,0-29,3-29,6c0,5,81,82,88,84c1,0,2-13,2-29
		C16750,5073,16727,5050,16689,5050z"/>
        </g>
    </svg>
    )
}

export default Splash3;