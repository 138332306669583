 function ProjectsWindowFrame() {
    return (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="450.000000pt" height="450.000000pt" viewBox="0 0 5000.000000 5000.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(500.000000,5000.000000) scale(0.100000,-0.100000)"
fill="#ffddd1" stroke="none">
<path d="M10217 37580 c-703 -52 -1336 -342 -1812 -831 -147 -151 -221 -242
-329 -402 -255 -378 -403 -781 -473 -1288 -16 -116 -17 -697 -21 -9474 -2
-6406 0 -9406 7 -9528 13 -222 41 -408 91 -602 267 -1049 1073 -1857 2106
-2114 254 -62 435 -84 704 -84 152 0 4040 233 15075 902 8179 495 14908 905
14955 911 646 81 1231 540 1579 1241 159 318 250 629 298 1009 17 139 18 461
18 7670 0 6034 -3 7547 -13 7635 -71 616 -288 1156 -636 1585 -339 417 -800
695 -1262 759 -150 22 -29879 2610 -30024 2615 -80 2 -198 1 -263 -4z m15452
-4950 c7615 -478 13904 -875 13974 -880 472 -39 885 -243 1244 -614 418 -431
682 -1018 758 -1681 14 -127 15 -675 12 -5715 l-3 -5575 -21 -125 c-81 -480
-222 -851 -458 -1205 -265 -398 -608 -685 -1008 -844 -93 -37 -235 -76 -352
-96 -122 -21 -27897 -1525 -28162 -1525 -192 0 -295 8 -463 36 -1010 168
-1859 877 -2224 1857 -69 184 -115 365 -163 637 -16 88 -17 589 -20 6970 -3
5047 -1 6906 7 6990 136 1386 1165 2461 2513 2624 275 34 -357 71 14366 -854z"/>
</g>
</svg>
    )
}

export default ProjectsWindowFrame;