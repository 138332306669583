function Tools() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1920.000000 540.000000"
            preserveAspectRatio="xMidYMid meet"
            className='tools'
            >

            <g transform="translate(0.000000,540.000000) scale(0.100000,-0.100000)"
            fill="#161616" stroke="none">
<path d="m10181 2319c-19-8-22-14-18-38 7-33 6-33-101-46-81-10-306-87-356-121-42-29 12-24 116 11 118 39 284 71 322 61 17-4 25-15 30-41 4-19 10-35 14-35 15 0 47-132 57-230 4-51 13-117 49-405 14-110 28-227 31-260 5-52 76-644 91-762 5-39 8-43 34-43 16 0 31 4 34 8 3 5-6 101-19 213-39 324-64 539-70 604-3 33-15 131-26 219-11 87-22 188-25 225-3 36-13 129-23 206-17 138-20 219-7 232 3 3 6 25 6 49v42l48 7c72 11 217 7 307-9 68-12 82-12 94-1 12 12 11 16-11 24-61 23-245 43-349 39l-106-5-9 31c-8 27-14 31-49 33-22 1-51-2-64-8z"/>
<path d="m9888 1975c-42-7-81-16-86-19-6-3-6-26 0-58 17-104 13-98 71-98h53l12-47c12-49 44-246 63-388 6-44 17-118 24-165 8-47 28-188 45-315 59-448 112-810 120-819 5-5 20-6 34-2 32 8 33 18 6 203-44 312-179 1323-185 1388-3 39-8 91-11 116l-5 46 41 6c64 8 73 21 66 90-3 32-8 63-12 68-8 13-145 9-236-6z"/>
<path d="m9120 1200c-30-59-26-76 21-100 34-18 39-24 33-43-15-47-308-624-329-646-8-10-15-22-15-28 0-18 45-52 74-55 28-3 31 1 48 47 11 28 43 101 73 162 30 62 76 159 101 215 55 123 122 260 133 271 5 4 27 1 50-9 50-20 62-12 87 53 17 44 17 46-2 60-30 24-217 113-236 113-10 0-26-16-38-40z"/>
            </g>
            </svg>
    )
}

export default Tools;