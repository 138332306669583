 function Items() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1920.000000 1080.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,1082.000000) scale(0.100000,-0.100000)"
            stroke="none">

<path d="M4548 4883 c-47 -30 -62 -71 -55 -154 10 -122 99 -377 161 -458 33
-44 33 -53 -3 -93 -15 -18 -26 -38 -24 -44 2 -7 -17 -13 -52 -16 -31 -3 -61
-10 -68 -17 -8 -8 -12 -52 -13 -130 -1 -65 -4 -138 -8 -163 -4 -24 -14 -123
-22 -219 -9 -96 -18 -200 -21 -232 -7 -73 7 -106 60 -140 76 -50 232 -83 349
-75 105 8 160 51 177 138 8 43 9 44 19 20 8 -18 11 -20 11 -7 1 15 5 16 21 7
29 -15 27 -43 -2 -35 -18 6 -20 5 -11 -5 14 -15 43 -6 43 13 0 8 -7 20 -15 27
-29 24 -18 78 25 132 l40 51 0 111 c0 95 -3 116 -21 145 -19 32 -20 39 -9 135
7 56 14 114 15 129 4 39 -22 63 -88 82 l-54 16 -5 57 c-8 83 -27 137 -63 180
-32 37 -39 56 -55 147 -18 106 -19 165 -5 165 19 0 35 32 35 68 0 51 -39 94
-125 137 -95 47 -190 59 -237 28z m179 -86 c62 -16 125 -57 118 -75 -3 -7 0
-69 6 -138 9 -109 8 -130 -5 -159 l-16 -33 -53 14 c-28 7 -62 14 -74 14 -19 0
-26 13 -52 95 -56 172 -73 252 -61 275 13 24 60 27 137 7z m163 -504 c25 -54
70 -166 70 -174 0 -5 -7 -9 -15 -9 -8 0 -15 8 -15 19 0 11 -9 21 -22 25 -38
10 -46 7 -52 -19 -7 -27 -21 -30 -87 -19 -30 5 -35 9 -32 28 3 13 -2 40 -11
61 -20 47 -21 45 9 45 30 0 95 31 114 54 19 22 26 20 41 -11z m245 -573 c11
-21 15 -59 15 -132 -1 -99 -2 -104 -30 -137 -16 -19 -32 -32 -35 -29 -3 3 1
29 8 59 8 31 13 96 12 154 -2 126 2 138 30 85z m-56 -251 c-17 -80 -24 -97
-34 -87 -6 6 21 200 32 233 5 15 9 9 13 -23 4 -27 0 -74 -11 -123z"/>
<path d="M6013 4768 c-30 -32 -53 -69 -53 -84 0 -4 13 12 29 37 67 107 153 83
210 -57 11 -27 20 -42 20 -34 1 29 -56 136 -82 153 -43 28 -88 22 -124 -15z"/>
<path d="M5932 4590 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M6228 4419 c4 -169 3 -183 -16 -209 -24 -32 -42 -92 -42 -142 0 -33
-5 -42 -47 -72 -27 -19 -70 -44 -98 -55 -27 -10 -58 -22 -67 -26 -16 -6 -18 0
-20 56 l-2 64 -5 -66 c-6 -65 -7 -67 -51 -101 -57 -43 -80 -88 -80 -157 0 -49
3 -56 38 -90 21 -20 51 -44 65 -54 26 -17 27 -22 27 -106 0 -77 -3 -91 -25
-121 -14 -19 -25 -43 -25 -53 0 -28 34 -79 69 -102 24 -15 35 -36 56 -100 27
-87 30 -111 15 -149 -14 -39 -46 -54 -208 -95 -83 -21 -155 -42 -161 -45 -27
-17 -7 -315 44 -640 21 -132 30 -167 56 -213 52 -90 152 -144 177 -95 5 9 15
53 22 96 14 91 91 382 121 458 32 81 79 144 214 283 142 146 185 204 185 249
0 38 -30 105 -57 129 -30 26 -118 54 -213 67 -47 6 -106 21 -132 32 -41 18
-49 26 -59 61 -6 22 -22 57 -35 79 -21 32 -26 56 -31 140 -4 59 -2 99 3 97 5
-2 54 -31 109 -63 135 -80 208 -137 254 -199 55 -74 183 -299 241 -422 57
-122 72 -130 133 -67 37 38 59 87 71 157 13 76 -51 564 -95 728 -24 89 0 80
-205 77 -215 -3 -244 18 -244 177 0 81 3 95 24 120 48 58 60 111 32 149 -8 10
-12 29 -10 40 3 12 1 25 -5 28 -7 5 -11 59 -12 149 -1 78 -4 152 -7 165 -4 13
-5 -58 -4 -159z m-2 -253 c-8 -33 5 -41 26 -14 17 23 18 23 18 2 0 -20 -48
-95 -69 -109 -16 -10 -14 69 3 110 17 40 31 47 22 11z m-304 -881 c-5 -29 5
-33 23 -8 11 15 14 14 28 -8 23 -35 32 -79 17 -79 -7 0 -30 14 -51 31 -28 22
-39 38 -39 58 0 15 3 31 6 34 13 12 21 -3 16 -28z"/>
<path d="M5924 4330 c0 -124 2 -174 3 -112 2 61 2 163 0 225 -1 61 -3 11 -3
-113z"/>
            </g>
        </svg>
    )
}

export default Items;