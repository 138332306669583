 function TransitionBackground() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1920.000000 155.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,150.000000) scale(0.100000,-0.100000)"
            fill="#0f2d30" stroke="none">
<path d="M0 940 l0 -871 58 3 57 3 35 70 c33 67 37 71 81 81 68 17 81 27 118
91 19 32 68 104 110 161 42 57 90 122 106 144 27 36 212 167 239 168 5 0 35
18 68 40 44 31 89 49 181 74 67 19 156 46 197 62 66 24 96 28 255 36 99 5 210
14 246 20 l66 11 88 -48 87 -48 72 7 c39 4 121 11 181 15 122 9 288 57 315 92
11 14 40 22 120 34 58 8 173 24 255 35 83 12 184 26 225 32 133 18 443 15 540
-4 60 -13 178 -21 395 -29 l310 -11 100 -36 100 -37 580 4 580 3 98 -26 c120
-32 136 -32 236 -1 77 25 216 45 431 64 58 6 427 13 820 16 393 3 774 8 845 9
72 2 258 7 415 11 193 5 304 4 343 -4 49 -9 64 -8 95 6 29 13 78 17 217 21
150 3 183 7 199 20 28 26 388 31 478 7 53 -14 125 -17 453 -19 443 -2 510 -10
668 -73 108 -44 142 -46 482 -23 182 12 215 16 245 35 146 89 348 123 738 125
108 0 141 -4 260 -34 133 -34 144 -35 392 -40 278 -6 384 -17 467 -48 l54 -20
50 42 c27 23 80 55 117 72 63 30 74 32 187 32 109 1 130 -2 230 -34 l110 -34
355 -6 c195 -3 360 -8 365 -12 13 -8 387 -54 515 -63 56 -4 126 -17 169 -31
l75 -24 65 19 c37 11 92 22 124 26 31 4 87 15 125 26 86 24 290 26 430 3 67
-11 131 -14 209 -11 104 5 116 4 160 -19 26 -13 75 -45 108 -72 74 -60 100
-74 194 -103 41 -12 93 -34 115 -49 127 -84 148 -93 236 -100 65 -6 105 -16
170 -44 47 -20 123 -47 170 -60 165 -46 275 -77 300 -85 31 -10 202 -131 255
-181 22 -21 81 -60 130 -86 50 -27 126 -78 170 -113 110 -89 125 -96 248 -125
60 -14 111 -26 113 -26 2 0 4 405 4 900 l0 900 -9600 0 -9600 0 0 -870z"/>
            </g>
        </svg>
    )
}

export default TransitionBackground;