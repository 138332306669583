 function Blue() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1920.000000 1080.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
            fill="#90dbf4" stroke="none">
<path d="M3843 10178 c-6 -8 18 -400 66 -1083 l76 -1070 35 0 c31 0 35 3 38
29 4 30 -136 2072 -144 2114 -3 17 -11 22 -33 22 -16 0 -33 -6 -38 -12z"/>
<path d="M3516 10152 c-3 -4 29 -480 70 -1058 40 -577 74 -1059 74 -1071 0
-20 5 -23 40 -23 35 0 40 3 40 23 0 31 -138 1997 -145 2075 l-7 62 -33 0 c-19
0 -36 -4 -39 -8z"/>
<path d="M4092 9178 c-7 -7 -12 -22 -12 -34 0 -39 300 -1138 313 -1146 15 -10
58 -1 76 17 12 12 -7 94 -138 582 -84 312 -158 574 -163 581 -13 15 -60 16
-76 0z"/>
<path d="M4716 8468 c-13 -18 -16 -56 -16 -205 0 -170 1 -184 20 -203 20 -20
33 -20 1018 -20 955 0 1000 1 1015 18 15 16 17 46 17 209 0 171 -2 192 -18
206 -17 16 -102 17 -1020 17 l-1001 0 -15 -22z"/>
            </g>
        </svg>
    )
}

export default Blue;